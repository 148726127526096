.user-panel-battles {
	$user-avatar-size: 38px;
	$level-size: 38px;
	$line-distance: 10px;
	$progress-size: 8px;
	$icon-size: 24px;
	$segments-distance: 8px;
	$wallet-distance: 8px;

	left: 0;
	bottom: 0;
	z-index: $zindex-fixed;
	background-color: var(--color--primary--background);
	width: 100%;
	height: 70px;
	padding: 12px 16px;

	&__menu {
		margin: auto;
		height: 100%;
		color: var(--color--primary--foreground);
		font-size: 14px;
		column-gap: 32px;

		&--pointer {
			cursor: pointer;
		}

		&--disabled {
			cursor: not-allowed;
		}

		&__main {
			height: 100%;
			cursor: pointer;
		}

		&__main-icon {
			width: 100px;

			> img {
				width: 100%;
				object-fit: contain;
			}

			&--disabled {
				filter: grayscale(1);
			}
		}

		&__icon {
			width: 24px;
			height: 24px;
			padding: 6px 5px;
			margin: auto;

			&--background {
				background-color: var(--color--interactive--background);
			}

			> img {
				width: 100%;
				object-fit: contain;
			}
		}
	}

	&__userContainer {
		position: absolute;
		left: 16px;

		@include media-breakpoint-down(sm) {
			display: none !important;
		}
	}

	&__avatarContainer {
		z-index: $zindex-sticky;
		bottom: 0;
		width: $user-avatar-size;
		height: $user-avatar-size;
	}

	&__avatarContainer--progress,
	.CircularProgressbar {
		width: $user-avatar-size + $progress-size;
		height: $user-avatar-size + $progress-size;
	}

	.CircularProgressbar .CircularProgressbar-path {
		stroke: var(--color--content--background);
	}

	.CircularProgressbar .CircularProgressbar-trail {
		stroke: color-mod(var(--color--content--background) shade(0.9));
	}

	&__avatar {
		width: $user-avatar-size;
		height: $user-avatar-size;
		background: {
			color: color-mod(var(--color--interactive--background) brighten(0.4));
			size: cover;
		}
	}

	&__level {
		width: $level-size;
		height: $level-size;
		right: -($level-size / 2);
		color: var(--color--interactive--foreground);
		background-color: var(--color--interactive--background);
		margin-left: -$segments-distance;
		z-index: $zindex-sticky + 1;
	}

	&__avatar,
	&__teamAvatar,
	&__level {
		background: {
			repeat: no-repeat;
			position: center;
		}
	}

	&__wallet {
		margin-left: -$wallet-distance;

		@include media-breakpoint-down(lg) {
			display: none;
		}

		.currencies {
			height: $level-size;
			background-color: var(--color--primary--background);
			color: var(--color--primary--foreground);
			bottom: $line-distance;
			border-radius: 24px;

			.currency {
				img {
					height: 20px;
				}

				.icon {
					width: $icon-size;
					height: $icon-size;
				}

				.value {
					font-size: 16px !important;
				}
			}
		}
	}
}
