// achievementPreview

.achievementDetails {
	font-size: 1rem;

	.standardPreview__image {
		filter: grayscale(1);
		&.achieved {
			filter: none;
		}
		&:not(.achieved) {
			opacity: 0.6;
		}
	}
	.standardPreview__column--middle {
		.image {
			width: 80%;
			background-position: top;
			&.notAchieved,
			&.notAchieved img {
				opacity: 0.8;
				filter: grayscale(1);
			}
			@include M {
				background-size: 0;
			}
		}
	}
	.markdown {
		color: var(--color--content--foreground-60);
	}
}
