.ranking-view {
	&__image {
		max-width: 100%;
	}

	&__information {
		min-width: auto;
		box-shadow: 0 0 15px 0 var(--color--content--foreground-10);
		background-color: var(--color--content--background);
		border-right: none;
		line-height: 1.2;
		margin-left: -$spacer;
		margin-right: -$spacer;

		p {
			color: var(--color--content--foreground);
		}

		@include media-breakpoint-up(sm) {
			border-right: 1px solid var(--color--content--foreground-10);
			background-color: transparent;
			box-shadow: none;
			min-width: 300px;
			max-width: 300px;
			margin-left: 0;
			margin-right: $spacer * 0.5;
		}

		img {
			max-width: 100%;
		}
	}
}
