// missionList

.missionListWrapper {
	overflow: hidden;
	position: relative;
	width: $missionListWidth;
	z-index: 300;
	@include L {
		display: none;
	}
	&__listContainer {
		position: relative;
		bottom: 0;
		overflow-x: hidden;
		> div {
			position: absolute;
			height: 100%;
			overflow-x: hidden;
		}
	}
	&__upArrowWrapper {
		height: 50px;
	}
	&__bottomArrowWrapper {
		height: 140px;
		@include touchDevices {
			height: 50px;
		}
	}
	div[class*='arrow'] {
		width: 20px;
		height: 20px;
		position: relative;
		top: 17px;
		left: 60px;
		cursor: pointer;
		&:hover {
			transform: scale(1.2);
		}
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			font-family: FontAwesome;
			font-size: 20px;
			color: var(--color--content--foreground);
		}
		&[class*='--up']::after {
			content: '\f106';
		}
		&[class*='--bottom']::after {
			content: '\f107';
		}
	}
	ul {
		overflow-y: scroll;
		width: $missionListWidth + 17px;
		padding-bottom: 70px;
		overflow-x: hidden;
		li {
			margin-top: 25px;
			align-self: flex-start;
			padding-left: 24px;
			&:first-child {
				margin-top: 10px;
			}
			@include minDesktop {
				margin-top: 40px;
			}
			.tileWrapper {
				text-align: center;
				display: block;
				position: relative;
				outline: 0;
				width: 83px;
				margin: 0 auto;
				border-radius: 50%;
				background-color: transparent;
				transition: opacity 0.2s ease-in;
				// Solution for proper display (centered) menu list elements on Firefox, caused by different calculation of container width (scroll with included/excluded) in this browser
				@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
					left: -10px;
				} // sass-lint:disable-line no-vendor-prefixes
				&:hover,
				&.active {
					transform: scale(1.02);
					.roundNumber {
						transform: scale(1.2);
					}
					.missionBox__name {
						color: var(--color--content--foreground);
					}
				}
			}
			.roundNumber {
				transition: 0.1s ease-in;
			}
			.missionBox {
				height: 72px;
				width: auto;
				max-width: 72px;
				transition: transform 0.3s;
				z-index: 200;
				margin: 0 auto;
				&__image {
					path {
						fill: var(--color--interactive--background);
					}
				}
				&__emptyImage {
					line-height: 2;
					border-radius: 72px;
					font-size: 36px;
					font-weight: 600;
					background-color: var(--color--interactive--background);
					color: var(--color--interactive--foreground);
				}
			}
			.missionBox__name {
				position: relative;
				width: 100%;
				color: var(--color--content--foreground-30);
				font-size: 16px;
				text-align: center;
				transition: all 0.2s ease-out;
				text-overflow: ellipsis;
				font-weight: 600;
				@include XXXL {
					font-size: 14px;
				}
			}
			.progressBar {
				width: 60px;
				margin: 0;
				top: -8px;
				left: 50%;
				transform: translate(-50%, 0%);
				background-color: var(--color--content--background);
			}
		}
	}
}
