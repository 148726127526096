//mobileTaskStatus

.mobileTaskStatus {
	width: 100vw;
	z-index: 500;
	background-color: var(--color--interactive--background-40);
	text-align: center;
	position: static;
	display: none;
	&__content {
		color: #fff;
		text-align: center;
		transition: all 0.2s ease-in;
		padding: 40px;
		max-width: none;
		min-width: 0;
		width: 100vw;
		top: 0;
		left: 0;
		border-radius: 0;
		height: auto;
		position: static;
		flex-grow: 1;
		transform: translate(0, 0) scale(1);
		animation: none;
	}
	&__icon {
		background-color: $answerCorrectDark;
		width: 136px;
		height: 136px;
		border-radius: 50%;
		margin-bottom: 20px;
	}
	.contentText {
		margin-bottom: 10px;
		color: var(--color--content--foreground);
		font-size: 24px;
		&--big {
			font-size: 34px;
		}
		&--small {
			font-size: 15px;
			font-weight: 100;
		}
		&--light {
			color: var(--color--content--foreground-60);
		}
	}
	@each $length, $fontSize in (0: 8vw, 1: 7vw, 2: 6vw) {
		.contentText--dynamic--#{$length} {
			font-size: $fontSize;
		}
	}
	.currencyContainer {
		span {
			font-size: 24px;
			color: var(--color--content--foreground);
		}
		&__wrapper {
			margin-bottom: 17px;
		}
	}
	.btn__basic {
		padding: 11px 12px 9px 12px;
	}
	.roundVisualStatus {
		width: 150px;
		height: 150px;
		margin-bottom: 15px;
		&--medium {
			width: 110px;
			height: 110px;
		}
		&--small {
			width: 60px;
			height: 60px;
		}
	}
}
