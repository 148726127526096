section.header {
	display: flex;
	height: 70px;
	min-height: 70px;
	padding-left: 32px;
	padding-right: 16px;
	background-color: var(--color--primary--background);
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	line-height: 18px;
	font-weight: 600;
	z-index: 1100;
	color: var(--color--primary--foreground);

	&.transparent {
		background-color: var(--color--primary--background-70);
	}

	@include L {
		height: 64px;
		min-height: 64px;
		padding: 0 16px;

		.positions {
			display: none;
		}
		.positions + .separator {
			display: none;
		}
	}

	.logo {
		display: flex;
		height: 38px;
		max-height: 38px;

		@include L {
			height: 32px;
			max-height: 32px;
		}
	}

	.menu-desktop {
		display: flex;
		align-items: center;
		margin-left: auto;

		.separator {
			width: 1px;
			height: 32px;
			margin: 0 16px;
			margin-left: 0;
			opacity: 0.15;
			background-color: var(--color--primary--foreground);

			@include L {
				margin: 0 10px;
				margin-left: 0;
			}
		}

		.positions {
			line-height: 32px;
			margin-right: 16px;

			@include L {
				margin-right: 12px;
			}

			ul {
				display: flex;
				li {
					margin-left: 16px;

					a {
						display: flex;
						align-items: center;
						height: 38px;
						position: relative;

						padding: 10px 16px;
						border-radius: 38px;

						.position-tooltip {
							display: none;
							background-color: white;
							color: #303030;
							position: absolute;
							left: 50%;
							bottom: -30%;
							line-height: 20px;
							font-size: 14px;
							font-weight: 400;
							text-align: center;
							padding: 4px 16px;
							border-radius: 12px;
							box-shadow: 0px 0px 8px 0px #0000000d;
							transform: translate(-50%, 100%);

							&::after {
								content: '';
								position: absolute;
								top: -7px;
								left: 50%;
								transform: translateX(-50%);
								width: 0;
								height: 0;
								border-left: 8px solid transparent;
								border-right: 8px solid transparent;
								border-bottom: 8px solid white;
							}
						}

						&.active,
						&:hover {
							color: var(--color--interactive--foreground);
							background-color: var(--color--interactive--background);
						}

						&:hover {
							.position-tooltip {
								display: block;
							}
						}

						&.has-image {
							padding: 7px;

							img {
								height: 24px;
								min-width: 24px;
								max-height: 24px;
							}
						}
					}
				}
			}
		}

		.languages {
			display: flex;
			align-items: center;
			margin-right: 16px;
			line-height: 38px;
			padding: 0 16px;
			border-radius: 32px;
			cursor: pointer;

			&:hover {
				background-color: var(--color--interactive--background);
			}

			&.more-not-exists {
				margin-right: 0;
			}

			@include L {
				margin-right: 8px;
			}

			svg {
				width: 16px;
				height: 16px;
				margin-left: 10px;
			}
		}

		.hamburger {
			width: 38px;
			height: 38px;
			line-height: 38px;
			border-radius: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			margin: 0 16px;

			&:hover {
				background-color: var(--color--interactive--background);
			}

			@include L {
				margin: 0;
			}

			svg {
				width: 20px;
				height: 20px;
				transform: scaleX(-1);
			}
		}
	}
}

@keyframes showBackdrop {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

section.menu-backdrop {
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1100;
	animation: showBackdrop 0.2s forwards;
}

section.menu {
	position: absolute;
	width: 268px;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: var(--color--content--background);
	z-index: 1200;
	box-shadow: 0 0 8px 0 #0000000d;
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
	padding: 16px 32px 0 32px;
	overflow-y: auto;

	transform: translateX(100%);
	transition: transform 0.2s ease-in-out;

	&.open {
		transform: translateX(0);
	}

	@include L {
		width: 278px;
		padding: 16px 0 0 0;
	}

	.content {
		display: flex;
		flex-direction: column;
		height: 100%;
		font-weight: 400;

		.menu-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 38px;

			@include L {
				padding: 0 16px;
			}

			.title-text {
				font-weight: 600;
			}

			.title-close {
				display: flex;
				justify-content: right;
				align-items: center;
				cursor: pointer;
				width: 32px;
				height: 32px;

				svg {
					width: 20px;
					height: 20px;
				}
			}
		}

		hr {
			margin: 24px 0;
			border-style: dashed;
			border-color: rgba(0, 0, 0, 0.05);
		}

		li {
			&:not(:last-child) {
				margin-bottom: 4px;
			}

			&.desktop-position {
				display: none;
			}

			a {
				display: flex;
				align-items: center;
				padding: 10px 16px;
				border-radius: 6px;
				color: var(--color--content--foreground);

				img {
					height: 20px;
					min-width: 20px;
					max-height: 20px;
				}

				&.active,
				&:hover {
					background-color: #f8f8f8;

					@include L {
						border-radius: 0;
					}
				}

				&.legal-link {
					padding: 10px 0;

					&:hover {
						text-decoration: underline;
						background-color: inherit;
					}

					@include L {
						padding: 10px 16px;
					}
				}
			}
		}

		@include L {
			li {
				&.desktop-position {
					display: block;
				}
			}
		}

		.menu-logo {
			margin-top: auto;
			padding-top: 72px;
			padding-bottom: 24px;

			img {
				height: 22px;
				max-height: 22px;
			}

			@include L {
				padding-left: 16px;
			}
		}
	}
}
