/**
 * button.btn
 * button.btn.btn-primary
 * button.btn.btn-close
 * button.btn.btn-close.top-right
 */

button.btn {
	position: relative;
	cursor: pointer;
	border: 0;

	&:focus {
		outline: 0;
	}

	&-primary {
		color: var(--color--primary--foreground);
		background-color: var(--color--primary--background);
	}

	&-interactive {
		color: var(--color--interactive--foreground);
		background-color: var(--color--interactive--background);
	}

	&-danger {
		background-color: #f00;
	}

	&-transparent {
		background-color: transparent;
	}

	&-icon {
		background-color: transparent;
		font-size: 1rem;

		&-interactive {
			color: var(--color--interactive--background);
		}
	}

	&-close {
		$size: 25px;

		position: absolute;
		width: $size;
		height: $size;
		border-radius: $size;

		&.top-left {
			transform: translate(-50%, -50%);
			top: 0;
			left: 0;
		}
		&.top-right {
			transform: translate(50%, -50%);
			top: 0;
			right: 0;
		}

		&::before,
		&::after {
			position: absolute;
			content: '';
			width: $size / 2;
			height: 1px;
			background-color: var(--color--primary--foreground);
			top: 50%;
			left: 50%;
		}
		&::before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
		&::after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
}
