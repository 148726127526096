// challenePrizes

.submissionAward {
	&__placeInfo {
		width: 150px;
		padding-left: 20px;
		@include M {
			margin-bottom: 20px;
		}
		img {
			height: 30px;
			width: auto;
		}
		p {
			color: var(--color--content--foreground);
			font-size: 12px;
			margin-top: 10px;
			span {
				padding: 0 3px;
			}
		}
	}
	&__section {
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
	&__section.isEmpty {
		margin-bottom: 20px;
		.emptyMessage {
			display: block;
			align-self: center;
			font-size: 2em;
			color: var(--color--content--foreground);
		}
	}
	&__tile {
		border: 1px solid var(--color--content--foreground-10);
		border-radius: 10px;
		padding: 20px;
		width: 156px;
		margin: 0 30px 30px 0;
		@include M {
			margin-right: 0;
		}
		.image {
			height: 163px;
			width: 116px;
			background-position: top;
		}
		.prizeCurrencies {
			.currencyContainer {
				margin-bottom: 5px;
				width: 50%;
				flex-direction: column;
				&__icon {
					width: 35px;
					height: auto;
					margin-bottom: 10px;
					@include M {
						margin-bottom: 5px;
					}
				}
			}
			& + p {
				margin-top: 5px;
			}
		}
		p {
			color: var(--color--interactive--background);
			font-size: 14px;
			font-weight: 400;
			text-align: center;
			@include M {
				flex-grow: 1;
			}
		}
	}
	@include minLaptop {
		.emptyList {
			padding-top: 0;
		}
	}
}
