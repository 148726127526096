// submissionDetails

.submissionDetails {
	&__info {
		font-size: 18px;
		margin-bottom: 30px;
		color: var(--color--content--foreground-30);
		p {
			margin-bottom: 0;
		}
		.author,
		.date {
			margin-left: 5px;
		}
		.author {
			color: var(--color--interactive--background);
		}
		.date {
			color: var(--color--content--foreground-60);
		}
	}
	&__content {
		.text {
			border-top: 1px solid var(--color--content--foreground-10);
			color: var(--color--content--foreground-60);
			padding-top: 30px;
			font-size: 16px;
			line-height: 1.8;
		}
		.image {
			margin: 0 auto 20px auto;
			display: block;
			max-width: 100%;
		}
	}
}
.view__challengeDetails .contentWrapper {
	padding: 30px 0;
}
.view__challengeDetails .standardPreview .standardPreview__mainWrapper {
	padding: 0 20px;
}
// .........................
.view__submissionDetails.view__challengeDetails .standardPreview .standardPreview__mainWrapper {
	height: auto;
}
.rateSection {
	&__number {
		color: var(--color--primary--foreground);
		margin-left: 10px;
		font-size: 28px;
	}
	&__stars + &__hearts {
		border-left: 1px solid var(--color--primary--foreground-30);
		margin-left: 30px;
		padding-left: 30px;
	}
	&__hearts {
		cursor: default;
		i {
			font: {
				family: FontAwesome;
				size: 20px;
			}
			position: relative;
			top: -1px;
			color: var(--color--primary--foreground-30);
		}
		&:hover i {
			color: var(--color--primary--foreground-30);
		}
		&.edit {
			cursor: pointer;
			i {
				color: var(--color--primary--foreground-30);
			}
			&:hover i {
				color: var(--color--interactive--background-80);
			}
		}
		&.selected {
			cursor: default;
			i {
				color: var(--color--interactive--background);
			}
			&:hover i {
				color: var(--color--interactive--background);
			}
		}

		@include M {
			i {
				color: var(--color--content--foreground-30);
			}
			&:hover i {
				color: var(--color--content--foreground-30);
			}
			&.edit {
				cursor: pointer;
				i {
					color: var(--color--content--foreground-30);
				}
				&:hover i {
					color: var(--color--interactive--background-80);
				}
			}
		}
	}

	@include M {
		justify-content: space-between;
		margin-bottom: 1.5rem;
		padding-bottom: 1.5rem;
		border-bottom: 1px solid #dee2e6 !important; // sass-lint:disable-line no-important

		&:not(.hidden--mobile) {
			display: flex;
		}
		&__number {
			color: var(--color--content--foreground-80);
		}
	}
}

.view__submissionDetails {
	.navigationArrow {
		display: flex;
		align-self: stretch;
		align-items: center;
		font-size: 44px;
		&--left a {
			padding-left: 20px;
		}
		&--right a {
			padding-right: 20px;
		}
		a {
			display: flex;
			align-self: stretch;
			align-items: center;
			color: var(--color--content--foreground-10);
			&:hover {
				color: var(--color--primary--background);
			}
		}
	}
}
