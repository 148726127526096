.portal {
	padding-bottom: 5rem !important;

	&__process {
		width: 100%;

		@include media-breakpoint-up(md) {
			width: 60%;
		}
	}

	&__spacer {
		border-top: var(--color--content--foreground-15) dashed 1px;
	}

	&__additional {
		width: 100%;

		@include media-breakpoint-up(md) {
			width: 40%;
		}
	}

	&__download-report {
		color: var(--color--content--foreground-50);
	}

	.download-report__button {
		color: var(--color--interactive--background);
		background-color: var(--color--content--background);
		box-shadow: $tile-box-shadow;
	}

	&__filter {
		width: 1rem;
		height: 1rem;
	}

	.color-blue {
		color: $blue;
	}
}

.processList {
	$user-avatar-size: 48px;
	$user-avatar-size-mobile: 36px;
	$border-radius: 0.625rem;

	background-color: var(--color--primary--background-10);
	box-shadow: $tile-box-shadow;
	border-radius: $border-radius;

	&--open {
		.processList__list {
			box-shadow: $tile-box-shadow;
		}
	}

	&--expandable {
		cursor: pointer;
	}

	&__text--highlighted {
		color: $red;
	}

	&__main {
		background-color: var(--color--content--background);
	}

	&__title {
		color: var(--color--content--foreground-60);
	}

	&__progressBar {
		&__actions {
			width: 25%;
			text-align: right;

			@include media-breakpoint-up(xxs) {
				width: 22%;
			}

			@include media-breakpoint-up(xs) {
				width: 16%;
			}

			@include media-breakpoint-up(lg) {
				width: 12%;
			}

			@include media-breakpoint-up(xl) {
				width: 10%;
			}
		}
	}

	&__roleAssignedList {
		color: var(--color--content--foreground-50);
	}

	&__toggleList {
		&__icon {
			position: absolute;
			left: -12px;
			top: -12px;
			width: 24px;
			height: 24px;
			background-color: var(--color--interactive--background);
			border-radius: 1rem;
			color: var(--color--interactive--foreground);
			z-index: 2;

			@include media-breakpoint-up(sm) {
				left: -14px;
				top: -14px;
				width: 28px;
				height: 28px;
			}
		}
	}

	&__list {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}

	&__avatar {
		width: $user-avatar-size-mobile;
		height: $user-avatar-size-mobile;
		background-color: var(--color--interactive--background);

		@include media-breakpoint-up(sm) {
			width: $user-avatar-size;
			height: $user-avatar-size;
		}
	}

	&--employees {
		.processList__progressBar__actions {
			width: 30%;

			@include media-breakpoint-up(xs) {
				width: 19%;
			}

			@include media-breakpoint-up(sm) {
				width: 15%;
			}

			@include media-breakpoint-up(md) {
				width: 26%;
			}

			@include media-breakpoint-up(lg) {
				width: 21%;
			}

			@include media-breakpoint-up(xl) {
				width: 15%;
			}
		}
	}
}

.actionList {
	@include media-breakpoint-up(sm) {
		padding-bottom: 6rem;
	}

	.action {
		&--delayed {
			color: red;

			.action__icon {
				fill: red;

				g,
				path {
					fill: red;
				}
			}
		}

		&--withImage {
			@include media-breakpoint-up(sm) {
				.actionList__listRow {
					margin-left: -10px;
					min-height: 8rem;
				}

				.action__image {
					aspect-ratio: 1.75 / 1;
					flex-shrink: 0;
					min-height: 8rem;
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
				}
			}

			@include media-breakpoint-down(xs) {
				.actionList__listRow {
					margin-top: -10px;
				}

				.action__image {
					aspect-ratio: 1.75 / 1;
					border-top-left-radius: $border-radius;
					border-top-right-radius: $border-radius;
				}
			}
		}

		&__alarmIcon {
			height: 24px;
			width: 24px;

			&--overdue {
				fill: red;
			}
		}

		&__icon {
			$size: 36px;
			width: $size;
			height: $size;
			@include media-breakpoint-up(sm) {
				$size: 48px;
				width: $size;
				height: $size;
			}
			fill: var(--color--interactive--background);

			g,
			path {
				fill: var(--color--interactive--background);
			}
		}

		&__contentName {
			font-weight: 600;
		}

		&__description {
			color: var(--color--content--foreground-50);
			font-weight: 400;

			&__separator {
				color: var(--color--content--foreground-15);
			}
		}

		&__assignRole {
			&__mobileSubmit {
				width: 2rem;
				height: 2rem;
				background-color: var(--color--interactive--background);
				color: var(--color--interactive--foreground);
				cursor: pointer;

				&--disabled {
					background-color: var(--color--content--foreground-15);
					cursor: not-allowed;
				}

				&--loading {
					&::before {
						position: absolute;
						content: '\f110';
						top: calc(50% - 0.5em);
						right: calc(50% - 0.5em);
						animation: rotate 1s infinite ease-in;
						z-index: 200;
						font-size: 1em;
					}
				}
			}
		}
	}

	&__listRow {
		width: 100%;
		background-color: var(--color--content--background);
		box-shadow: $tile-box-shadow;
		border-radius: $border-radius;
	}

	&__placeholder {
		width: 100%;

		@include media-breakpoint-up(xs) {
			width: 70%;
		}

		&__icon {
			font-size: 6rem;
		}
	}
}

.knowledgeBase {
	&__tile {
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $tile-box-shadow;
		background-color: var(--color--content--background);
		overflow: visible;

		.tile__front {
			visibility: visible !important;
		}

		.tile__icon {
			$size: 2rem;
			width: $size;
			height: $size;
			padding: 5px;
			background: var(--color--interactive--background);
			color: var(--color--interactive--foreground);
		}
	}
}

.contacts {
	&__tile {
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $tile-box-shadow;
		background-color: var(--color--content--background);
		overflow: visible;

		.tile__front {
			visibility: visible !important;
		}

		.tile__image {
			width: 60%;
			height: auto;
			padding-top: 60%;
			background-color: var(--color--interactive--background);
		}

		.tile__role {
			opacity: 0.5;
		}

		.tile__contactIcon {
			$size: 2.25rem;
			width: $size;
			height: $size;
			padding: 7px;
			background: var(--color--interactive--background);
			color: var(--color--interactive--foreground);
		}
	}
}
