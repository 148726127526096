.pagination {
	p {
		font-size: 1rem;
	}

	button {
		border: 0;
		background-color: transparent;
		color: var(--color--content--foreground);
		cursor: pointer;
		outline: 0;
		font-size: 0.9rem;
		min-width: 25px;
		border-radius: 15px;

		&[disabled] {
			cursor: default;
			color: var(--color--content--foreground-50);
		}

		&.active {
			cursor: default;
			font-weight: 600;
			background-color: var(--color--interactive--background);
			color: var(--color--interactive--foreground);
		}

		&:hover:not(.active):not([disabled]) {
			background-color: var(--color--content--foreground-20);
			color: var(--color--interactive--background);
		}

		i {
			font-size: 1.1rem;
		}
	}
}
