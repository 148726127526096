// statsTile

.statsTile {
	min-height: 98px;
	width: 202px;
	margin: 0 10px 10px 0;
	border-radius: 10px;
	overflow: hidden;
	border: 1px solid var(--color--content--foreground-10);
	&__iconWrapper {
		background-color: var(--color--interactive--background);
		width: 68px;
		padding: 20px 8px;
		.image {
			background-position: top center;
		}
	}
	&__textContent {
		padding: 15px;
		p {
			font-size: 14px;
			font-weight: 100;
			&.points {
				font-size: 32px;
				color: var(--color--content--foreground);
				font-weight: 400;
			}
		}
	}
	@include M {
		flex-direction: column;
		width: 136px;
		&:nth-child(odd) {
			margin: 0 5px 10px 0;
		}
		&:nth-child(even) {
			margin: 0 0 10px 5px;
		}
		&__wrapper {
			padding: 30px;
		}
		&__iconWrapper {
			width: 100%;
			height: 67px;
			padding: 10px 20px;
			.image {
				background-position: top left;
			}
		}
		&__textContent {
			padding: 15px 20px 12px;
		}
	}
}
