.buttonWrapper {
}

.button {
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 0.3rem;
	padding: 0.2rem 0.4rem;
	margin: 0.1rem;
	outline: 0;
	border: 0;
}

.button {
	background-color: rgb(224, 224, 224);
}

.button svg {
	fill: #8d8d8d;
}

.button:hover,
.button:focus,
.active {
	background-color: #777;
}

.button:hover svg,
.button:focus svg,
.active svg {
	fill: #fff;
}
