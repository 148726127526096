// FAQ

.view__FAQ {
	.standardList {
		li {
			@include M {
				cursor: pointer;
			}
			.row__text--light {
				padding-right: 10px;
			}
			@include minLaptop {
				&:hover {
					.standardListRow__mainContent {
						color: var(--color--content--foreground);
					}
				}
			}
			&.active {
				opacity: 1;
				.standardListRow__extendedContent {
					opacity: 1;
					margin-top: 10px;
					transform: scaleY(1);
				}
				.standardListRow__mainContent {
					padding-bottom: 0;
				}
			}
		}
		.standardListRow {
			&__extendedContent {
				color: var(--color--content--foreground);
				margin-top: 0;
				transition: all 0.2s ease-in;
				transform: scaleY(0);
				* {
					transition: all 0.2s ease-in;
				}
			}
			&__mainContent {
				transition: padding 0.2s;
			}
		}
	}
	.faqWrapper {
		position: relative;
	}
	.faq__image {
		position: absolute;
		right: 0;
		top: 0;
		width: 25vw;
		height: 100%;
	}
	iframe {
		margin-bottom: 20px;
	}
	@include minDesktop {
		.standardList {
			li.active .standardListRow {
				&__extendedContent {
					margin-top: 15px;
				}
				&__mainContent {
					padding: 35px 50px 15px;
				}
			}
		}
	}
	@include M {
		.standardList {
			* {
				cursor: pointer;
			}
			li.active {
				.standardListRow__mainContent {
					background-color: transparent;
				}
			}
			iframe {
				width: 100%;
				height: auto;
			}
		}
	}
}
