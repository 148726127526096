.collectionMission {
	&__info {
		min-width: auto;
		box-shadow: 0 0 15px 0 var(--color--content--foreground-10);
		background-color: var(--color--content--background);
		border-right: none;
		line-height: 1.2;
		margin-left: -$spacer;
		margin-right: -$spacer;

		p {
			color: var(--color--content--foreground-60);
		}

		.description {
			color: var(--color--content--foreground);
		}

		@include media-breakpoint-up(sm) {
			border-right: 1px solid var(--color--content--foreground-10);
			background-color: transparent;
			box-shadow: none;
			min-width: 300px;
			max-width: 300px;
			margin-left: 0;
			margin-right: $spacer * 0.5;
		}
	}

	.task {
		&--completed {
			.task__status {
				background-color: $answerCorrect;
				&::after {
					content: '\f00c';
				}
			}
		}
		&--available {
			.task__status {
				background-color: #2e4884;
				&::after {
					content: '\f25a';
				}
			}
		}
		&--unavailable {
			cursor: not-allowed;

			.task__status {
				background-color: $error;
				&::after {
					content: '\f023';
				}
			}
		}
		&--retention {
			.task__status {
				background-color: $waitingStatus;
				&::after {
					content: '\f01e';
				}
			}
		}
		&--periodicity {
			.task__status {
				background-color: $waitingStatus;
				&::after {
					transform: translate(-50%, -50%);
					content: '\f017';
				}
			}
		}
		&--awaiting {
			.task__status {
				background-color: $waitingStatus;
				&::after {
					content: '\f013';
					transform: translate(-50%, -50%);
				}
			}
		}
		&--rejected {
			.task__status {
				background-color: $error;
				&::after {
					content: '\f00d';
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	&__list {
		.task__status {
			$size: 30px;
			font-family: FontAwesome;
			font-size: 1.1rem;
			position: absolute;
			right: -$size / 2;
			top: -$size / 2;
			width: $size;
			height: $size;
			color: #fff;

			&::after {
				position: absolute;
				transform: translate(-50%, -50%);
				left: 50%;
				top: 50%;
			}
		}
	}

	&__tile {
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $tile-box-shadow;

		.tile__front {
			background-color: var(--color--content--background);

			p {
				color: var(--color--interactive--background);
				border-top: 1px solid var(--color--content--foreground-5);
				box-shadow: inset 0 17px 29px -27px rgba(0, 0, 0, 0.1);
			}
		}

		.tile__image {
			height: auto;
			padding-top: 100%;
		}

		.tile__back {
			background-color: var(--color--content--background);

			p.task-name {
				color: var(--color--interactive--background);
			}

			p.task-earnings {
				color: var(--color--content--foreground);
			}

			.tile__button {
				background-color: var(--color--content--foreground-3);
			}
		}
	}

	&__rowList {
	}

	&__listRow {
		position: relative;
		background-color: transparent;
		border: {
			top: 1px solid transparent;
			left: 1px solid transparent;
			right: 1px solid transparent;
			bottom: 1px solid var(--color--content--foreground-5);
		}

		.listRow {
			&__status {
				.task__status {
					$size: 30px;
					font-family: FontAwesome;
					font-size: 1.1rem;
					position: relative;
					width: $size;
					height: $size;
					color: #fff;

					&::after {
						position: absolute;
						transform: translate(-50%, -50%);
						left: 50%;
						top: 50%;
					}
				}
			}
			&__icon {
			}
			&__name {
				color: var(--color--interactive--background);
			}
			&__progressBar {
				width: 100%;
				color: var(--color--content--foreground-50);

				@include media-breakpoint-up(sm) {
					width: auto;
					min-width: 300px;
				}
			}
			&__points {
				background-color: transparent;
			}
		}

		@include hover() {
			background-color: var(--color--content--background);
			border: 1px solid var(--color--content--foreground-5);
			box-shadow: $row-box-shadow;
			border-radius: $border-radius;

			.listRow__points {
				background-color: var(--color--content--foreground-5);
			}
		}
	}
}
