// dynamicFont

.dynamicFont {
	font-size: 28px;
	@include minLaptop {
		@include XXXXL {
			font-size: 1.8vw;
		}
		@include XXL {
			font-size: 2.4vw;
		}
	}
	@include M {
		font-size: 6vw;
		text-align: center;
	}
}
