// commentsSection

.commentsSection {
	border-radius: 10px;
	padding: 20px 30px;
	background-color: $standardBgr;
	@include core-border-radius(10px, bottom-left, bottom-right);
	textarea {
		background-color: #fff;
		border-radius: 10px;
		border: 1px solid $standardBgrDark;
		padding: 10px;
		margin-bottom: 10px;
		color: var(--color--content--foreground);
		resize: none;
		line-height: 1.3;
		&:focus {
			outline: none;
		}
	}
	.commentsWrapper {
		margin-bottom: 20px;
		border-bottom: 1px solid $standardBgrDark;
	}
	.sectionTitle {
		font-size: 14px;
		padding-bottom: 20px;
		color: var(--color--content--foreground-60);
		margin-bottom: 0;
	}
	.comment {
		padding-bottom: 30px;
		&__avatar {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			margin-right: 16px;
		}
		&__commentBox {
			padding: 10px 20px;
			border-radius: 10px;
			background-color: #fff;
			position: relative;
			color: var(--color--content--foreground-60);
			border: 1px solid $standardBgrDark;
			&::after,
			&::before {
				content: '';
				position: absolute;
				top: 20px;
				left: -10px;
				width: 0;
				height: 0;
				transform: translate(0, -50%);
			}
			&::after {
				border: {
					right: 10px solid $standardBgrDark;
					top: 10px solid transparent;
					bottom: 10px solid transparent;
				}
			}
			&::before {
				z-index: 300;
				left: -9px;
				border: {
					right: 9px solid #fff;
					top: 9px solid transparent;
					bottom: 9px solid transparent;
				}
			}
			.btn__basic {
				position: absolute;
				right: 20px;
				bottom: -12px;
			}
			.author {
				color: var(--color--interactive--background);
				font-weight: 600;
				padding-right: 5px;
				+ div {
					position: relative;
					top: 1px;
				}
			}
			.date {
				opacity: 0.7;
				margin-left: 10px;
			}
			.textContent {
				padding-top: 20px;
				font-size: 12px;
				line-height: 1.3;
			}
		}
	}
	.inputSection {
		color: var(--color--content--foreground);
		span:not(.btn__basic span) {
			color: var(--color--content--foreground-30);
		}
		textarea {
			min-height: 55px;
		}
		.remainingSigns {
			position: relative;
			top: 2px;
			span {
				color: var(--color--content--foreground-30);
			}
		}
		.btn__basic {
			margin-left: 8px;
		}
	}
	@include M {
		padding: 20px;
		.comment {
			&__avatar {
				width: 40px;
				height: 40px;
				margin-right: 14px;
			}
		}
	}
}
