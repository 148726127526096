// roundVisualStatus

.roundVisualStatus {
	display: none;
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	z-index: 200;
	flex-shrink: 0;
	&__signWrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 58%;
		height: 58%;
		&::after,
		&::before {
			position: absolute;
			content: '';
			min-width: 3px;
			border-radius: 5px;
			background-color: #fff;
			transition: height 0.15s ease-in, background-color 0.15s ease-in;
			bottom: 0;
			left: 0;
		}
	}
	&--incorrect {
		@include roundVisualStatus__incorrect;
	}
	&--incorrect--mini {
		@include roundVisualStatus__incorrect--mini;
	}
	&--awaiting {
		@include roundVisualStatus__awaiting;
	}
	&--awaiting--mini {
		.roundVisualStatus__signWrapper {
			top: 54%;
		}
		@include roundVisualStatus__awaiting--mini;
	}
	&--correct {
		@include roundVisualStatus__correct;
	}
	&--mobile {
		display: block;
		width: 163px;
		height: 163px;
	}
	&--transparent {
		background-color: transparent;
		border: 4px solid $answerCorrectDark;
		.roundVisualStatus__signWrapper {
			&::after,
			&::before {
				border-radius: 0;
				background-color: $answerCorrectDark;
			}
			&::after {
				height: 12%;
			}
			&::before {
				width: 12%;
			}
		}
	}
}
