/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

.CircularProgressbar {
	/*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
	width: 88px;
	height: 88px;

	@include media-breakpoint-down(xs) {
		width: 68px;
		height: 68px;
	}
}

.CircularProgressbar .CircularProgressbar-path {
	stroke: var(--color--primary--background);
	stroke-linecap: round;
	transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
	stroke: #ddd;
}

.CircularProgressbar .CircularProgressbar-text {
	fill: var(--color--primary--background);
	font-size: 20px;
	dominant-baseline: middle;
	text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
	fill: #ddd;
}

/**
* Function to generate procentual progress classes
* .p-1 1% -> p-100 100%
**/
$step: 1;
$loops: round(100 / $step);
$increment: 360 / $loops;
$half: round($loops / 2);

$color-back: #ddd;
$color-bar: var(--color--primary--background);

@for $i from 1 through $loops {
	.progress-#{$i*$step} {
		@if $i < $half {
			$nextdeg: 90deg + ($increment * $i);
			background-image: linear-gradient(90deg, $color-back 50%, transparent 50%, transparent),
				linear-gradient($nextdeg, $color-bar 50%, $color-back 50%, $color-back);
		} @else {
			$nextdeg: -90deg + ($increment * ($i - $half));
			background-image: linear-gradient($nextdeg, $color-bar 50%, transparent 50%, transparent),
				linear-gradient(270deg, $color-bar 50%, $color-back 50%, $color-back);
		}
	}
}
