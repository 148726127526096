// commons

.font-weight-medium {
	font-weight: 500 !important;
}

%wrapper--row {
	flex-direction: row;
}

%wrapper {
	display: flex;
}

.wrapper {
	// Row and col modifier defines children direction in parent container.
	&--row,
	&--col {
		@extend %wrapper;
	}
	&--row {
		@extend %wrapper--row;
		&--wrap {
			flex-wrap: wrap;
		}
		&--wrapReverse {
			flex-wrap: wrap-reverse;
		}
	}
	&--col {
		min-height: 0;
		flex-direction: column;
		&--stretch {
			height: 100%;
		}
	}
}

.justify {
	&--start {
		justify-content: flex-start;
	}
	&--end {
		justify-content: flex-end;
	}
	&--center {
		justify-content: center;
	}
	&--between {
		justify-content: space-between;
	}
	&--around {
		justify-content: space-around;
	}
}

.align {
	&--start {
		align-items: flex-start;
	}
	&--end {
		align-items: flex-end;
	}
	&--center {
		align-items: center;
	}
	&--baseline {
		align-items: baseline;
	}
	&--stretch {
		align-items: stretch;
	}
}

.el {
	&--shrink {
		flex-shrink: 0;
	}
	&--grow {
		flex-grow: 1;
	}
	&--align {
		&--start {
			align-self: flex-start;
		}
		&--end {
			align-self: flex-end;
		}
		&--center {
			align-self: center;
		}
		&--baseline {
			align-self: baseline;
		}
		&--stretch {
			align-self: stretch;
		}
	}
}

%el {
	&--shrink {
		flex-shrink: 0;
	}
	&--grow {
		flex-grow: 1;
	}
	&--align {
		&--start {
			align-self: flex-start;
		}
		&--end {
			align-self: flex-end;
		}
		&--center {
			align-self: center;
		}
		&--baseline {
			align-self: baseline;
		}
		&--stretch {
			align-self: stretch;
		}
	}
}

%image {
	background: {
		repeat: no-repeat;
		position: center;
	}
}

.image {
	&--contain {
		background-size: contain;
	}
	&--cover {
		background-size: cover;
	}
	&--contain,
	&--cover {
		@extend %image;
	}
}

.sticky {
	position: fixed;
	z-index: 200;
	width: 100%;
	& + * {
		position: relative;
	}
}

.mainContainer {
	height: 100%;
	overflow: hidden;
}

div.view {
	width: 100%;
	height: 100%;
	z-index: 300;
	padding: 30px 30px 0 30px;
	animation: slideUp 0.2s ease-in;
	flex-grow: 1;
	overflow-x: hidden;
	@include minDesktop {
		padding: 30px 50px 0 45px;
	}
	@include M {
		padding: 0;
		overflow: auto;
		overflow-x: hidden;
		animation: slideUpMobile 0.15s ease-in;
		background-color: var(--color--background);
		//background: linear-gradient(to bottom, $mobileTopGradientBgr 0%, $mobileTopGradientBgr 10%, #FFF 28%, #FFF 100%);
	}
	@include hXS {
		@include wholePageScroll;
	}
	//@include hM { padding: 25px 45px 15px 45px; }
	&--withoutPadding {
		padding: 0;
		@include L {
			padding: 0;
		}
	}
	.mainContentWrapper {
		margin: 10px 20px 0 20px;
		flex-grow: 1;
		// IE...
		height: 100%;
		@include minDesktop {
			margin: 20px 40px 0 40px;
		}
		@include L {
			margin: 40px 0 0 0;
		}
		@include M {
			margin: 0;
		}
	}
}

.overflow--auto {
	overflow: auto;
}

.overflow--hidden {
	overflow: hidden;
}

.sectionTitle {
	font-size: 1.4em;
	color: var(--color--content--foreground);
	margin-bottom: 15px;
	font-weight: 400;
	&--doubleMargin {
		margin-bottom: 30px;
	}
}
.standardText {
	font-size: 0.9rem;
	color: var(--color--content--foreground-60);
	line-height: 1.3;
	font-weight: 400;
	margin-bottom: 1rem;
	&--noMargin {
		margin-bottom: 0;
	}
	@include minDesktop {
		font-size: 1rem;
	}
}

.visible {
	&--mobile {
		@include M {
			display: inline-flex;
		}
		@include minLaptop {
			display: none;
		}
	}
	&--laptop {
		@include M {
			display: none;
		}
		@include minLaptop {
			display: inline-flex;
		}
	}
	&--desktop {
		@include XXXL {
			display: none;
		}
		@include minDesktop {
			display: inline-flex;
		}
	}
}

.hidden {
	&--mobile {
		@include M {
			display: none;
		}
	}
	&--laptop {
		@include minLaptop {
			display: none;
		}
		@include minDesktop {
			display: inline-flex;
		}
	}
	&--desktop {
		@include minDesktop {
			display: none;
		}
	}
}

.text-end {
	text-align: end;
}
