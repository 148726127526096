// popup

.addSubmission {
	color: var(--color--content--foreground-60);
	font-size: 13px;
	text-align: center;
	overflow: visible;
	z-index: 500;
	background-color: var(--color--content--background);

	@include core-border-radius(10px, top-left, bottom-left, bottom-right);
	flex-grow: 1;
	@include minLaptop {
		flex-grow: 0;
	}
	a,
	button.linkStyle {
		color: var(--color--interactive--background);
		position: relative;
		&::after {
			content: '';
			width: 0;
			border-bottom: 1px solid var(--color--interactive--background);
			position: absolute;
			bottom: -2px;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: width 0.15s ease-in;
		}
		&:hover::after {
			width: 100%;
		}
	}
	button.linkStyle {
		border: 0;
		background: none;
		padding: 0;
		cursor: pointer;
		text-transform: lowercase;
		&:focus {
			outline: 0;
		}
	}
	textarea {
		width: 100%;
		height: 141px;
		&.titleArea {
			font-size: 14px;
			height: 58px;
			@include M {
				height: 100px;
				font-size: 16px;
			}
		}
		&:focus {
			box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05) inset;
		}
	}
	input[type='text'] {
		background-color: var(--color--content--background);
		border: 1px solid var(--color--content--foreground-10);
		color: var(--color--interactive--background);
		font-size: 14px;
		border-radius: 10px;
		height: 34px;
		width: 100%;
		padding: 0 15px;
		line-height: 1;
		text-align: center;
		&::placeholder {
			color: inherit;
		}
		&:focus {
			outline: none;
			box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05) inset;
		}
	}
	.submissionPopup__wrapper {
		width: 600px;
	}
	.addSubmission__contentWrapper {
		background-color: var(--color--content--foreground-5);
		@include core-border-radius(10px, bottom-left, bottom-right);
		.btn__basic {
			/*height: 23px;
			width: 86px;
			font-size: 12px;
			min-width: 0;
			padding: 7px 0 9px;*/
			&.loading {
				width: auto;
				span {
					padding: 0 26px 0 10px;
				}
			}
		}
	}
	.submissionType {
		padding: 60px 20px;
		&__type:not(:last-child) {
			margin-right: 11px;
		}
		svg {
			height: 88px;
			margin-right: 9px;
			cursor: pointer;
			path {
				fill: var(--color--interactive--background);
			}
		}
		.btn__basic {
			margin-top: 10px;
		}
		.fileInfo {
			font-size: 12px;
			padding-top: 10px;
			.type {
				color: var(--color--content--foreground);
			}
		}
	}
	.addText {
		padding: 30px 30px 35px 30px;
		label {
			margin: 13px 0 20px;
			cursor: pointer;
		}
		strong {
			color: var(--color--content--foreground);
		}
		.btn__basic {
			margin: 35px 0 10px;
			&--noMargin {
				margin: 0;
			}
		}
	}
	.addImage,
	.addVideo,
	.addLink {
		padding: 60px 130px 30px 130px;
		width: 100%;
		svg {
			path {
				fill: var(--color--interactive--foreground);
			}
		}
		input[type='text'] {
			margin: 27px 0;
		}
	}
	.addLink {
		padding-bottom: 60px;
	}
	.addGif {
		padding: 30px 130px;
		.gifBox {
			width: 100%;
			margin: 30px 0;
			div {
				border-radius: 10px;
				width: 108px;
				height: 108px;
				overflow: hidden;
				cursor: pointer;
				&:hover img {
					height: 110%;
				}
			}
			img {
				height: 100%;
				width: auto;
				transition: height 0.15s linear;
			}
		}
	}
	.graphicItemPreview {
		&__textContentWrapper {
			padding: 40px 0 34px 0;
		}
		.item {
			background: {
				size: contain;
				repeat: no-repeat;
				position: center;
			}
			height: 500px;
		}
		img {
			width: auto;
			max-width: 100%;
			max-height: 60vh;
		}
		.btn__basic {
			margin-bottom: 11px;
		}
	}
	.addItemBox {
		width: 100%;
		border-radius: 10px;
		padding: 25px 0;
		color: #fff;
		font-size: 14px;
		position: relative;
		background-color: var(--color--interactive--background);
		margin-bottom: 30px;
		transition: box-shadow 0.1s ease-in;
		@include minLaptop {
			&:hover {
				box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1) inset;
			}
		}
		svg {
			width: auto;
			height: 90px;
			margin-bottom: 20px;
			&:not(:last-child) {
				margin-right: 55px;
			}
		}
		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
			opacity: 0;
			cursor: pointer;
		}
	}
	.submissionConfirmation {
		padding: 50px;
		.roundVisualStatus {
			width: 150px;
			height: 150px;
			margin-bottom: 50px;
		}
		&__text {
			font-size: 16px;
		}
		.btn__basic {
			margin-top: 50px;
		}
	}
	@include M {
		width: 100%;
		textarea,
		input {
			font-size: 16px;
		}
		.submissionPopup {
			&__technicalWrapper {
				justify-content: flex-start;
			}
			&__wrapper {
				flex-grow: 1;
			}
		}
		.addSubmission__contentWrapper {
			flex-grow: 1;
			.btn__basic {
				font-size: 16px;
				height: 36px;
				width: auto;
			}
		}
		.submissionPopup__wrapper {
			width: 100%;
		}
		.submissionType {
			padding: 30px 10px 10px;
			&__type {
				margin: 0 18px 28px 18px;
			}
			img {
				margin-right: 0;
			}
		}
		.addText {
			flex-grow: 0;
			padding: 30px 20px 35px 20px;
			textarea {
				flex-grow: 1;
			}
			.btn__basic {
				margin-top: 25px;
			}
		}
		.addImage,
		.addVideo,
		.addLink,
		.addGif {
			padding: 30px 20px 30px 20px;
			justify-content: space-between;
		}
		.addGif {
			padding-top: 30px;
			.gifBox div {
				flex: 1;
				width: auto;
				height: 141px;
				&:first-of-type {
					margin-right: 10px;
				}
			}
		}
		.graphicItemPreview {
			&__textContentWrapper {
				padding: 30px 0 34px 0;
			}
		}
	}
}
