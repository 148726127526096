.view--faq {
	ul.faq__list {
		li {
			font-size: 0.85rem;
			cursor: pointer;
			margin-bottom: 1px;

			&.active {
				.answer {
					display: block;
				}
			}

			&:not(:last-child) {
				border-bottom: 1px solid var(--color--content--foreground-10);
			}

			&:hover {
				background-color: var(--color--content--foreground-5);
				border-bottom-color: transparent;
			}

			.question {
				color: var(--color--interactive--background);
			}

			.category {
				font-size: 0.9rem;
				color: var(--color--content--foreground);
				white-space: nowrap;

				span {
					color: var(--color--content--foreground-60);
				}
			}

			.answer {
				display: none;
				color: var(--color--content--foreground-60);
				font-size: 1rem;
			}
		}
	}
}
