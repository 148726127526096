// _cafeteria.scss

.view--cafeteria {
}

.cafeteria {
	&__filters {
		min-width: auto;
		box-shadow: 0 0 15px 0 var(--color--content--foreground-10);
		background-color: var(--color--content--background);
		border-right: none;
		line-height: 1.2;
		margin-left: -$spacer;
		margin-right: -$spacer;

		@include media-breakpoint-up(sm) {
			border-right: 1px solid var(--color--content--foreground-10);
			background-color: transparent;
			box-shadow: none;
			min-width: 300px;
			max-width: 300px;
			margin-left: 0;
			margin-right: $spacer * 0.5;
		}

		.filter {
			&__price {
				font-size: 1.2rem;

				input {
					font-weight: 600;
				}
			}
		}
	}

	.store__item {
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $tile-box-shadow;

		.tile__image {
			height: auto;
			padding-top: 100%;
		}

		.item__name {
			color: var(--color--interactive--background);
		}

		.item__price {
		}

		.item__key-value {
			& > div:not(:last-of-type) {
				border-bottom: 1px solid var(--color--content--foreground-10);
			}
		}

		.item__description {
		}

		.item__button {
			background-color: var(--color--content--foreground-3);
		}
	}
}
