// standardPreview

.standardPreview {
	position: relative;
	z-index: 600;
	height: inherit;
	background-color: var(--color--content--background);
	&__header {
		min-height: $headerHeight--mobile;
		padding-right: 30px;
		background-color: var(--color--primary--background);
		color: var(--color--primary--foreground);
		position: relative;
		animation: standardPreviewSlide 0.5s ease-out;
		z-index: 100;
		@include minLaptop {
			min-height: $headerHeight--laptop;
		}
		@include minDesktop {
			min-height: $headerHeight--desktop;
		}
		.titleWrapper {
			margin: 10px 0;
		}
		.currencyContainer span {
			color: var(--color--primary--foreground);
			margin-right: 15px;
			font-size: 2em;
		}
		h1 {
			font-size: 28px;
			padding: 0 35px;
			border-left: 1px solid var(--color--primary--foreground-10);
		}
	}
	&__footer {
		position: relative;
		animation: standardPreviewSlide 0.5s ease-out;
		background-color: var(--color--primary--background);
		min-height: $footerHeight;
	}
	.currenciesWrapper {
		margin-bottom: 40px;
	}
	&__mainWrapper {
		position: relative;
		animation: standardPreviewSlide 0.5s ease-out;
		padding: 35px 30px 30px 30px;
		height: 0;
	}

	&--disabledEffect > &__header,
	&--disabledEffect > &__footer,
	&--disabledEffect > &__mainWrapper {
		animation: none;
	}
	.standardPreview__column {
		transition: all 0.2s ease-in;
		&--left {
			padding-right: 4vw;
			width: 27.5%;
		}
		&--middle {
			padding: 0 4vw;
			width: 45%;
			border: {
				left: 1px solid var(--color--content--foreground-10);
				right: 1px solid var(--color--content--foreground-10);
			}
			img:not(.imageViewer__miniature) {
				width: 70%;
				height: auto;
				max-width: 700px;
				margin-bottom: 30px;
			}
		}
		&--right {
			padding-left: 4vw;
			width: 27.5%;
		}
	}
	&.profile,
	&.challengeDetails {
		.challengeDetails,
		.profile {
			&__descriptionSection {
				color: var(--color--content--foreground-60);
				margin-right: 30px;
				font-size: 16px;
				width: 235px;
				@include M {
					width: auto;
				}
				.markdown {
					font-size: 1em;
				}
				.sectionTitle {
					font-size: 1.1em;
					margin: 30px 0 25px 0;
					@include minDesktop {
						margin-top: 28px;
					}
					color: var(--color--content--foreground-60);
				}
			}
			&__details {
				padding-bottom: 20px;
				font-size: 1em;
				color: var(--color--content--foreground-60);
			}
			&__detailsRow {
				padding: 10px 0;
				border-bottom: 1px solid var(--color--content--foreground-10);
				span,
				div {
					&:last-child {
						color: var(--color--content--foreground);
						font-weight: 400;
					}
				}
			}
		}
		.standardPreview__mainWrapper {
			padding: 0 30px 0 30px;
		}
		.contentSection {
			&__header {
				height: 70px;
				border-bottom: 1px solid var(--color--content--foreground-10);
			}
			&__contentWrapper {
				width: 100%;
			}
		}
	}
	@include M {
		&__header {
			padding: 10px 20px 10px 0;
			h1 {
				padding: 0 30px;
				font-size: 24px;
			}
		}
		&__mainWrapper {
			padding: 35px 30px 30px 30px;
			overflow: auto;
		}
		&__columnWrapper {
			flex-direction: column;
		}
		.standardPreview__column {
			width: 100%;
			padding: 10px 0;
			&--middle {
				order: 1;
				border: none;
				img:not(.imageViewer__miniature) {
					width: 80%;
				}
			}
			&--left {
				order: 2;
			}
			&--right {
				order: 3;
			}
		}
		&.profile {
			.profile {
				&__details {
					padding: 20px 0 10px 0;
				}
				&__descriptionSection {
					padding: 30px;
					width: 100%;
					margin-right: 0;
					font-size: 3.5vw;
					> * {
						position: relative;
					}
					@include XS {
						font-size: 14px;
					}
				}
				&__contentSection {
					border-bottom: 0;
					position: relative;
					.contentSection__contentWrapper {
						> *:not(.loaderWrapper) {
							position: relative;
						}
					}
				}
			}
			.standardPreview__mainWrapper {
				padding: 0 30px 0 30px;
			}
			.contentSection {
				&__header {
					border-bottom: 0;
					height: auto;
				}
			}
			.standardPreview {
				&__mainWrapper {
					flex-direction: column;
					padding: 0;
				}
			}
		}
	}
}
