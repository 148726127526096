// basic select

.dropdownList {
	border: 1px solid var(--color--content--foreground-20);
	color: var(--color--content--foreground-60);
	width: 100%;
	border-radius: 25px;
	position: relative;
	&:hover {
		color: var(--color--interactive--background);
		border-color: var(--color--interactive--background);
		.dropdownList__arrow {
			background: linear-gradient(
				to bottom,
				var(--color--interactive--background) 0%,
				color-mod(var(--color--interactive--background) shade(0.9)) 100%
			);
		}
	}
	select {
		font-weight: 100;
		appearance: none;
		z-index: 200;
		width: 100%;
		cursor: pointer;
		background-color: transparent;
		padding: 5px 12px;
		outline: none;
		border: none;
		height: 25px;
		color: inherit;
		&::-ms-expand {
			display: none;
		} // sass-lint:disable-line no-vendor-prefixes
		&:active + .dropdownList__arrow {
			transform: rotate(0deg);
		}
		option {
			padding: 10px;
		}
	}
	.dropdownList__arrow {
		position: absolute;
		top: 0;
		right: 0;
		width: 25px;
		height: 25px;
		background-color: var(--color--content--foreground-20);
		border-radius: 50%;
		transform: rotate(180deg);
		transition: transform 0.2s ease-in;
		&::after,
		&::before {
			content: '';
			width: 2px;
			height: 7px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&::after {
			transform: translate(-4px, -4px) rotate(45deg);
		}
		&::before {
			transform: translate(1px, -4px) rotate(-45deg);
		}
	}
}
