.battlesList {
	$user-avatar-size: 50px;

	&__load-more-icon {
		width: 24px;
		height: 24px;
		background-color: var(--color--interactive--background);
		border-radius: 1rem;
		color: var(--color--interactive--foreground);
		cursor: pointer;
	}

	&__empty-tile {
		padding-bottom: 100px;

		.bottom-right {
			bottom: 5px;
			right: 10px;
		}
	}

	&__tile {
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $tile-box-shadow;
		background-color: var(--color--content--background);

		&--clickable {
			cursor: pointer;
		}

		.tile__front {
			background-color: var(--color--content--background);
		}

		.tile__image {
			width: $user-avatar-size;
			height: $user-avatar-size;
			background-color: var(--color--interactive--background);
		}

		.progress-bar-container {
			background-color: color-mod(var(--color--content--background) shade(0.2));
		}

		.tile__color--gray {
			color: var(--color--content--foreground-50);
		}

		.tile__color--main {
			color: var(--color--content--foreground);
		}

		.tile__player {
			@include media-breakpoint-down(xs) {
				margin-bottom: ($user-avatar-size/4);
			}
		}

		.tile__score {
			position: absolute;
			background-color: #ff591d;
			color: white;
			border-radius: 12px;
			padding: 4px 7px 3px 7px;
			bottom: 0;
		}

		.tile__score--left {
			@include media-breakpoint-up(sm) {
				left: $user-avatar-size - ($user-avatar-size/4);
			}

			@include media-breakpoint-down(xs) {
				left: 50%;
				transform: translateX(-50%);
				margin-bottom: -($user-avatar-size/4);
			}
		}

		.tile__score--right {
			@include media-breakpoint-up(sm) {
				right: $user-avatar-size - ($user-avatar-size/4);
			}

			@include media-breakpoint-down(xs) {
				left: 50%;
				transform: translateX(-50%);
				margin-bottom: -($user-avatar-size/4);
			}
		}

		.tile__score--winner {
			background-color: #5bacf6;
		}

		.tile__completed {
			padding: 8px 6px;
		}

		.tile__canceled {
			padding: 8px 6px;
		}

		.tile__resolved {
			padding: 8px 6px;
			border-radius: 8px;
			background-color: rgba(255, 89, 29, 0.15);

			&--winner {
				background-color: rgba(91, 172, 246, 0.15);
			}
		}
	}
}
