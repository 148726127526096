.tabs {
	width: 0;
	min-width: 100%;

	ul {
		overflow-x: auto;
		li {
			a {
				font-size: 1rem;
				color: var(--color--content--foreground);
				white-space: nowrap;
				position: relative;
				transition: color 0.2s;
				&:hover,
				&.active {
					color: var(--color--interactive--foreground);
					background-color: var(--color--interactive--background);
				}
				.badge {
					color: var(--color--primary--foreground);
					background-color: var(--color--primary--background);
					min-width: 1.6em;
				}
			}
		}
	}
}
