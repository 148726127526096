.user-agreements {
	position: fixed;
	z-index: 1000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;

	form {
		background-color: var(--color--content--background);
		margin-bottom: 30px;
		@include core-border-radius(10px, top-left, bottom-left, bottom-right);
	}

	.background {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: fixed;
		background-color: var(--color--content--foreground-60);
		z-index: 200;
	}

	.markdown {
		p {
			padding-bottom: 0;
		}
	}

	.content {
		width: 640px;
		z-index: 300;
		border-radius: 8px;
		color: var(--color--primary--foreground);
		font-size: 14px;
		position: relative;
		margin: 40px 0;

		@include M {
			width: calc(100% - 40px);
		}

		.errorWrapper {
			min-height: 25px;
			text-align: center;
			padding: 2px 0;
			.errorMessage.rulesError,
			.errorMessage--visible {
				display: block;
			}
		}

		section.agreements-content {
			padding: 30px;
			background-color: var(--color--content--background);
			@include core-border-radius(10px, top-left, top-right);

			h2 {
				font-size: 28px;
				text-align: center;
				padding: 0 0 10px 0;
				color: var(--color--content--foreground);
			}

			section.agreements {
				&.rodo {
					.warning {
						padding: 10px 20px;
						margin: 20px 0;
						background-color: #ff4646;
						border-radius: 8px;
						p {
							color: #fff;
							line-height: 1.5;
						}
					}
				}
				h3 {
					text-align: center;
					font-weight: 600;
					font-size: 18px;
					margin: 10px 0;
					color: var(--color--content--foreground);
				}
				div.agreements {
					.agreement {
						color: var(--color--content--foreground);
					}
				}
			}
		}

		section.confirm {
			padding: 20px;
			text-align: center;
			background-color: var(--color--content--foreground-10);
			@include core-border-radius(10px, bottom-left, bottom-right);
		}
	}
}
.your-agreements {
	cursor: pointer;
	font-size: 1rem;

	@include M {
		display: none;
	}
}
.user-agreements-button {
	background: none;
	border: none;
	font-size: 20px;
	outline: 0;
	padding: 0;
	cursor: pointer;
	color: var(--color--primary--foreground);
}
