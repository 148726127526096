.palette {
	position: absolute;
	bottom: 5px;
	right: 5px;
	z-index: 5000;
	table {
		background-color: rgba(255, 255, 255, 0.7);
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		tbody {
			tr {
				padding: 20px 0 10px;
				box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
				th,
				td {
					border: 0;
					width: 80px;
				}
				th {
					color: #666;
					padding: 10px 5px;
					text-align: center;
					font-weight: 600;
				}
				td {
					text-align: center;
					padding: 5px;
				}
			}
		}
	}
	input {
		cursor: pointer;
		width: 50px;
		height: 50px;
		border: 0;
		border-radius: 5px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
		transition: all 0.05s ease-in;
		&:hover {
			box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.08);
			transform: scale(1.05);
		}
	}
}
