// attributes

@keyframes progress {
	0% {
		stroke-dasharray: 0 1000;
	}
}

.attributes {
	width: 100%;
	margin: 100px 0;
	.svgWrapper {
		position: absolute;
		width: 117px;
		height: 117px;
		top: 0;
		left: 0;
		border-radius: 50%;
		background-color: var(--color--content--foreground-10);
	}
	svg {
		display: block;
		position: absolute;
		z-index: 100;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		path {
			fill: none;
			stroke-width: 4px;
			animation: progress 2s ease-in-out;
		}
	}
	.attribute {
		margin: 0 13px 30px 13px;
		color: var(--color--content--foreground);
		&__chartWrapper {
			position: relative;
			height: 117px;
			width: 117px;
			&:hover {
				.infoCloud {
					display: block;
				}
			}
			.image {
				width: 105px;
				height: 105px;
				border-radius: 50%;
				position: relative;
				z-index: 200;
				transform: translate(0.5px, 0.5px);
				background-color: var(--color--content--foreground-10);
			}
		}
		&__textData {
			text-align: center;
			max-width: 100px;
			padding-top: 8px;
			cursor: default;
			&:hover {
				.number p {
					&:first-child {
						display: none;
					}
					&:nth-child(2) {
						display: block;
					}
				}
			}
			.name {
				font-size: 14px;
				cursor: default;
			}
			.number {
				padding-top: 3px;
				font-size: 26px;
				font-weight: 400;
				p:nth-child(2) {
					display: none;
				}
			}
		}
	}
	.infoCloud {
		display: none;
		position: absolute;
		width: max-content;
		min-width: 0;
		max-width: 350px;
		box-shadow: 0 0 4px 3px var(--color--content--foreground-10);
		border-radius: 10px;
		padding: 10px 20px 7px 20px;
		color: var(--color--content--foreground);
		font-size: 14px;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 0);
		text-align: center;
		&::after {
			content: '';
			position: absolute;
			left: 50%;
			bottom: 100%;
			transform: translate(-50%, 0);
			width: 0;
			height: 0;
			border: {
				bottom: 10px solid var(--color--content--background);
				left: 10px solid transparent;
				right: 10px solid transparent;
			}
		}
		p {
			padding: 3px 0;
			font-weight: 400;
			&:nth-child(2) {
				opacity: 0.5;
			}
		}
	}
	@include M {
		margin: 0;
		padding: 0 15px;
		.attribute {
			margin: 0 10px 10px 10px;
		}
	}
	@include S {
		padding: 0;
		.attribute {
			margin: 0 8px 10px 8px;
		}
		.infoCloud {
			width: 200px;
			padding: 13px 10px;
		}
	}
}
