.missionList__tile {
	border: 1px solid var(--color--content--foreground-5);
	box-shadow: $tile-box-shadow;

	.tile__front,
	.tile__back {
		background-color: var(--color--content--background);
	}

	.tile__image {
		width: 60%;
		height: auto;
		padding-top: 60%;
		background-color: var(--color--content--foreground-5);
	}

	p.task-name {
		color: var(--color--interactive--background);
	}

	p.task-activity {
		color: var(--color--content--foreground-30);

		strong {
			color: var(--color--content--foreground);
			font-weight: bolder;
		}
	}

	.tile__back {
		p {
			color: var(--color--content--foreground-30);
		}

		.task__button {
			background-color: var(--color--content--foreground-3);
		}
	}
}
