.user-profile {
	font-size: 1rem;

	.summary {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 300px;
		}

		.membership {
			font-size: 0.75rem;
			.team-avatar {
				width: 52px;
				height: 52px;
				background: {
					color: var(--color--primary--background);
					size: cover;
					position: center;
					repeat: no-repeat;
				}
			}
			.team-name {
				color: var(--color--interactive--background);
			}
		}

		.level {
			background-color: var(--color--content--foreground-5);
			small {
				color: var(--color--content--foreground-50);
			}
		}
	}

	.info {
		font-size: 0.8rem;
		background-color: color-mod(var(--color--content--background) shade(0.1));

		@include media-breakpoint-up(md) {
			background-color: var(--color--content--background);
		}

		h6 {
			font-size: 0.85rem;
			color: var(--color--content--foreground-50);
		}

		i {
			font-size: 1.2rem;
			color: var(--color--content--foreground-20);
		}
	}
}
