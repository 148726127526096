//NOTE: hack (react-sortable-hoc library move answer outside of the view)
.answer--global.wrapper--row {
	.answer__contentArea {
		pointer-events: none;
		.standardText {
			color: var(--color--content--foreground);
		}
	}
	&[style^='position'] {
		.answer__contentArea {
			background-color: var(--color--content--foreground-5);
			.standardText {
				color: var(--color--interactive--background);
			}
		}
		.icon path {
			fill: var(--color--interactive--background);
		}
	}
	.icon {
		min-width: 30px !important;
		max-width: 30px !important;
		width: 30px !important;
		height: 30px !important;
		align-self: center;
		margin-right: 20px;
		margin-bottom: 0 !important;
	}
	img.icon {
		visibility: hidden;
	}
	.icon path {
		fill: var(--color--content--foreground-30);
	}
	@include hooverSupported {
		&:hover {
			.answer__contentArea .standardText {
				color: var(--color--interactive--background);
			}
			.icon path {
				fill: var(--color--interactive--background);
			}
		}
	}
	@include touchDevices {
		.icon path {
			transform: scale(1);
			transiton: all 0.3s ease-in;
			transform-origin: center center;
		}
		&.mobileOrder {
			.icon path {
				fill: var(--color--interactive--background);
				transform: scale(0.8);
			}
			.answer__contentArea .standardText {
				color: var(--color--interactive--background);
			}
		}
	}
}
