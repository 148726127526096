// repetitionTree

.repetitionTree {
	margin-bottom: 60px;
	position: relative;
	max-width: 900px;
	ul {
		margin: 0;
		li {
			padding: 40px;
		}
	}
	.statusCircle {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		position: relative;
		border: 10px solid #fff;
		box-sizing: content-box;
		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			color: #fff;
			font-family: FontAwesome;
			font-size: 50px;
			text-align: center;
		}
		&:hover {
			@include minLaptop {
				.infoCloud {
					display: block;
				}
			}
		}
		&--completed {
			background-color: $answerCorrect;
			&::before {
				content: '\f00c';
			}
		}
		&--repetition {
			background-color: $yellowStatus;
			&::before {
				content: '\f01e';
			}
		}
		&--blocked {
			background-color: $standardBgr;
			&::before {
				content: '\f023';
			}
		}
	}
	.connectedNodeLines {
		position: absolute;
		top: 0;
		left: 0;
	}
	.infoCloud {
		font-size: 16px;
		max-width: 260px;
		z-index: 400;
		background-color: var(--color--content--background);
		&::before,
		&::after {
			top: 32px;
		}
		.title {
			color: var(--color--content--foreground-60);
			font-weight: 400;
		}
		.stats {
			padding-top: 20px;
			font-size: 0.8em;
			&__column {
				> div {
					flex: 1;
					min-width: 100px;
					&:last-child {
						padding-left: 20px;
					}
				}
			}
			&__numbers {
				font-size: 16px;
				strong {
					display: block;
					margin-top: 7px;
				}
			}
			.currencyContainer {
				margin-top: 5px;
			}
		}
	}
	@include M {
		ul {
			justify-content: center;
			li {
				padding: 20px;
			}
		}
		.infoCloud {
			display: none;
		}
	}
	@include S {
		ul li {
			padding: 10px;
		}
	}
}
