// _map.scss

.map {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 400;
	background-color: var(--color--content--background);
}
