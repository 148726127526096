// graph

.graphContainer {
	height: 100%;
	> div.wrapper--col {
		height: 100%;
	}
	.gamfiGraph {
		height: 100%;
		overflow: hidden;
	}
	.headerGraph {
		width: 100%;
		padding: 20px 20px;
		margin-top: 18px;
		background-color: rgba(0, 0, 0, 0.2);
		@include hM {
			margin-top: 10px;
		}
		@include XXXL {
			@include hL {
				margin-top: 10px;
			}
		}
		@include XL {
			margin-top: 0;
		}
		h1 {
			color: #fff;
			font: {
				size: 34px;
				weight: 100;
			}
			@include L {
				font-size: 28px;
			}
			@include S {
				font-size: 30px;
			}
			@include XS {
				font-size: 24px;
			}
		}
		p {
			color: rgba(#fff, 0.6);
			width: 100%;
			font-size: 16px;
			font-weight: 100;
			text-align: center;
			padding-top: 5px;
		}
	}
	.graph {
		svg {
			display: block;
		}
		.taskIcon {
			path {
				fill: #92b161;
			}
			&.reached {
				path {
					fill: #fff;
				}
			}
		}
		.achievementIcon {
			path {
				fill: #92b161;
			}
			&.reached {
				path {
					fill: #fff;
				}
			}
		}
	}
}

.sectionZoom {
	position: absolute;
	bottom: 32px;
	right: 32px;
	z-index: 300;
	.roundNumber {
		position: static;
		background-color: var(--color--interactive--background);
		box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.5) inset;
		height: 20px;
		margin-left: 10px;
		&:hover .number {
			&::before,
			&.plus::after {
				transform: translate(-50%, -50%) scale(1.3);
			}
		}
		.number {
			background-color: var(--color--interactive--background);
			position: relative;
			cursor: pointer;
			&::after,
			&::before {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 10px;
				height: 2px;
				background-color: #fff;
				transform: translate(-50%, -50%);
				transition: all 0.15s ease-in;
			}
			&.plus {
				&::after {
					content: '';
					width: 2px;
					height: 10px;
				}
				&::before {
					content: '';
				}
			}
			&.minus::before {
				content: '';
			}
		}
	}
	@include M {
		@-moz-document url-prefix() {
			// sass-lint:disable-line no-vendor-prefixes
			.container {
				top: 20px;
			}
		}
	}
}

@-moz-document url-prefix() {
	// sass-lint:disable-line no-vendor-prefixes
	@include M {
		.sectionZoom {
			bottom: 70px;
		}
	}
}

.grabbable {
	cursor: move;
}
.clickable {
	cursor: pointer;
}
