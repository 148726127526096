// newsPreview

.newsDetails {
	@include minLaptop {
		padding-bottom: 70px;
	}
	.viewTitleBox {
		border-bottom: none;
	}
	.subtitle span {
		color: var(--color--content--foreground);
	}
	&__contentWrapper {
		padding: 10px;
		@include minLaptop {
			padding: 10px 15px;
		}
	}
	.textContentWrapper {
		flex: 3;
	}
	.imageContainer {
		flex: 2;
		background-position: top;
		padding-bottom: 30px;
		margin-left: 30px;
		img {
			width: 100%;
			height: auto;
		}
	}
	.commentsSection {
		margin-top: 20px;
	}
	@include M {
		display: block;
		.imageContainer {
			background-size: 0;
			flex: initial;
			margin-left: 0;
			img {
				max-width: 400px;
			}
		}
		.markdown {
			flex: initial;
		}
		&__contentWrapper {
			padding: 30px;
			flex-direction: column-reverse;
		}
	}
}
