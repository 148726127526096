.extended-entry {
	$loupe-size-width: 30px;
	$loupe-size-height: 15px;

	$close-size: 20px;

	$cloud-offset: 10px;
	$cloud-distance: 0;
	$cloud-min-width: 250px;
	$cloud-max-width: 250px;

	$avatar-size: 40px;

	$arrow-distance: 20px;
	$arrow-size: 7px;

	cursor: default;

	&__loupe {
		color: var(--color--interactive--background);
		cursor: pointer;
		font-size: 16px;

		&:hover {
			color: var(--color--primary--background);
		}
	}

	&__backdrop {
		background-color: var(--color--content--foreground-60);
		z-index: $zindex-modal-backdrop;
	}

	&__cloud {
		background-color: var(--color--content--background);
		color: var(--color--content--foreground-80);
		font-size: 14px;
		transform: translate(-100%, -100%);
		z-index: $zindex-modal;
		margin-top: -($loupe-size-height + $cloud-distance);
		margin-left: ($loupe-size-width + $cloud-offset);
		min-width: $cloud-min-width;
		max-width: $cloud-max-width;

		&::after {
			content: '';
			position: absolute;
			right: $arrow-distance;
			bottom: -$arrow-size;
			border-left: $arrow-size solid transparent;
			border-right: $arrow-size solid transparent;
			border-top: $arrow-size solid var(--color--content--background);
		}

		@include media-breakpoint-down(xs) {
			transform: translate(0, -100%);
			margin-top: 0;
			margin-left: auto;
			min-width: auto;
			max-width: none;
			left: 20px;
			right: 20px;

			&::after {
				right: 10px;
			}
		}
	}

	&__close {
		background-color: var(--color--primary--background);
		color: var(--color--primary--foreground);
		font-size: 12px;
		top: -($close-size / 2);
		right: -($close-size / 2);
		width: $close-size;
		height: $close-size;
		cursor: pointer;
	}

	.entry {
		&__avatar {
			img {
				width: $avatar-size;
				height: $avatar-size;
				background-color: var(--color--interactive--background);
			}
		}
		&__name {
			a {
				color: var(--color--primary--background);
			}
		}
	}
}
