// notificationList

.standardList {
	width: 100%;
	padding: 10px 30px 80px 10px;
	&.isEmpty {
		display: none;
	}
	> li {
		position: relative;
		&:not(:last-child) {
			border-bottom: 1px solid var(--color--content--foreground-10);
		}
		//LIGHTEN: &:not(:last-child) { border-bottom: 1px solid lighten(var(--color--content--foreground-10), 50%); }
		@include M {
			cursor: pointer;
		}
		@include minLaptop {
			&:hover,
			&.active {
				&::after,
				&::before {
					content: '';
					width: 100%;
					height: 1px;
					position: absolute;
					background-color: var(--color--content--background);
					left: 0;
				}
				&::after {
					top: -1px;
				}
				&::before {
					bottom: -1px;
				}
				.standardListRow__mainContent {
					color: var(--color--content--foreground);
					background-color: var(--color--content--foreground-5);
				}
				.row__icon path {
					fill: var(--color--content--foreground);
				}
				.standardListRow__additionalContent {
					background-color: var(--color--content--foreground-10);
				}
			}
		}
		&.active {
			.standardListRow__extendedContent {
				margin-top: 10px;
				opacity: 1;
				height: 100%;
				transition: all 0.2s ease-out;
				@include minDesktop {
					margin-top: 15px;
				}
				.btn__basic {
					padding: 4px 12px;
				}
			}
		}
	}
	.standardListRow {
		border-radius: 15px;
		cursor: pointer;
		&__mainContent {
			color: var(--color--interactive--background);
		}
		&__additionalContent {
			color: var(--color--content--foreground);
		}
		&__mainContent,
		&__additionalContent {
			padding: 20px 19px;
			@include minLaptop {
				padding: 20px 39px;
			}
			@include minDesktop {
				padding: 20px 50px;
			}
		}
		&__extendedContent {
			color: var(--color--content--foreground);
			margin-top: 0;
			opacity: 0;
			height: 0;
			.btn__basic {
				padding: 0;
				float: right;
				font-size: 12px;
				margin-top: 10px;
			}
		}
	}
	.row {
		&__title {
			font-size: 1.4em;
			font-weight: 600;
			margin-right: 5px;
		}
		&__title--leftMargin {
			margin-left: 20px;
		}
		&__text {
			font-size: 1.4em;
			color: var(--color--content--foreground);
			&--light {
				color: var(--color--content--foreground-60);
			}
			&--leftMargin {
				margin-left: 20px;
			}
		}
		&__icon {
			width: 30px;
			height: 30px;
			margin-right: 30px;
			path {
				fill: var(--color--interactive--background);
			}
		}
		&__currencyIcon {
			width: 22px;
			height: 22px;
			margin: 0 5px;
			path {
				fill: var(--color--interactive--background);
			}
		}
		&__currencyContainer {
			margin: 0 5px;
			.row__text:last-child {
				position: relative;
				top: 3px;
			}
		}
	}
	img.row__currencyIcon,
	img.row__icon {
		height: auto;
	}
	@include M {
		padding: 0;
		li {
			padding: 0 30px;
			&.active {
				background-color: var(--color--content--foreground-10);
				.standardListRow__mainContent {
					background-color: transparent;
				}
				.standardListRow__additionalContent {
					background-color: transparent;
				}
			}
			&:not(:last-child) {
				border-bottom: none;
			}
			&:focus {
				outline: none;
			}
		}
		.standardListRow {
			border-radius: 0;
			&__mainContent {
				padding: 20px 5px 20px 0;
			}
			&__additionalContent {
				padding: 20px 0 0 5px;
				background-color: transparent;
			}
		}
		.row {
			&__icon {
				width: 7vw;
				height: 7vw;
				min-width: 35px;
				min-height: 35px;
				margin-right: 5.5vw;
			}
		}
	}
}
