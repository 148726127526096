// fullSizeNotification

@mixin fullView {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.realTimeNotification {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1400;
	canvas {
		left: 0;
		top: 0;
	}
	.background {
		@include fullView;
		position: absolute;
		background-color: rgba(255, 255, 255, 0.8);
	}
	section.content {
		position: relative;
		transform: scale(1);
		background-color: var(--color--primary--background);
		padding: 50px;
		color: var(--color--primary--foreground);
		text-align: center;
		border-radius: 10px;
		font-size: 16px;
		animation: zoomIn 0.8s ease-in-out, show 0.8s ease-in-out;
		font-weight: 400;
		@include minLaptop {
			min-width: 450px;
			max-width: 450px;
		}
		.box {
			border-radius: 50%;
			width: 150px;
			height: 150px;
			margin: 15px auto;
			.image {
				width: 100%;
				height: 100%;
			}
			svg {
				path {
					fill: var(--color--primary--foreground);
				}
			}
		}
		.currencyWrapper {
			justify-content: center;
			margin-bottom: 15px;
			.currencyContainer span {
				color: inherit;
				font-size: 20px;
			}
		}
		h2 {
			font-size: 1.8em;
		}
		h3 {
			font-size: 1.3em;
			margin-bottom: 15px;
		}
		p {
			font-size: 1em;
			margin: 0 0 15px;
		}
		.btn__basic {
			font-size: 1em;
			font-weight: 600;
			padding: 10px 20px;
		}
		@include M {
			& {
				width: 100vw;
				height: 100vh;
				padding: 50px;
				transform: none;
				border-radius: 0;
			}
		}
		@include L {
			& {
				font-size: 16px;
			}
		}
		@include S {
			& {
				font-size: 14px;
			}
		}
	}
}
