// submissionTile

.submissionTile {
	position: relative;
	margin: 0 0 20px 20px;
	@include minLaptop {
		min-width: 157px;
		max-width: 200px;
	}
	a {
		width: inherit;
		overflow: hidden;
	}
	&__front {
		width: 100%;
		position: relative;
		z-index: 100;
		transition: opacity 0.1s;
		opacity: 1;
	}
	&__sidesWrapper {
		width: 100%;
		cursor: pointer;
	}
	&__reverse {
		left: 0;
		width: 100%;
		position: relative;
		z-index: 200;
		border-radius: 10px;
		overflow: hidden;
		opacity: 0;
		transition: opacity 0.1s ease-in;
		> div {
			padding: 20px 14px;
			background-color: var(--color--content--foreground-5);
		}
		.buttonSection {
			background-color: var(--color--content--foreground-10);
		}
		.btn__basic {
			font-size: 14px;
		}
	}
	&__previewWrapper {
		background-color: var(--color--interactive--background);
		background-size: cover;
		height: 132px;
		color: #fff;
		font-size: 14px;
		padding: 20px 14px;
		@include core-border-radius(10px, top-left, top-right);
	}
	&__infoWrapper {
		font-size: 14px;
		border: 1px solid var(--color--content--foreground-10);
		padding: 11px 14px 15px 14px;
		color: var(--color--content--foreground-60);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		.status {
			display: none;
			span {
				display: block;
				margin-top: 4px;
				&:first-child {
					font-weight: 600;
				}
			}
		}
		.error {
			color: $error;
		}
		&.accepted .accepted {
			display: block;
		}
		&.rejected .rejected {
			display: block;
		}
		&.new .new {
			display: block;
		}
		> div:last-child {
			margin-bottom: 0;
		}
	}
	.title {
		color: var(--color--interactive--background);
		font-weight: 400;
		font-size: 13px;
	}
	.author,
	.date,
	.rejectMessage {
		font-size: 13px;
		margin-top: 4px;
		color: var(--color--content--foreground-60);
	}
	.rejectMessage {
		color: $error;
		font-weight: 600;
	}
	.date {
		margin-top: 9px;
	}
	&__visualStatusWrapper {
		min-width: 26px;
		height: 26px;
		position: absolute;
		right: -14px;
		top: -10px;
		z-index: 300;
		> div {
			display: none;
		}
		&:hover {
			z-index: 400;
		}
		&.accepted .accepted {
			display: block;
		}
		&.new .new {
			display: flex;
		}
		&.rejected .rejected {
			display: block;
		}
		&.awarded .awarded {
			display: block;
		}
		.visualStatus {
			width: 26px;
			height: 26px;
			border-radius: 50%;
			font-size: 2em;
			svg {
				width: 17px;
				height: 17px;
				position: relative;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				path {
					fill: #fff;
				}
			}
			&:hover .infoCloud.hide {
				display: block;
			}
		}
		.new {
			background-color: $waitingStatus;
		}
		.rejected {
			background-color: $error;
		}
		.awarded {
			background-color: var(--color--primary--background);
			border-radius: 13px;
			padding: 4px;
			color: #fff;
			font-size: 14px;
			font-weight: 600;
			width: auto;
			min-width: 26px;
			img {
				height: 26px;
				width: auto;
				position: absolute;
				right: calc(100% - 5px);
				top: 0;
			}
			span {
				position: relative;
				padding: 0 4px;
				top: 2px;
				width: 100%;
				text-align: center;
				display: inline-block;
			}
			&--winners {
				text-align: center;
				line-height: 2.1;
				font-size: 12px;
				img {
					position: absolute;
					height: 26px;
					left: -28px;
					top: 0;
				}
			}
		}
	}
	@include M {
		width: 100%;
		flex-direction: row;
		margin-left: 0;
		&__wrapper {
			width: 100%;
		}
		&__previewWrapper {
			height: auto;
			min-height: 132px;
			width: 50%;
			border: {
				bottom-left-radius: 10px;
				top-right-radius: 0;
			}
		}
		&__sidesWrapper {
			flex-direction: row;
		}
		&__infoWrapper {
			border-bottom-left-radius: 0;
			border-top-right-radius: 10px;
		}
		&__submissionInfoWrapper {
			border-bottom-left-radius: 0;
		}
		&__front {
			flex-direction: row;
		}
		&__visualStatusWrapper {
			display: none;
		}
	}
	@include minLaptop {
		width: 157px;
		&:hover {
			&:not(.submissionTile--reverseBlocked) {
				.submissionTile {
					&__reverse {
						opacity: 1;
						left: -100%;
					}
					&__front {
						opacity: 0;
					}
				}
			}
		}
	}
}
