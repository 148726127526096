// missionGraphPreview

.html-map {
	position: absolute;
	.wrapper {
		transform-origin: 0 0;
	}
	.node {
		position: absolute;
		border-radius: 50%;
		cursor: pointer;
	}
	.tooltip {
		transition: display 0s, opacity 0.5s linear;
		h1 {
			font-size: 22px;
			line-height: 1;
		}
		p {
			font-size: 14px;
			line-height: 1;
			padding: 5px 0;
		}
		button {
			margin-top: 5px;
		}
		.tooltip-body {
			width: 280px;
			display: block;
			position: relative;
			padding: 20px 20px;
			border-radius: 15px;
			color: #999;
			background-color: #fff;
			font-size: 14px;
			box-shadow: 0 1px 3px 0 #999;
			h1 {
				color: var(--color--interactive--background);
			}
			.progress-wrapper {
				display: flex;
				align-items: center;
				color: #666;
				.progress-section {
					width: 100%;
					.progress-parent {
						height: 12px;
						background-color: #efefef;
						margin: 0 10px;
						border-radius: 15px;
						overflow: hidden;
						position: relative;
						.progress-line {
							background-color: var(--color--interactive--background);
							position: absolute;
							top: 0;
							bottom: 0;
							border-radius: 15px;
						}
					}
				}
			}
		}
	}
	.__react_component_tooltip {
		background-color: transparent !important; // sass-lint:disable-line no-important
		opacity: 1 !important; // sass-lint:disable-line no-important
		padding: 0 !important; // sass-lint:disable-line no-important
	}
}
