// challengeRanking

.challengeDetails .submissionRanking {
	padding: 30px 40px 0 10px;
	.standardList--ranking {
		padding: 10px 30px 20px 10px;
	}
	.standardListRow {
		.currencyContainer span {
			font-weight: 100;
			margin: 0 0 0 5px;
		}
		& + .row__typeIcon {
			@include XL {
				left: 56px;
			}
			@include M {
				left: 68px;
			}
		}
	}
	@include M {
		overflow: visible;
		&.contentSection__contentWrapper {
			padding: 0;
		}
		.standardList--ranking {
			padding: 10px 0 0 0;
		}
	}
}
