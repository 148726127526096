.customSelect {
	.autocomplete {
		margin-top: 0;
		&.expanded {
			.menu {
				display: block;
			}
			.arrow {
				transform: rotate(0deg);
				background-color: var(--color--content--foreground);
				&::after,
				&::before {
					background-color: var(--color--content--background);
				}
			}
		}
		.menu {
			display: none;
		}
	}
	&__mobile {
		position: relative;
		width: 30px;
		height: 30px;
		text-align: center;
		i {
			line-height: 1.8;
			font-size: 16px;
			color: var(--color--interactive--background);
		}
		select {
			position: absolute;
			width: 30px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
		}
	}
}
