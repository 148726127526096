// _store.scss

.store {
	.store-items {
		.store__item {
			border: 1px solid var(--color--content--foreground-5);
			box-shadow: $tile-box-shadow;

			.tile__image {
				height: auto;
				padding-top: 100%;
			}

			.item__name {
				color: var(--color--interactive--background);
			}

			.item__price {
			}

			.item__key-value {
				& > div:not(:last-of-type) {
					border-bottom: 1px solid var(--color--content--foreground-10);
				}
			}

			.item__description {
			}

			.item__button {
				background-color: var(--color--content--foreground-3);
			}
		}
	}
}
