// ~/assets/scss/views/_auth.scss

.view__auth {
	width: 360px;
	text-align: center;
	font-size: 14px;
	padding: 0 0 50px 0;
	@include M {
		padding: 10px 30px;
		width: 100%;
	}
	input {
		text-align: center;
		margin-bottom: 25px;
		padding: 0 15px;
		line-height: 1;
		// Hide default selects arrow visibility to gain custom look in IE
		&:-webkit-autofill {
			background-color: transparent;
		} // sass-lint:disable-line no-vendor-prefixes
		&.noMargin {
			margin: 0;
		}
	}
	.errorWrapper {
		min-height: 25px;
		text-align: center;
		padding: 2px 0;
		.errorMessage.rulesError,
		.errorMessage--visible {
			display: block;
		}
	}
	textarea {
		height: 105px;
		margin-bottom: 20px;
	}
	.sectionTitle {
		letter-spacing: -1px;
		font-size: 22px;
		text-align: center;
		margin-bottom: 25px;
	}
	.imageChooseContainer {
		position: relative;
		width: auto;
		padding-bottom: 11px;
		margin-bottom: 30px;
		.image {
			width: 121px;
			height: 121px;
			border-radius: 50%;
		}
		&.correct {
			.roundVisualStatus {
				@include roundVisualStatus__correct;
				width: 25px;
				height: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		label {
			position: absolute;
			width: 55px;
			height: 25px;
			bottom: -10px;
			left: 50%;
			transform: translate(-50%, -40%);
			overflow: hidden;
			cursor: pointer;
			input {
				position: relative;
				z-index: 200;
				width: 55px;
				height: 25px;
				opacity: 0;
				margin-bottom: 0;
				cursor: pointer;
				&:hover + .btn__basic {
					background-color: rgb(26, 224, 25);
					&::after {
						top: 3px;
					}
				}
			}
			.btn__basic {
				position: absolute;
				left: 0;
				z-index: 100;
				font-size: 12px;
				padding: 7px 10px 6px 10px;
				min-width: initial;
				span {
					cursor: pointer;
				}
			}
		}
	}
	.progressBar__number {
		top: 23px;
	}
	.separator {
		width: 100%;
		height: 1px;
		margin: 40px 0;
		border-top: 1px solid var(--color--content--foreground-10);
	}
	.welcomePageImage {
		width: 100%;
		height: 30vh;
		margin-bottom: 25px;
	}
	.regionContainer {
		width: 100%;
		padding-left: 20px;
		margin-bottom: 25px;
		border: 1px solid var(--color--content--foreground-10);
		border-radius: 79px;
		height: 79px;
		.sectionTitle {
			margin-bottom: 0;
		}
		.standardText {
			margin-bottom: 0;
			text-align: left;
		}
		.teamIcon {
			width: 79px;
			height: 79px;
			background-color: var(--color--interactive--background);
			border-radius: 50%;
		}
	}
	.textLine {
		margin: 40px 0;
		&--image {
			margin-bottom: 30px;
		}
		&__line {
			border-top: 1px solid var(--color--content--foreground-10);
			height: 1px;
		}
		&__text {
			color: var(--color--content--foreground-60);
			padding: 0 12px;
			height: 0;
			position: relative;
			top: -6px;
		}
		&__image {
			margin: 0 10px;
			img {
				vertical-align: bottom;
				height: 25px;
				width: auto;
				&.grayscale {
					filter: grayscale(1);
					opacity: 0.4;
				}
			}
		}
		&--lower {
			margin: 20px 0;
		}
		&--topMarginReduced {
			margin-top: 15px;
		}
	}
	.standardText {
		margin-bottom: 0;
		font-size: 14px;
		line-height: 1.2;
		&--awarded {
			color: var(--color--interactive--background);
		}
		&--light {
			color: var(--color--content--foreground-30);
			font-size: 12px;
			a {
				color: var(--color--content--foreground);
			}
		}
		&--additional {
			height: 25px;
			position: relative;
			span {
				position: absolute;
				left: 50%;
				bottom: 100%;
				transform: translate(-50%, 50%);
			}
		}
		&--bottomMargin {
			margin-bottom: 25px;
		}
		&--noMargin {
			margin-bottom: 0;
		}
	}
	a {
		color: var(--color--interactive--background);
		padding: 0 3px;
		&:hover {
			text-decoration: underline;
		}
	}
	.btn__basic {
		font-size: 14px;
		margin-bottom: 20px;
		font-weight: 600;
		&--halfWidth {
			width: 50%;
		}
		&--fb,
		&--google,
		&--linkedIn,
		&--oauth {
			margin-bottom: 10px;
			&:focus {
				outline: 0;
			}
			span {
				display: block;
				transform: scale(1);
				transition: 0.15s ease-in;
			}
		}
		&--fb {
			@include buttonEffect(#3b5998);
		}
		&--google {
			@include buttonEffect(rgb(207, 67, 50));
		}
		&--linkedIn {
			@include buttonEffect(rgb(26, 129, 185));
		}
	}
	.buttonsWrapper > * {
		&:first-child {
			margin-right: 20px;
		}
	}
	.noMargin {
		margin-bottom: 0;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.selectWrapper {
		cursor: pointer;
		margin-bottom: 20px;
		width: auto;
		&--topMargin {
			margin-top: 20px;
		}
		&.noMargin {
			margin-bottom: 0;
		}
		.standardText {
			text-align: left;
			font-size: 14px;
			padding-top: 2px;
		}
		.btn__basic {
			width: 50%;
		}
	}

	.authError {
		color: $error;
	}
	.authInProgress {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);
		z-index: 300;
		div.loading {
			transform: translate(-100%, -50%);
			position: absolute;
			left: 50%;
			top: 50%;
			width: 30px;
			height: 30px;
			&::before {
				font-family: FontAwesome;
				position: absolute;
				content: '\f11b';
				animation: burst 1.3s infinite linear;
				z-index: 300;
				font-size: 30px;
				color: var(--color--interactive--background-80);
			}
		}
	}
}

.registerConfirmationPopup {
	background-color: var(--color--content--background);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 900;
	.roundVisualStatus {
		width: 150px;
		height: 150px;
		margin-bottom: 30px;
	}
	.successMessage {
		font-size: 20px;
		margin-bottom: 30px;
		color: var(--color--content--foreground);
	}
}
