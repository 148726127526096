.view__landingPage {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	.circle {
		position: absolute;
		border-radius: 50%;
	}
}

.landingPage__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: relative;
	max-width: 80%;
	margin: 0 auto;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	height: 80px;
	.logo {
		display: flex;
		align-items: center;
		img {
			height: auto;
			max-height: 50px;
			margin-left: 15px;
		}
	}
	button.menu {
		display: none;
		background: none;
		border: 0;
		outline: 0;
		cursor: pointer;
		&::after {
			content: '\f0c9';
			font-family: FontAwesome;
			font-size: 20px;
			color: inherit;
		}
	}
	nav {
		ul {
			display: flex;
			li {
				padding: 0 15px;
				&:not(:last-of-type) {
					border-right: 1px solid rgba(255, 255, 255, 0.2);
				}
				a {
					color: inherit;
					font-size: 14px;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.landingPage__content {
	width: 75%;
	max-width: 80%;
	position: absolute;
	left: 50%;
	top: 47%;
	transform: translate(-50%, -50%);
	text-align: center;
	padding: 30px 15px;
	h1 {
		font-size: 48px;
	}
	h2 {
		font-size: 24px;
		padding: 15px 0 40px 0;
		max-width: 100%;
	}
	a.btn__basic {
		&::after {
			background: none;
		}
		span {
			font-size: 18px;
		}
	}
}

.landingPage__footer {
	color: #a0a0a0;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	margin-top: 40px;
	a {
		text-decoration: underline;
	}
	.skew {
		position: relative;
		&::before {
			content: '';
			transform: skewY(-7deg);
			background-color: #363636;
			position: absolute;
			left: 0;
			right: 0;
			top: -100px;
			bottom: -1000px;
		}
		.content {
			padding-bottom: 80px;
			p {
				padding: 0 20px 0 20px;
				position: relative;
				font-size: 14px;
				line-height: 1.4;
			}
			@include hM {
				padding-bottom: 50px;
				p {
					padding: 0 20px 20px 20px;
				}
			}
		}
		@include hM {
			&::before {
				top: -40px;
			}
			.content {
				padding-bottom: 0;
			}
		}
	}
}

@include M {
	.view__landingPage {
		.landingPage__header {
			border-bottom: 0;
			max-width: none;
			margin: 0 20px;
			nav {
				display: none !important;
			}
			button.menu {
				display: block;
			}
			.logo {
				img {
					max-width: 240px;
					margin-left: 0;
				}
			}
		}

		&.menuOpen {
			.landingPage__header {
				.logo {
					display: none;
				}
				nav {
					display: flex !important;
				}
				button.menu {
					&::after {
						content: '\00d7';
						font-size: 30px;
						color: #aaa;
					}
				}
			}
		}

		.landingPage__content {
			width: auto;
			h1 {
				font-size: 36px;
			}
			h2 {
				font-size: 18px;
				padding: 12px 0 30px 0;
			}
		}
	}
}

@mixin landingPageFooter--simple {
	.view__landingPage {
		position: relative;
		top: auto;
		right: auto;
		bottom: auto;
		left: auto;
	}
	.landingPage__content {
		width: auto;
		min-height: calc(100vh - 178px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		top: auto;
		left: auto;
		transform: none;
		max-width: none;
		padding: 30px;
		margin: 0 auto;
	}
	.landingPage__footer {
		position: static;
		bottom: auto;
		left: auto;
		right: auto;
		.skew::before {
			display: none;
		}
		.content {
			background-color: #323232;
			padding-top: 20px;
		}
	}
}

@include XL {
	@include landingPageFooter--simple;
}

@include hS {
	@include landingPageFooter--simple;
}
