// <Wysiwyg />

.wysiwyg {
	border-radius: 0.5rem;
	background-color: var(--color--content--background);
	color: var(--color--content--foreground);
	border: 1px solid var(--color--content--foreground-5);

	&:focus {
		border-color: var(--color--content--foreground-20);
	}
}

.wysiwyg__toolbar {
}

.wysiwyg__body {
	height: 160px;
	padding: 0.5rem;
	text-align: left;
	overflow-y: scroll;

	font-size: 1rem;

	ol,
	ul {
		margin-left: 1.5rem;
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}
}

.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
	display: flex;
	width: 100%;
}

.DraftEditor-root {
	text-align: left;
}

.public-DraftEditorPlaceholder-root {
}

.DraftEditor-editorContainer {
}
