// buttons

.btn__basic {
	display: inline-block;
	padding: 8px 18px;
	border: 0;
	border-radius: 1.4em;
	text-align: center;
	cursor: pointer;
	color: var(--color--interactive--foreground);
	font-size: 16px;
	font-weight: 400;
	position: relative;
	overflow: hidden;
	min-width: 125px;
	background-color: var(--color--interactive--background);
	z-index: 200;
	@include minLaptop {
		padding: 9px 18px;
	}
	@include minDesktop {
		padding: 10px 18px;
	}
	&::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		border-radius: 1.4em;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: linear-gradient(
			to bottom,
			var(--color--interactive--background) 0%,
			color-mod(var(--color--interactive--background) shade(0.9)) 100%
		);
	}
	&:focus {
		outline: 0;
	}
	span {
		position: relative;
		z-index: 200;
	}
	&:not(.btn__basic--transparent):not(.btn__basic--disabled):not(.btn__basic--red):not(.btn__basic--basicGreen):not(.btn__basic--social):not(.btn__basic--help) {
		&:hover {
			background-color: color-mod(var(--color--interactive--background) brighten(0.5));
			&::after {
				top: 3px;
			}
		}
		&:focus,
		&.loading {
			outline: 0;
			background-color: color-mod(var(--color--interactive--background) shade(0.9));
			&::after {
				top: inherit;
				bottom: 3px;
				background: linear-gradient(
					to bottom,
					color-mod(var(--color--interactive--background) shade(0.9)) 0%,
					var(--color--interactive--background) 100%
				);
			}
		}
	}

	&--red {
		background-color: $error;
		color: #fff;
		&:hover {
			background-color: color-mod($error brighten(0.5));
			&::after {
				top: 3px;
			}
		}
		&::after {
			background: linear-gradient(to bottom, $error 0%, $errorDark 100%);
		}
		&:focus {
			&::after {
				top: inherit;
				bottom: 3px;
			}
		}
	}
	&--close {
		padding: 1px 6.5px;
		&::before {
			content: '\00d7';
			font-size: 22px;
			font-weight: 600;
			font-style: normal;
		}
	}
	&--fullWidth {
		width: 100%;
	}
	&--disabled {
		color: #fff;
		background-color: transparent;
		cursor: default;
		opacity: 0.8;
		&:focus {
			outline: none;
		}
		&::after {
			background: linear-gradient(to bottom, #9a9a9a 0%, #797979 100%);
		}
	}
	&--transparent {
		background-color: transparent;
		&::after {
			box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.5) inset;
			background: none;
		}
		&:hover {
			&::after {
				box-shadow: 0 0 6px 1px rgba(255, 255, 255, 0.5) inset;
			}
		}
		&:focus {
			outline: none;
			&::after {
				box-shadow: 1px 1px 4px 1px rgba(255, 255, 255, 0.5) inset;
			}
		}
	}
	&.loading {
		transition: all 0.2s ease-in;
		&::before {
			font-family: FontAwesome;
			position: absolute;
			content: '\f110';
			top: calc(50% - 0.5em);
			right: 10px;
			animation: rotate 1s infinite ease-in;
			z-index: 200;
			font-size: 1em;
		}
	}
	&--contentFit {
		min-width: 0;
		padding: 10px 10px;
		@include M {
			padding: 8px 10px;
		}
		&.loading {
			padding-right: 15px;
		}
	}
	&--basicGreen {
		background-color: $defaultButton;
		color: #fff;
		&::after {
			background: linear-gradient(to bottom, $defaultButton 0%, color-mod($defaultButton shade(0.9)) 100%);
		}
		&:hover {
			background-color: color-mod($defaultButton brighten(0.5));
			&::after {
				top: 3px;
			}
		}
		&:focus,
		&.loading {
			background-color: color-mod($defaultButton shade(0.9));
			&::after {
				background: linear-gradient(to bottom, color-mod($defaultButton shade(0.9)) 0%, $defaultButton 100%);
			}
		}
	}
	&--help {
		color: var(--color--primary--foreground);
		background-color: var(--color--primary--background);
		&::after {
			background: linear-gradient(
				to bottom,
				var(--color--primary--background) 0%,
				color-mod(var(--color--primary--background) shade(0.9)) 100%
			);
		}
		&:hover {
			background-color: color-mod(var(--color--primary--background) brighten(0.5));
			&::after {
				top: 3px;
			}
		}
		&:focus,
		&.loading {
			background-color: color-mod(var(--color--primary--background) shade(0.9));
			&::after {
				background: linear-gradient(
					to bottom,
					color-mod(var(--color--primary--background) shade(0.9)) 0%,
					var(--color--primary--background) 100%
				);
			}
		}
	}
}
