.task--visitLink {
	a {
		color: var(--color--interactive--background);
		&:hover {
			text-decoration: underline;
		}
	}

	.markdown {
		color: inherit;
	}

	p,
	h3,
	h6 {
		color: var(--color--content--foreground-80);
	}
}
