// welcomePage

.view__welcomePage {
	//position: absolute;
	@include hXS {
		align-items: flex-start;
		&.view {
			padding: 30px;
		}
	}
	.welcomePageWrapper {
		> div:nth-of-type(1) {
			margin-bottom: 80px;
			@include L {
				flex-direction: column;
			}
			@include hM {
				@include L {
					margin-bottom: 30px;
				}
			}
			.image {
				width: 415px;
				height: 140px;
				margin-right: 100px;
				@include XL {
					width: 245px;
					margin-right: 50px;
				}
				@include L {
					margin-right: 0;
					height: 90px;
				}
				@include S {
					height: 70px;
					margin-bottom: 20px;
				}
			}
			.textWrapper {
				width: 720px;
				@include XL {
					width: 100%;
				}
				@include L {
					* {
						text-align: center;
					}
				}
			}
			h1 {
				font-size: 42px;
				text-transform: uppercase;
				span {
					display: block;
				}
				@include XL {
					font-size: 32px;
				}
				@include S {
					font-size: 26px;
				}
			}
			h2 {
				margin-top: 30px;
			}
			h2,
			p {
				font-size: 24px;
				font-family: current-font;
				text-align: justify;
				@include XL {
					font-size: 18px;
				}
				@include S {
					font-size: 15px;
				}
			}
			h1,
			h2,
			p {
				width: inherit;
			}
		}
		&--firstStep p {
			margin-top: 30px;
		}
		&--secondStep > div:nth-of-type(1) {
			@include L {
				flex-direction: column-reverse;
			}
			p {
				text-align: right;
				padding-right: 27px;
				@include S {
					padding-right: 0;
				}
			}
			.image {
				margin: {
					left: 40px;
					right: 0;
				}
				@include L {
					margin-left: 0;
				}
			}
		}
		&--thirdStep > div:nth-of-type(1) {
			.image {
				margin-right: 60px;
				@include L {
					margin-right: 0;
				}
			}
		}
		.btn__basic {
			padding: 11px 32px;
			font-size: 24px;
			text-transform: uppercase;
			@include XL {
				padding: 9px 28px;
			}
			@include hS {
				padding: 9px 28px;
			}
			@include S {
				padding: 8px 24px;
				font-size: 14px;
			}
		}
	}
	.progressContainer {
		padding: 10px;
		border-radius: 5px;
		position: absolute;
		bottom: 15px;
		left: 50%;
		transform: translate(-50%, 0);
		@include M {
			@include hM {
				display: none;
			}
		}
		@include hS {
			display: none;
		}
		span {
			font-size: 12px;
			font-weight: 100;
		}
		.progressBar {
			width: 100px;
			height: 3px;
			margin: 0 10px;
		}
	}
}
