// progressBar

.progressBar {
	position: relative;
	width: 100%;
	height: 12px;
	border-radius: 12px;
	border: 1px solid var(--color--content--foreground-10);
	padding: 1px;
	&__content {
		max-width: 100%;
		height: 8px;
		border-radius: 10px;
		background: linear-gradient(
			to bottom,
			var(--color--interactive--background) 0%,
			color-mod(var(--color--interactive--background) shade(0.9)) 100%
		);
	}
	&__number {
		color: var(--color--content--foreground);
		top: 17px;
		right: 0;
		position: absolute;
		font-size: 1.2em;
		.icon {
			width: 15px;
			height: 15px;
			margin-left: 3px;
		}
	}
	&__status {
		margin-top: 10px;
		font-weight: 100;
		strong {
			color: var(--color--content--foreground);
			padding: 0 5px;
		}
	}
}
