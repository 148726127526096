.user-panel {
	$user-avatar-size: 76px;
	$user-avatar-size-mobile: $user-avatar-size - 18px;
	$team-avatar-size: 48px;
	$team-avatar-size-mobile: 32px;
	$level-size: 48px;
	$level-size-mobile: 32px;
	$user-panel-distance: 32px;
	$user-panel-distance-mobile: 16px;
	$line-distance: 10px;
	$progress-size: 8px;
	$progress-size-mobile: 6px;
	$icon-size: 24px;
	$icon-size-mobile: 16px;
	$segments-distance: 8px;
	$wallet-distance: 8px;

	left: $user-panel-distance;
	bottom: $user-panel-distance;
	z-index: $zindex-fixed;

	&.membership {
		left: $user-panel-distance + $team-avatar-size - $segments-distance;

		@include media-breakpoint-down(xs) {
			left: $user-panel-distance-mobile + $team-avatar-size-mobile - $segments-distance;
		}
	}

	&__avatarContainer {
		z-index: $zindex-sticky;
		bottom: 0;
		width: $user-avatar-size;
		height: $user-avatar-size;
	}

	&__avatarContainer--progress,
	.CircularProgressbar {
		width: $user-avatar-size + $progress-size;
		height: $user-avatar-size + $progress-size;
	}

	&__avatar {
		width: $user-avatar-size;
		height: $user-avatar-size;
		background: {
			color: color-mod(var(--color--interactive--background) brighten(0.4));
			size: cover;
		}
	}

	@include media-breakpoint-down(xs) {
		left: $user-panel-distance-mobile;
		bottom: $user-panel-distance-mobile;

		&__avatarContainer {
			width: $user-avatar-size-mobile;
			height: $user-avatar-size-mobile;
		}

		&__avatarContainer--progress,
		.CircularProgressbar {
			width: $user-avatar-size-mobile + $progress-size-mobile;
			height: $user-avatar-size-mobile + $progress-size-mobile;
		}

		&__avatar {
			width: $user-avatar-size-mobile;
			height: $user-avatar-size-mobile;
		}
	}

	&__teamAvatar {
		width: $team-avatar-size;
		height: $team-avatar-size;
		left: -($team-avatar-size) + $segments-distance;
		background: {
			color: var(--color--interactive--background);
			size: cover;
		}
		z-index: $zindex-sticky - 1;

		@include media-breakpoint-down(xs) {
			width: $team-avatar-size-mobile;
			height: $team-avatar-size-mobile;
			left: -($team-avatar-size-mobile) + $segments-distance;
		}
	}

	&__level {
		width: $level-size;
		height: $level-size;
		right: -($level-size / 2);
		color: var(--color--interactive--foreground);
		background-color: var(--color--interactive--background);
		margin-left: -$segments-distance;
		z-index: $zindex-sticky + 1;

		@include media-breakpoint-down(xs) {
			width: $level-size-mobile;
			height: $level-size-mobile;
		}
	}

	&__avatar,
	&__teamAvatar,
	&__level {
		background: {
			repeat: no-repeat;
			position: center;
		}
	}

	&__wallet {
		margin-left: -$wallet-distance;

		@include media-breakpoint-down(xs) {
			margin-left: -$wallet-distance;
		}

		.currencies {
			height: $level-size;
			background-color: var(--color--primary--background);
			color: var(--color--primary--foreground);
			bottom: $line-distance;
			border-radius: 24px;

			.currency {
				img {
					height: 20px;
				}

				.icon {
					width: $icon-size;
					height: $icon-size;

					@include media-breakpoint-down(xs) {
						width: $icon-size-mobile;
						height: $icon-size-mobile;
					}
				}
			}

			@include media-breakpoint-down(xs) {
				max-width: 220px;
				overflow-x: scroll;
				height: $level-size-mobile;
			}
		}
	}

	.animation {
		$time: 1s;

		position: absolute;
		color: var(--color--content--foreground);

		&.positive {
			@keyframes positiveCurrencyAnimation {
				0% {
					top: -45px;
				}
				30% {
					top: -35px;
				}
				100% {
					top: 0;
				}
			}

			@keyframes positiveIconAnimation {
				0% {
					opacity: 1;
				}
				25% {
					opacity: 1;
				}
				90% {
					opacity: 0.5;
				}
				100% {
					opacity: 0;
				}
			}

			@keyframes positiveValueAnimation {
				0% {
					opacity: 1;
				}
				25% {
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}

			@keyframes positiveIconScale {
				0% {
					transform: scale(0.8);
				}
				40% {
					transform: scale(1.3);
				}
				85% {
					transform: scale(1);
				}
				93% {
					transform: scale(1.8);
				}
				100% {
					transform: scale(1);
				}
			}

			top: 0;
			animation: positiveCurrencyAnimation $time ease-out;

			.icon {
				opacity: 0;
				animation: positiveIconAnimation $time ease-out, positiveIconScale $time ease-out;
			}

			.value {
				opacity: 0;
				animation: positiveValueAnimation $time ease-out;
			}
		}

		&.negative {
			@keyframes negativeCurrencyAnimation {
				0% {
					top: 0;
				}
				70% {
					top: -35px;
				}
				100% {
					top: -45px;
				}
			}

			@keyframes negativeIconAnimation {
				0% {
					opacity: 0;
				}
				50% {
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}

			@keyframes negativeValueAnimation {
				0% {
					opacity: 0;
				}
				10% {
					opacity: 1;
				}
				25% {
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}

			@keyframes negativeIconScale {
				0% {
					transform: scale(0.8);
				}
				100% {
					transform: scale(1);
				}
			}

			top: -45px;
			animation: negativeCurrencyAnimation $time ease-out;

			.icon {
				opacity: 0;
				animation: negativeIconAnimation $time ease-out, negativeIconScale $time ease-out;
			}

			.value {
				opacity: 0;
				animation: negativeValueAnimation $time ease-out;
			}
		}
	}
}
