// effects

.effect__shadow--box {
	transition: opacity 0.15s, box-shadow 0.15s, filter 0.15s, transform 0.15s;
	&:hover {
		box-shadow: rgba(30, 58, 102, 0.2) 0 1px 1px, rgba(30, 58, 102, 0.2) 0 2px 2px, rgba(30, 58, 102, 0.2) 0 4px 4px,
			rgba(30, 58, 102, 0.2) 0 8px 8px, rgba(30, 58, 102, 0.2) 0 16px 16px, rgba(30, 58, 102, 0.2) 0 32px 32px;
	}
}

@keyframes zoomIn {
	0% {
		transform: scale(0);
	}
	80% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes show {
	0% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@keyframes rotateLoader {
	0% {
		transform: rotate(0);
	}
	70% {
		transform: rotate(660deg);
	}
	100% {
		transform: rotate(720deg);
	}
}

/*@keyframes blockIn {
	0% { opacity: 0; transform: scale(0.3); }
	80% { opacity: 0.6; transform: scale(1.1); }
	100% { opacity: 1; transform: scale(1); }
}*/

@keyframes zoomImage {
	0% {
		width: 100%;
	}
	100% {
		width: 103%;
	}
}

.effect__scale {
	transition: transform 0.2s;
	&:hover {
		transform: scale(1.02);
	}
}
@keyframes burst {
	0% {
		opacity: 0.6;
	}
	50% {
		transform: scale(2);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes focusAttentionOnItem {
	from {
		box-shadow: 1px 1px 4px 2px var(--color--primary--foreground-40) inset;
	}
	to {
		box-shadow: 1px 1px 4px 2px transparent inset;
	}
}

@keyframes focusAttentionOnBackgroundItem {
	from {
		box-shadow: 1px 1px 4px 2px var(--color--content--foreground-30) inset;
	}
	to {
		box-shadow: 1px 1px 4px 2px transparent inset;
	}
}

@keyframes focusAttentionOnBackgroundItem--Light {
	from {
		box-shadow: 1px 1px 2px 2px var(--color--content--foreground-10) inset;
	}
	to {
		box-shadow: 1px 1px 4px 2px transparent inset;
	}
}

.fa-gamepad {
	animation: burst 1.3s infinite linear;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

@keyframes realTimeNotificationFadeIn {
	0% {
		background-color: rgba(255, 255, 255, 0);
	}
	100% {
		background-color: rgba(255, 255, 255, 0.8);
	}
}
@keyframes realTimeNotificationScaleIn {
	0% {
		transform: translate(-50%, -50%) scale(0);
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
	}
}

@keyframes hideRotating {
	0% {
		transform: rotateY(0deg);
	}
	50% {
		transform: rotateY(90deg);
	}
	100% {
		transform: rotateY(90deg);
	}
}
@keyframes showRotating {
	0% {
		transform: rotateY(90deg);
	}
	50% {
		transform: rotateY(90deg);
	}
	100% {
		transform: rotateY(0deg);
	}
}

@keyframes slideUp {
	0% {
		padding-top: 45px;
	}
	100% {
		padding-top: 30px;
	}
}

@keyframes slideUpMobile {
	0% {
		padding-top: 15px;
	}
	100% {
		padding-top: 0;
	}
}

@keyframes standardPreviewSlide {
	0% {
		left: -30px;
	}
	100% {
		left: 0;
	}
}
