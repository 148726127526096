// infoCloud

.infoCloud {
	background-color: var(--color--content--background);
	border: 1px solid var(--color--content--foreground-10);
	padding: 20px;
	border-radius: 10px;
	position: fixed;
	top: -300px;
	left: 0;
	font-size: 13px;
	max-width: 240px;
	min-width: 200px;
	z-index: 900;
	&.noMargin {
		align-items: stretch;
		padding: 0;
		.infoCloud__textWrapper {
			text-align: left;
			padding: 20px;
		}
	}
	.rightContent {
		padding: 20px;
		font-size: 30px;
		color: var(--color--interactive--background);
		background-color: var(--color--content--foreground-1);
	}
	&.hide {
		display: none;
	}
	&.error .title {
		color: $error;
	}
	&.right,
	&.left,
	&.bottom {
		&::after,
		&::before {
			content: '';
			position: absolute;
			top: 19px;
			border: {
				top: 8px solid transparent;
				bottom: 8px solid transparent;
			}
		}
	}
	&.right {
		&::after {
			left: -10px;
			border-right: 10px solid var(--color--content--background);
		}
		&::before {
			left: -11px;
			border-right: 10px solid var(--color--content--foreground-10);
		}
	}
	&.left {
		&::after {
			right: -10px;
			border-left: 10px solid var(--color--content--background);
		}
		&::before {
			right: -11px;
			border-left: 10px solid var(--color--content--foreground-10);
		}
	}
	&.bottom {
		&::after {
			top: 100%;
			right: 50%;
			border-left: 10px solid var(--color--content--background);
			transform: rotate(90deg) translate(-50%, -50%);
		}
		&::before {
			top: 100%;
			right: 50%;
			border-left: 10px solid var(--color--content--foreground-10);
			transform: rotate(90deg) translate(-50%, -50%);
		}
	}
	.title,
	.message {
		font-size: 1em;
	}
	.title {
		font-weight: 600;
		margin-bottom: 8px;
		&:only-child {
			margin-bottom: 0;
		}
	}
	.message {
		line-height: 1.2;
		margin-bottom: 0;
	}
}
