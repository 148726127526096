// imageTypeDecider

.matchingImageType {
	overflow: hidden;
	position: relative;
	&.rounded,
	&.rounded img {
		border-radius: 10px;
	}
	img {
		position: fixed;
		opacity: 0;
		top: -100vh;
	}
	&--background {
		width: 100%;
		background-position: top center;
		@include M {
			height: 30vh;
			width: 100%;
		}
		img {
			display: none;
		}
	}
	&--image {
		background-size: 0;
		img {
			position: static;
			opacity: 1;
			width: 100%;
			height: auto;
		}
	}
	&__radius {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		border-radius: 10px;
		box-shadow: 0 0 0 10px var(--color--content--background);
		z-index: 100;
	}
}
