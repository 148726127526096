$fa-font-path: '~font-awesome/fonts' !default;
$fa-version: '4.7.0' !default;

@import '~font-awesome/scss/font-awesome';

@import 'common/fonts';
@import 'widgets/unsupportedBrowser';

@keyframes burst {
	0% {
		opacity: 0.6;
	}
	50% {
		transform: scale(2);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

.app {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	> i {
		font-family: 'FontAwesome';
		position: absolute;
		font-size: 40px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: rgba(0, 0, 0, 0.4);
		animation: burst 1.3s infinite linear;
	}
}

//CHATWEE-INTEGRATION
body div.chch-fixedPaneBottom {
	.chch-fixedChatweeWindowSwitch,
	.chch-mobileChatweeWindowSwitch {
		top: -50px;
		position: relative;
	}
}
