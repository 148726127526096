// scalableIframe

.scalableIframe {
	position: relative;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		transform-origin: top left;
	}
}
