// profileEdit

.profile {
	color: var(--color--content--foreground-60);
	.standardPreview__header .btn__basic {
		@include M {
			min-width: 0;
		}
	}
	.textInput {
		border: 0;
		width: auto;
		background-color: transparent;
		display: inline-block;
		text-align: center;
		border-radius: 10px;
		box-shadow: 1px 1px 4px 2px transparent inset;
		color: var(--color--primary--foreground);
		margin-bottom: 5px;
		&::placeholder {
			color: var(--color--primary--foreground);
		}
		&:focus {
			outline: 0;
		}
	}
	.visibleOnEdit {
		display: none;
	}
	&.editMode {
		.hideOnEdit {
			display: none;
		}
		.technicalHeader {
			.btn__basic {
				margin-left: 30px;
			}
			@include M {
				h1 {
					margin: 0;
				}
				.btn__basic {
					margin-left: 20px;
				}
			}
		}
		.userData .name {
			position: relative;
			top: 2px;
		}
		.teamMemberLink {
			cursor: default;
		}
		.profile {
			&__descriptionSection {
				.editableTextField {
					border: 1px solid var(--color--content--foreground-30);
					padding: 15px;
					border-radius: 10px;
					&--inputLook {
						padding: 5px 10px;
						margin-bottom: 5px;
						text-align: right;
						+ div {
							display: none;
						}
					}
				}
			}
		}
		.nonEditable {
			position: relative;
			opacity: 0.3;
			@include M {
				opacity: 0.3;
			}
		}
		@include minLaptop {
			.visibleOnEdit {
				display: flex;
			}
			.editInProgress {
				@include XL {
					display: none;
				}
			}
		}
		.visibleOnEdit {
			display: flex;
		}
		.editableInput {
			border: 2px solid var(--color--primary--foreground-10);
			padding: 5px 10px;
			animation: focusAttentionOnItem 2s ease-in;
			text-align: center;
			margin-right: 10px;
		}
		.editInProgress {
			@include M {
				display: none;
			}
		}
	}
	&__avatarSection {
		padding-bottom: 30px;
		text-align: right;
		color: var(--color--content--foreground);
		font-weight: 400;
		@include minLaptop {
			.avatar + div {
				@include ms {
					width: 100%;
				}
			}
		}
		.teamName {
			color: var(--color--interactive--background);
			margin-top: 12px;
			width: 100%;
		}
		.image {
			width: 52px;
			height: 52px;
			background-color: var(--color--interactive--background);
			&.teamIcon {
				background-size: 60%;
			}
		}
		p {
			margin-bottom: 5px;
		}
		.editAvatar {
			position: relative;
			margin-right: 10px;
			.buttonWrapper {
				position: relative;
				height: 25px;
				max-width: 103px;
				margin: 5px 0;
				input,
				.btn__basic {
					position: absolute;
					left: 50%;
					transform: translate(-50%, 0);
				}
				input {
					width: 54px;
					height: 25px;
					opacity: 0;
					overflow: hidden;
					cursor: pointer;
					font-size: 0;
					z-index: 900;
					&:hover ~ .btn__basic {
						cursor: pointer;
					}
				}
				.btn__basic {
					font-size: 13px;
					cursor: default;
					padding: 5px 10px;
					transition: all 0.2s ease-in, right 0.1s ease-in;
				}
			}
			.avatarError {
				color: $error;
				text-align: center;
			}
		}
	}
	&__descriptionSection {
		.editableTextField {
			width: 100%;
			border: 0;
			padding: 0;
		}
	}
}
