.background-image {
	background: {
		repeat: no-repeat;
		position: center;
	}

	&.size--cover {
		background-size: cover;
	}

	&.size--contain {
		background-size: contain;
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@each $size, $length in $sizes {
		@include media-breakpoint-up($breakpoint) {
			.w-#{$breakpoint}-#{$size} {
				width: $length !important;
			}
		}
	}
}
