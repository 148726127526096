// missionPreview

.view__missionPreview {
	.viewTitleBox {
		@include M {
			flex-direction: row;
		}
		.viewTitleBox__contentWrapper {
			width: 100%;
			padding-right: 20px;
			@include M {
				padding-right: 0;
			}
			> div:not(.image) {
				width: 100%;
			}
		}
	}
	.currenciesWrapper {
		position: relative;
		top: 5px;
	}
	.statsWrapper {
		margin-left: 30px;
		padding-top: 5px;
		&__number {
			color: var(--color--interactive--foreground);
			padding: 4px 9px;
			border-radius: 50%;
			font-size: 20px;
			font-weight: 600;
			background-color: var(--color--interactive--background);
			min-width: 28px;
		}
		&__text {
			color: var(--color--content--foreground-60);
			font-size: 1.4em;
			padding-right: 10px;
			position: relative;
			top: 7px;
		}
		@include minLaptop {
			@include XXXL {
				flex-direction: column;
				padding-top: 0;
				.statsWrapper__text {
					top: 0;
				}
			}
		}
	}
	.missionInfoWrapper {
		@include minLaptop {
			@include XL {
				flex-direction: column;
				padding-bottom: 20px;
				.statsWrapper {
					margin-left: 0;
				}
				.missionProgress {
					margin-bottom: 25px;
				}
			}
		}
		@include minDesktop {
			min-height: 60px;
		}
	}
	.progressBar {
		margin-top: 5px;
		&__content {
			position: relative;
			&.followProgress {
				.progressBar__number {
					left: initial;
					right: 0;
					text-align: right;
				}
			}
			.progressBar__number {
				position: absolute;
				left: 0;
				top: 16px;
				text-align: left;
			}
		}
	}
	.subtitle {
		padding: 5px 0 12px 15px;
	}
	.navigationBar {
		padding: 0 0 15px 15px;
		font-size: 1.4em;
		a {
			color: var(--color--interactive--background);
			&:hover {
				color: var(--color--content--foreground);
			}
			&:not(:last-child)::after {
				content: '/';
				color: var(--color--content--foreground-60);
				padding: 0 5px;
			}
			&.active {
				cursor: default;
				pointer-events: none;
				color: var(--color--content--foreground);
			}
		}
	}
	@include minDesktop {
		.navigationBar,
		.subtitle {
			padding-left: 35px;
		}
	}
	@include M {
		.subtitle {
			padding: 12px 0 12px 30px;
		}
		.navigationBar {
			padding: 0 0 15px 30px;
		}
	}
}
