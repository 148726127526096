// markedAvatar

/*
* Widget for place mark (i.e. roundNumber) in certain place via avatar.
* For this purpose use markedAvatar class with modificators showing desired mark place: --top, --middle, --bottom, --left, --right.
*/

*[class*='markedAvatar'] {
	position: relative;
	&[class*='--left'] {
		.marker {
			right: 100%;
			transform: translate(60%, 0);
		}
		&[class*='--middle'] .marker {
			transform: translate(50%, -50%);
		}
	}
	&[class*='--right'] {
		.marker {
			left: 100%;
			transform: translate(-60%, 0);
		}
		&[class*='--middle'] .marker {
			transform: translate(-50%, -50%);
		}
	}
	&[class*='--middle'] .marker {
		top: 50%;
		transform: translateY(-50%);
	}
	&[class*='--top'] .marker {
		top: 0;
	}
	&[class*='--bottom'] .marker {
		bottom: 0;
	}
	> * {
		position: absolute;
		border-radius: 50%;
	}
}
