.block__teamAssignment {
	label.autocomplete {
		width: 100%;
		position: relative;
		margin-left: 0;
		margin-bottom: 10px;
		&:nth-of-type(1) {
			z-index: 1000;
		}
		&:nth-of-type(2) {
			z-index: 800;
		}
	}
}
