// markdown

.markdown {
	h1 {
		font-size: 24px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 16px;
	}

	strong {
		font-weight: 600;
	}

	em {
		font-style: italic;
	}

	a {
		color: var(--color--primary--background);
		word-break: break-word;
		&:hover {
			text-decoration: underline;
		}
	}

	pre {
		padding-bottom: 0.5rem;
	}

	hr {
		border: 0;
		border-top: 1px solid var(--color--content--foreground-10);
		padding-bottom: 0.5rem;
	}

	ul,
	ol {
		list-style-position: inside;
		padding: 0.5rem 0;
	}

	ul {
		list-style: none;
		li {
			padding-left: 1rem;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				transform: translate(50%, -40%);
				top: 0.5em;
				width: 0.3em;
				height: 0.3em;
				background-color: var(--color--interactive--background);
				border-radius: 50%;
			}
		}
	}

	ol {
		counter-reset: item;
		li {
			&::before {
				content: counter(item) '. ';
				counter-increment: item;
				color: var(--color--interactive--background);
			}
		}
	}

	p {
		padding-bottom: 0.5rem;

		&:last-of-type {
			padding-bottom: 0;
		}
	}

	img {
		max-width: 100%;
		margin: 0.5rem 0;
	}

	&.inline {
		& > div {
			display: inline;
		}
		p {
			display: inline;
			padding: 0;
		}
	}
}
