// autocomplete

label.autocomplete {
	width: 100%;
	height: 38px;
	position: relative;
	font-size: 16px;
	color: var(--color--content--foreground);
	&:nth-child(1) {
		z-index: 5;
	}
	&:nth-child(2) {
		z-index: 4;
	}
	&:nth-child(3) {
		z-index: 3;
	}
	input,
	> p {
		position: relative;
		width: 100%;
		height: 38px;
		padding: 0 30px 0 16px;
		border-radius: 12px;
		border: 1px solid var(--color--content--foreground-35);
		z-index: 3;
		color: inherit;
		font-weight: 600;
		margin-bottom: 0;
		background-color: var(--color--content--background);
		text-overflow: ellipsis;
		&:hover,
		&:focus {
			outline: none;
		}
		&:disabled {
			background-color: transparent;
			opacity: 0.5;
			& + .arrow {
				opacity: 0.5;
			}
		}

		&:placeholder-shown {
			text-overflow: ellipsis;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 26px 0 8px;
		}
	}
	input::placeholder {
		color: var(--color--content--foreground);
		font-weight: 600;
	}
	.arrow {
		position: absolute;
		top: 11px;
		right: 16px;
		width: 16px;
		height: 16px;
		background-color: transparent;
		border-radius: 50%;
		transition: transform 0.2s ease-in;
		z-index: 6;
		&::after,
		&::before {
			content: '';
			width: 2px;
			height: 8px;
			background-color: var(--color--content--foreground);
			position: absolute;
			top: 50%;
			left: 50%;
			opacity: 0.35;
		}
		&::after {
			transform: translate(-4px, -4px) rotate(-45deg);
		}
		&::before {
			transform: translate(1px, -4px) rotate(45deg);
		}
		@include media-breakpoint-down(sm) {
			right: 8px;
		}

		&--expanded {
			transform: rotate(180deg);
			&::after,
			&::before {
				opacity: 1;
			}
		}
	}
	.menu {
		position: absolute;
		top: 15px;
		left: 0;
		padding-top: 22px;
		width: 100%;
		overflow: hidden;
		transition: all 0.2s ease-in;
		min-height: 15px;
		color: inherit;
		z-index: 2;
		background-color: var(--color--content--background);
		@include core-border-radius(15px, bottom-left, bottom-right);
		border: {
			left: 1px solid var(--color--content--foreground-30);
			right: 1px solid var(--color--content--foreground-30);
			bottom: 1px solid var(--color--content--foreground-30);
		}
		&__scrollContainer {
			position: relative;
			max-height: 200px;
			color: inherit;
			overflow-y: auto;
			scrollbar-width: auto; /* Firefox */
			z-index: 2;
		}
		&__scrollContainer::-webkit-scrollbar {
			width: 10px;
		}
		&__scrollContainer::-webkit-scrollbar-track {
			background: transparent;
			border-radius: 5px;
		}
		&__scrollContainer::-webkit-scrollbar-thumb {
			background: var(--color--interactive--background);
			border-radius: 5px;
			border: 2px solid var(--color--content--background);
		}

		li {
			min-height: 24px;
			line-height: 24px;
			color: var(--color--content--foreground-80);
			&:nth-child(even) {
				background-color: var(--color--content--foreground-5);
			}
			&:hover {
				cursor: pointer;
			}
			span {
				margin-left: 15px;
				opacity: 1;
			}
		}

		.item {
			&--highlighted {
				color: var(--color--content--foreground);
				font-weight: bolder;
			}
		}
	}
}

.filters {
	@include M {
		flex-direction: row;
		flex-wrap: nowrap;
		.autocomplete {
			width: 30px;
			margin: 0 0 0 10px;
			transition: all 0.2s ease-in;
			input {
				padding: 0;
				opacity: 0;
				border: 1px solid transparent;
				background-color: transparent;
				transition: all 0.2s ease-in;
				&:disabled {
					opacity: 0;
				}
			}
			&:hover {
				width: 100%;
				flex-grow: 1;
				input {
					padding: 0 30px 0 15px;
					opacity: 1;
					border: 1px solid var(--color--content--foreground-30);
					background-color: var(--color--content--background);
					z-index: 5;
					&:disabled {
						opacity: 0.5;
					}
				}
			}
		}
	}
}
