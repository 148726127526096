hr {
	width: auto;
	height: 0;
	border: 0;
	border-top: 1px solid var(--color--content--foreground-10);
	background-color: transparent;
	margin: 0;
}

// forms

fieldset {
	border: none;
	margin: 0;
	padding: 0;
}

// heading sizes

h1,
.h1 {
	font-size: 2.3rem;
}
