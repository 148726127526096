// contentBlocks

.contentBlocks {
	padding: 13px 90px 0 90px;
	@include M {
		padding: 50px 30px 0 30px;
	}
	.markdown {
		color: var(--color--content--foreground);
	}
	.block--text {
		font-size: 18px;
	}
	&__block {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.imageWrapper {
		text-align: center;
		width: 100%;
	}
	img {
		max-width: 100%;
		height: auto;
		border-radius: 10px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.btn__basic {
		padding: 13px 18px;
		font-size: 20px;
		margin-bottom: 20px;
	}
	iframe {
		@include M {
			width: 100%;
			height: auto;
		}
	}
	h1 {
		font-size: 24px;
	}
	h2 {
		font-size: 22px;
	}
	h3 {
		font-size: 20px;
	}
	@include minDesktop {
		h1 {
			font-size: 28px;
		}
		h2 {
			font-size: 26px;
		}
		h3 {
			font-size: 24px;
		}
	}
}
