// viewTitleBox

.viewTitleBox {
	width: 100%;
	flex-shrink: 0;
	padding: 0 0 0 15px;
	color: var(--color--content--foreground);
	transition: all 0.2s ease-in;
	@include ms--widthAuto;
	&__descriptionSection {
		margin-top: -15px;
	}
	h1 {
		font-size: 38px;
		padding-left: 15px;
	}
	.subtitle {
		color: var(--color--content--foreground-60);
		font-size: 1.4em;
		display: block;
		padding: 15px;
	}
	.btn__basic {
		margin: 0 15px;
		@include M {
			&:first-of-type {
				margin-left: 0;
			}
		}
	}
	@include minDesktop {
		h1,
		.subtitle {
			padding-left: 35px;
		}
	}
	@include L {
		padding-left: 0;
	}
	@include M {
		padding: 0;
		border-bottom: 0;
		&__titleSection {
			background-color: var(--color--content--foreground-5);
			padding: 11px 30px 9px 30px;
		}
		&__descriptionSection {
			background-color: var(--color--content--background);
			overflow: hidden;
			min-height: 40px;
			height: auto;
			border-bottom: 1px solid var(--color--content--foreground-10);
		}
		h1 {
			font-size: 24px;
			padding-left: 0;
		}
		.subtitle {
			font-size: 1.4em;
			display: block;
			padding: 13px 30px;
			color: var(--color--content--foreground-60);
		}
		.btn__basic {
			margin: 0 15px;
			@include M {
				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	}
}
