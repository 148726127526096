.team-profile {
	font-size: 1rem;

	.summary {
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 200px;
		}

		p {
			font-size: 0.9em;
			white-space: pre-wrap;
		}
	}

	.info {
		font-size: 0.8rem;
		background-color: color-mod(var(--color--content--background) shade(0.1));

		.tabs {
			background-color: var(--color--content--background);
		}

		@include media-breakpoint-up(md) {
			background-color: var(--color--content--background);
		}
	}

	h5 {
		font-size: 0.95rem;
		color: var(--color--content--foreground-50);
	}

	.members {
		.member {
			.name {
				color: var(--color--primary--background);
			}
			.nickname {
				font-size: 0.95em;
				word-break: break-all;
			}
			.role {
				color: var(--color--content--foreground-30);
				font-size: 1em;
			}
			.wallet {
				font-size: 1.2em;
			}
		}
	}

	.tasks {
		.task {
			position: relative;

			&:not(:last-child) {
				border-bottom: 1px solid var(--color--content--foreground-10);
			}

			a {
				color: var(--color--interactive--background);

				&:hover {
					text-decoration: underline;
				}
			}

			.task__loading {
				position: absolute;
				z-index: 500;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-color: rgba(255, 255, 255, 0.8);
			}

			.task__modal {
				text-align: center;

				h1 {
					font-size: 1.6rem;
				}
			}

			.task__userName {
				font-size: 1rem;
				font-weight: 600;
				color: var(--color--interactive--background);
			}
			.task__description {
				color: var(--color--content--foreground-80);
				line-height: 1.2;
			}

			.task__currencies {
				color: var(--color--content--foreground-80);
				font-size: 1rem;
			}
		}
	}
}
