// news

.view__news {
	.standardListRow {
		&__additionalContent {
			> div:first-child {
				color: var(--color--content--foreground-60);
				margin-right: 10px;
			}
		}
		&__mainContent,
		&__additionalContent {
			@include XXXL {
				padding: 15px 30px;
			}
		}
	}
	@include M {
		.standardListRow__mainContent {
			padding: 20px 0;
		}
		.row__title {
			margin-bottom: 2px;
		}
		.row__text {
			margin-right: 5px;
		}
	}
}
