// KPI

.view__challengeDetails--kpi {
	.standardPreview.challengeDetails {
		.challengeDetails {
			&__descriptionSection {
				@include minLaptop {
					width: 30vw;
					max-width: 530px;
				}
			}
			&__mainImage {
				width: 100%;
				height: 195px;
				position: relative;
				img {
					width: 103%;
					height: auto;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					animation: zoomImage 0.5s ease-out;
				}
			}

			&__footer {
				.progressBarWrapper {
					width: 50%;
				}
			}
		}
	}
	.progressBarWrapper {
		position: relative;
		top: 5px;
	}
	.progressBar {
		padding: 0;
		height: 10px;
		min-width: 115px;
		border: 1px solid var(--color--primary--foreground-10);
		&__content {
			height: 8px;
		}
		&__status {
			margin-top: 4px;
			.standardText {
				color: var(--color--primary--foreground-70);
				font: {
					size: 12px;
					weight: 100;
				}
				strong {
					padding-left: 15px;
					color: var(--color--primary--foreground);
				}
			}
		}
		@include M {
			border: 1px solid var(--color--content--foreground-10);
			&__status {
				.standardText {
					color: var(--color--content--foreground-70);
					strong {
						color: var(--color--content--foreground);
					}
				}
			}
		}
	}
	.standardPreview__header {
		h1 {
			border-left: none;
		}
		.status {
			.standardText {
				color: var(--color--primary--foreground-70);
				strong {
					color: var(--color--primary--foreground);
				}
			}
		}
	}
	@include M {
		.standardPreview.challengeDetails {
			.challengeDetails__footer {
				.progressBarWrapper {
					width: 100%;
				}
				.progressBar {
					margin: 5px 0;
					&__content {
						width: 100%;
					}
				}
			}
		}
		.challengeDetails {
			&__mainImage {
				img {
					width: 103%;
					height: auto;
					position: static;
					transform: none;
				}
			}
		}
		.standardPreview__mainWrapper {
			overflow: visible;
		}
	}
}
