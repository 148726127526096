// DoubleSideTile

.doubleSideTile {
	width: 11vw;
	margin-right: 20px;
	* {
		transition: all 0s ease-in;
	}
	a {
		overflow: hidden;
		width: inherit;
		max-width: inherit;
		> * {
			width: inherit;
			max-width: inherit;
		}
	}
	.btn__basic {
		min-width: 0;
	}
	&.reverseAllowed a {
		@include minLaptop {
			&:hover {
				.doubleSideTile {
					&__front {
						left: -100%;
					}
					&__reverse {
						opacity: 1;
						left: -100%;
					}
				}
			}
		}
	}
	&:not(.reverseAllowed) a {
		overflow: visible;
		@include minLaptop {
			&:hover {
				.doubleSideTile {
					&__title {
						text-shadow: 0 0 0 var(--color--interactive--background);
						cursor: default;
					}
				}
			}
		}
	}
	&__title {
		font-size: 1.4em;
		color: var(--color--interactive--background);
		font-weight: 600;
		text-align: center;
		width: 100%;
		margin-bottom: 15px;
	}
	&__front {
		width: inherit;
		padding-top: 20px;
		position: relative;
		.tileImage {
			width: 100%;
			height: auto;
			margin: 0 5px 10px 5px;
			// For 100% width IE demands height 100% to keep proper scale
			@include ms {
				height: 100%;
			}
		}
	}
	&__reverse {
		opacity: 0;
		position: relative;
		border-radius: 10px;
		background-color: var(--color--content--foreground-5);
		left: 0;
		transition: opacity 0.05s ease-in;
		.markdown {
			padding-bottom: 20px;
		}
		.doubleSideTile__title {
			color: var(--color--content--foreground);
			text-align: left;
			font-weight: 100;
		}
	}
	.tileStandardText {
		color: var(--color--content--foreground);
		font-size: 1.2em;
		margin-top: 15px;
	}
	.reverse {
		&__contentWrapper {
			padding: 20px;
			width: inherit;
		}
		&__bottomContent {
			background-color: var(--color--content--foreground-10);
			color: var(--color--content--foreground);
			padding: 20px;
			width: inherit;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
	@include minLaptop {
		&__front {
			max-width: 140px;
		}
		.doubleSideTile__reverse > * {
			max-width: 140px;
		}
		@include XXXL {
			width: 16vw;
			max-width: 140px;
			margin-right: 15px;
			// IE fix
			@include ms {
				width: 140px;
			}
		}
	}
	@include minDesktop {
		&__front {
			max-width: 250px;
		}
	}
}
