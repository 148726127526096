// footer

section.footer {
	position: absolute;
	bottom: 20px;
	left: 30px;
	z-index: 500;
	@include touchDevices {
		position: absolute;
		height: 46px;
		width: 100%;
		left: 0;
		bottom: 0;
		align-self: flex-end;
		> div {
			width: 100%;
			height: inherit;
		}
		.footer__technical {
			flex-grow: 1;
			overflow-x: scroll;
			overflow-y: hidden;
			position: relative;
			top: 0;
			left: 0;
			margin-left: 0;
			&.scoreAnimation {
				overflow-x: visible;
				overflow-y: visible;
				overflow: visible;
			}
			&::after,
			&::before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				height: 100%;
				width: 20px;
				z-index: 200;
			}
			&::after {
				left: 0;
				background: linear-gradient(
					to right,
					var(--color--primary--background) 0%,
					var(--color--primary--background-0) 100%
				);
			}
			&::before {
				right: 0;
				background: linear-gradient(
					to left,
					var(--color--primary--background) 0%,
					var(--color--primary--background-0) 100%
				);
			}
		}
		.footer__playerInfoContainer {
			overflow-x: scroll;
			height: 70px;
			align-items: flex-start;
			padding: 15px 0 0 14px;
			&.scoreAnimation {
				overflow-x: visible;
			}
		}
	}
}

.footer__workingWrapper {
	position: relative;
	height: inherit;
	.footer__avatarContainer {
		.avatar-container {
			border-radius: 50%;
			height: 84px;
			width: 84px;
			z-index: 900;
		}
		.avatar {
			width: 76px;
			height: 76px;
			background-color: var(--color--interactive--background);
			display: block;
			margin: 4px;
			border-radius: 50%;
			overflow: hidden;
			z-index: 900;
			position: relative;
			&__iconWrapper {
				position: absolute;
				z-index: 950;
				height: 36px;
				width: 36px;
				border-radius: 50%;
				background-position: center;
				background-color: color-mod(var(--color--primary--background) brighten(0.4));
				background-repeat: no-repeat;
				top: 43%;
				right: 94%;
			}
		}
		.levelNumber {
			position: absolute;
			border-radius: 50%;
			width: 36px;
			height: 36px;
			text-align: center;
			line-height: 2;
			font-size: 18px;
			color: var(--color--primary--foreground);
			background-color: color-mod(var(--color--primary--background) brighten(0.4));
			top: 43%;
			right: 65%;
		}
	}
	.footer__technical {
		position: relative;
		left: -20px;
		top: 12px;
		height: 36px;
		border-top-right-radius: 36px;
		border-bottom-right-radius: 36px;
		background-color: var(--color--primary--background);
		transition: width 0.5s ease-in;
		&.hasLevel {
			left: -4px;
		}
	}
	.footer__playerInfoContainer {
		height: 36px;
		padding: 0 11px 0 28px;
		transition: all 2s ease-in, width 0.1s ease-out;
		position: relative;
		.currencyContainer {
			transition: margin-right 1s ease-out;
			margin-right: 14px;
			.currencyContainer__icon {
				height: 19px;
				width: 19px;
			}
			span {
				font-family: current-font;
				font-size: 18px;
				font-weight: 600;
				padding-left: 5px;
				margin: 0;
				color: var(--color--primary--foreground);
			}
		}
		&.countScoreAnimation {
			position: absolute;
			width: auto;
		}
		&.scoreAnimation {
			.currencyContainer {
				margin-right: 25px;
			}
		}
	}
	@include touchDevices {
		background-color: var(--color--primary--background);
		padding-left: 30px;
		.footer__avatarContainer {
			flex-direction: row-reverse;
			.avatar {
				width: 36px;
				height: 36px;
			}
			.avatar__iconWrapper {
				position: static;
				width: 36px;
				height: 36px;
				margin-right: 10px;
			}
		}
		.footer {
			&__technical {
				position: static;
				margin-left: 14px;
				height: 47px;
			}
			&__playerInfoContainer {
				justify-content: flex-start;
				.currencyContainer {
					.currencyContainer__icon {
						height: 17px;
						width: 20px;
					}
					img.currencyContainer__icon {
						height: 17px;
						width: auto;
					}
					span {
						font-size: 16px;
					}
				}
			}
		}
	}
}
