// .returnArrow

.returnArrow {
	position: relative;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	transition: background-color 0.05s ease-in;
	z-index: 200;
	cursor: pointer;
	&::after,
	&::before {
		position: absolute;
		content: '';
		width: 11px;
		height: 3px;
		border-radius: 3px;
		background-color: var(--color--primary--foreground);
		transition: all 0.15s ease-in;
		left: 50%;
		top: 50%;
	}
	&::after {
		transform: rotate(50deg) translate(-2px, 7px);
	}
	&::before {
		transform: rotate(-50deg) translate(-1px, -8px);
	}
	&--right {
		transform: rotate(180deg);
	}
	&__wrapper {
		width: 75px;
		cursor: pointer;
		position: relative;
		&::after {
			content: attr(data-text);
			z-index: 100;
			visibility: hidden;
			position: absolute;
			white-space: nowrap;
			padding: 7px 9px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			background-color: var(--color--primary--background);
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
			font-size: 12px;
			transition: color 0.1s ease-in, bottom 0.1s ease-in;
			text-transform: none;
		}
		@include minLaptop {
			width: 80px;
			&:hover {
				&::after {
					bottom: -15px;
					visibility: visible;
					color: var(--color--primary--foreground);
				}
				.returnArrow {
					&::after,
					&::before {
						background-color: var(--color--interactive--foreground);
					}
				}
				.returnArrow {
					background-color: var(--color--interactive--background);
				}
			}
		}
	}
}
