// bootstrap overrides:
$black: #000;

$box-shadow: 0 0 0.8rem rgba($black, 0.15) !default;
$tile-box-shadow: 0 0 0.625rem rgba($black, 0.05) !default;
$row-box-shadow: 0 0 0.5rem rgba($black, 0.1) !default;

$border-radius: 0.5rem !default;
$border-radius-lg: 1rem !default;

$font-weight-bold: 600 !default;

$headings-font-weight: 400 !default;

$spacer: 1rem !default;
$spacers: (
	0: 0,
	1: (
		$spacer * 0.25,
	),
	2: (
		$spacer * 0.5,
	),
	3: $spacer,
	4: (
		$spacer * 1.5,
	),
	5: (
		$spacer * 2,
	),
	6: (
		$spacer * 3,
	),
) !default;

$grid-columns: 24 !default;
$grid-gutter-width: 1rem !default;

$grid-breakpoints: (
	xxxs: 0,
	xxs: 380px,
	xs: 500px,
	sm: 768px,
	md: 992px,
	lg: 1280px,
	xl: 1600px,
	xxl: 1920px,
) !default;
