.contactList {
	&__list {
	}

	&__tile {
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $tile-box-shadow;

		.tile__avatar {
		}

		.user-avatar {
			height: auto;
			padding-top: 100%;
			background-color: var(--color--primary--background);
		}

		.team-avatar {
			left: 0;
			bottom: 0;
			width: 35%;
			height: 35%;
			background-color: var(--color--interactive--background);
		}

		.name {
			color: var(--color--interactive--background);
		}

		.field__name,
		.field__value {
			display: block;
		}

		.field__name {
			color: var(--color--content--foreground-50);
			white-space: nowrap;
		}

		.field__value {
			&.link {
				word-break: break-all;
			}

			a {
				color: var(--color--interactive--background);

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.contact-list-entry {
	border: 1px solid var(--color--content--foreground-10);

	font-size: 0.95rem;

	&__avatar {
		@include media-breakpoint-down(xs) {
			width: 100%;
			min-width: 80px;
			max-width: 80px;
		}
	}

	p {
		color: var(--color--interactive--background);
	}

	&__status {
		top: 0;
		right: 0;
		width: 12%;
		height: 12%;
		background-color: #8af703;
	}

	&__fields {
		background-color: var(--color--content--foreground-10);

		border-bottom-right-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;

		@include media-breakpoint-down(xs) {
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
			border-bottom-left-radius: 0;
		}
	}
}
