// pointsAnimation

$pointsAnimation-initialDrop: 1.5s;
$pointsAnimation-addScoreDrop: 1.3s;
$pointsAnimation-finalAnimation: 1.3s;

@keyframes addPointsAnimation--fullSlide {
	0% {
		top: -60vh;
	}
	20% {
		transform: scale(4);
	}
	40% {
		transform: scale(3);
	}
	70% {
		transform: scale(1);
	}
	90% {
		transform: scale(1.3);
	}
	100% {
		transform: scale(1);
		top: 0;
	}
}

@keyframes subtractPointsAnimation {
	0% {
		top: 35px;
	}
	0% {
		transform: scale(2);
	}
	100% {
		top: 0;
	}
}

@keyframes addPointsAnimation--iconFinishScale {
	0% {
		transform: scale(1);
	}
	85% {
		transform: scale(1);
	}
	93% {
		transform: scale(1.8);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes negativeFinalAnimation--wrapper {
	0% {
		top: -35px;
	}
	100% {
		top: -100px;
	}
}

@keyframes finalAnimation--wrapper {
	0% {
		top: -35px;
	}
	100% {
		top: 0;
		opacity: 0;
	}
}

@keyframes finalAnimation--icon {
	0% {
		opacity: 1;
	}
	90% {
		opacity: 0.5;
	}
	100% {
		opacity: 0;
	}
}

@keyframes finalAnimation--points {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes addPointsAnimation--iconOpacity {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes initialAnimation--pointsOpacity {
	0% {
		opacity: 0;
	}
	70% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes initialAnimation--negativePointsOpacity {
	0% {
		opacity: 0;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes subtractPointsAnimation--iconOpacity {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

.footer__animationTechnicalWrapper {
	position: relative;
	.addedPoints {
		&__icon,
		&__icon2 {
			display: none;
			position: relative;
			z-index: 100;
			height: 19px;
			width: 19px;
		}
		&__points,
		&__points2 {
			opacity: 0;
			position: relative;
			font-weight: 600;
			font-size: 16px;
			margin-left: 5px;
			color: var(--color--content--foreground);
			z-index: 200;
			text-shadow: 0 0 5px var(--color--content--background-60);
		}
		&__iconWrapper {
			position: relative;
			.additionalIcon,
			.additionalIcon2 {
				height: 19px;
				width: 19px;
				position: absolute;
				opacity: 0;
				top: 0;
			}
		}
		&__wrapper {
			position: absolute;
			top: -35px;
			left: 0;
			&.initial {
				.addedPoints__points {
					opacity: 1;
					animation: initialAnimation--pointsOpacity $pointsAnimation-initialDrop ease-out,
						addPointsAnimation--fullSlide $pointsAnimation-initialDrop ease-out;
				}
				.addedPoints__icon {
					display: block;
					animation: addPointsAnimation--iconOpacity $pointsAnimation-initialDrop ease-in,
						addPointsAnimation--fullSlide $pointsAnimation-initialDrop ease-out;
				}
			}
			&.initialNegative {
				.addedPoints__points {
					opacity: 1;
					animation: initialAnimation--negativePointsOpacity $pointsAnimation-initialDrop ease-out,
						subtractPointsAnimation $pointsAnimation-initialDrop ease-out;
				}
				.addedPoints__icon {
					animation: subtractPointsAnimation--iconOpacity $pointsAnimation-initialDrop ease-in,
						subtractPointsAnimation $pointsAnimation-initialDrop ease-out;
					display: block;
				}
			}
			&.visible {
				.addedPoints__icon {
					display: block;
				}
				.addedPoints__points {
					opacity: 1;
				}
			}
			&.visible2 {
				.addedPoints__icon2 {
					display: block;
				}
				.addedPoints__points {
					opacity: 1;
				}
			}
			&.scoreAnimation {
				.additionalIcon {
					animation: addPointsAnimation--fullSlide $pointsAnimation-addScoreDrop ease-in,
						addPointsAnimation--iconOpacity $pointsAnimation-addScoreDrop ease-in;
				}
				.addedPoints__icon {
					animation: addPointsAnimation--iconFinishScale $pointsAnimation-initialDrop ease-in;
				}
			}
			&.scoreAnimation2 {
				.additionalIcon2 {
					animation: addPointsAnimation--fullSlide $pointsAnimation-addScoreDrop ease-in,
						addPointsAnimation--iconOpacity $pointsAnimation-addScoreDrop ease-in;
				}
				.addedPoints__icon2 {
					animation: addPointsAnimation--iconFinishScale $pointsAnimation-initialDrop ease-in;
				}
			}
			&.negativeScoreAnimation {
				.additionalIcon {
					animation: subtractPointsAnimation $pointsAnimation-addScoreDrop ease-in,
						subtractPointsAnimation--iconOpacity $pointsAnimation-addScoreDrop ease-in;
				}
				.addedPoints__icon {
					animation: addPointsAnimation--iconFinishScale $pointsAnimation-initialDrop ease-in;
				}
			}
			&.negativeScoreAnimation2 {
				.additionalIcon2 {
					animation: subtractPointsAnimation $pointsAnimation-addScoreDrop ease-in,
						subtractPointsAnimation--iconOpacity $pointsAnimation-addScoreDrop ease-in;
				}
				.addedPoints__icon2 {
					animation: addPointsAnimation--iconFinishScale $pointsAnimation-initialDrop ease-in;
				}
			}
			&.finalAnimation {
				animation: finalAnimation--wrapper $pointsAnimation-finalAnimation ease-out;
				.addedPoints__iconWrapper img {
					opacity: 0;
				}
				.addedPoints__icon {
					animation: finalAnimation--icon $pointsAnimation-finalAnimation ease-out;
				}
				.addedPoints__icon2 {
					animation: finalAnimation--icon $pointsAnimation-finalAnimation ease-out;
				}
				.addedPoints__points {
					opacity: 0;
					animation: finalAnimation--points 0.2s ease-out;
				}
			}
			&.finalAnimationNegative {
				animation: negativeFinalAnimation--wrapper $pointsAnimation-finalAnimation ease-out;
				.addedPoints__iconWrapper img {
					opacity: 0;
				}
				.addedPoints__icon {
					animation: finalAnimation--icon $pointsAnimation-finalAnimation ease-out;
				}
				.addedPoints__icon2 {
					animation: finalAnimation--icon $pointsAnimation-finalAnimation ease-out;
				}
				.addedPoints__points {
					opacity: 0;
					animation: finalAnimation--points 0.05s ease-out;
				}
			}
		}
	}
}
