// teamProfile

.view__teamProfile {
	height: inherit;
	.standardPreview {
		&__header h1 {
			padding: 0 30px;
			&.teamName {
				opacity: 0.5;
				font-weight: 100;
				display: none;
				@include minLaptop {
					display: inline-block;
				}
			}
		}
	}
	.profile {
		&__avatarSection {
			.editAvatar {
				.avatar {
					height: 103px;
					width: 103px;
				}
			}
			.image.defaultIcon {
				background-size: 70%;
				margin-bottom: 0;
			}
		}
	}
	.contentSection__contentWrapper {
		padding: 30px 30px 0 30px;
	}
	.usersList {
		padding-bottom: 100px;
		&__title {
			color: var(--color--content--foreground-60);
			margin-bottom: 25px;
			font-size: 14px;
			@include minDesktop {
				font-size: 16px;
			}
		}
		li {
			margin: 0 20px 30px 0;
			min-width: 250px;
			max-width: 300px;
			.markedAvatar {
				overflow: hidden;
			}
			.avatarWrapper {
				border-radius: 0;
			}
			&:hover {
				.markedAvatar {
					.avatarWrapper .image {
						transform: scale(1.1);
					}
				}
			}
			@include minLaptop {
				@include XXXL {
					width: 30%;
					min-width: 220px;
				}
			}
		}
		.markedAvatar {
			width: 78px;
			height: 78px;
			transition: all 0.05s ease-in;
			position: relative;
			transform: translateZ(0);
			.avatarWrapper {
				overflow: hidden;
				position: relative;
				width: inherit;
				height: inherit;
				background-color: var(--color--interactive--background);
				.image {
					border-radius: 50%;
					top: 0;
					left: 0;
					position: absolute;
					width: 100%;
					height: 100%;
					transform: scale(1);
					transition: all 0.1s ease-in;
				}
			}
			&.userAvatar {
				background-color: transparent;
				.avatarWrapper {
					background-color: transparent;
				}
			}
			.roundNumber {
				min-width: 25px;
				height: 25px;
				background-color: var(--color--interactive--background);
				transform: translate(-100%, 0);
				.number {
					border-radius: 25px;
					background-color: var(--color--interactive--background);
					color: #fff;
					top: 0;
					font-size: 14px;
				}
			}
		}
		.userData {
			font-size: 12px;
			padding-left: 20px;
			.mail {
				color: var(--color--content--foreground);
			}
			.role {
				color: var(--color--content--foreground-60);
				margin-top: 3px;
			}
			.name {
				color: var(--color--interactive--background);
				font-size: 14px;
			}
		}
	}
	.achievementList {
		font-size: 14px;
		.achievementTile {
			width: 155px;
			margin: 0 20px 20px 0;
			border-radius: 10px;
			text-align: center;
			> * {
				font-weight: 100;
				width: inherit;
				border-radius: 10px;
			}
			&__front {
				position: relative;
				padding: 20px 15px;
				border: 1px solid $standardBgrDark;
				.image {
					width: 100%;
					height: 100px;
					margin-bottom: 20px;
				}
				.title {
					color: var(--color--interactive--background);
					font-weight: 400;
					margin-bottom: 7px;
				}
			}
			&__reverse {
				position: relative;
				background-color: $standardBgr;
				padding: 20px 8px 8px 8px;
				opacity: 0;
				left: 0;
				transition: opacity 0.05s ease-in;
			}
			@include minLaptop {
				&:hover {
					.achievementTile {
						&__front {
							left: -100%;
							opacity: 0;
						}
						&__reverse {
							opacity: 1;
							left: -100%;
						}
					}
				}
			}
		}
		.winnersList {
			margin-top: 15px;
			overflow: hidden;
			ul {
				position: relative;
				height: 130px;
				width: calc(100% + 54px);
				padding: 2px 5px 5px 5px;
				li {
					width: 36px;
					height: 36px;
					margin: 0 10px 10px 0;
					transition: all 0.05s ease-in;
					&:hover {
						transform: scale(1.1);
					}
					a {
						display: block;
						width: inherit;
						height: inherit;
						border-radius: 50%;
					}
				}
			}
		}
	}
	.autocomplete {
		width: 100%;
		margin-left: 0;
		.menu li {
			margin: 0;
		}
	}
	.levelBox {
		margin-top: 30px;
	}
	@include M {
		.profile {
			.usersList {
				margin-bottom: 20px;
				overflow: visible;
				li {
					width: 100%;
					margin: 0 0 10px 0;
				}
				.markedAvatar {
					width: 58px;
					height: 58px;
				}
			}
			.contentSection__contentWrapper {
				padding: 30px;
			}
			.achievementList {
				justify-content: center;
			}
		}
	}
}
