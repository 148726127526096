.create-submission {
	header {
		background-color: var(--color--primary--background);
		color: var(--color--primary--foreground);

		h3,
		h4 {
			font-weight: $font-weight-light;
		}

		h3 {
			font-size: 1.75rem;
		}

		h4 {
			font-size: 1rem;
		}
	}

	section {
		ul.submission-types {
			li {
				cursor: pointer;

				&:hover {
					background-color: var(--color--content--foreground-5);
				}

				i {
					font-size: 90px;
					color: var(--color--interactive--background);
				}

				p {
					font-size: 12px;
				}
			}
		}
	}

	button {
		//TODO: refactor button loading status
		&.loading {
			width: auto;
			span {
				padding: 0 15px 0 10px;
			}
		}
	}

	p.return {
		span {
			color: var(--color--interactive--background);
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.create-image-submission {
		.select-image {
			background-color: var(--color--interactive--background);
			color: var(--color--interactive--foreground);
			position: relative;
			transition: box-shadow 0.1s ease-in;

			&:hover {
				box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1) inset;
			}

			.loader {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-color: var(--color--content--foreground-60);
				transform: none;
			}

			.icons {
				font-size: 90px;
			}

			input[type='file'] {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				border: 0;
				opacity: 0;
				cursor: pointer;
			}
		}

		.image-preview {
			img {
				width: auto;
				max-width: 100%;
				max-height: 600px;
				border: 1px solid var(--color--content--foreground-10);
			}
		}
	}

	.confirmation-view,
	.error-view {
		i {
			font-size: 120px;
		}
		p {
			font-size: 18px;
			font-weight: $font-weight-light;
			line-height: 1.2;
		}
	}

	.confirmation-view {
		i {
			color: #00b33a;
		}
	}

	.error-view {
		i {
			color: var(--color--interactive--background);
		}
	}
}
