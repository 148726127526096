@font-face {
	font-family: 'FontAwesome';
	src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
	src: url('#{$fa-font-path}/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
		url('#{$fa-font-path}/fontawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
		url('#{$fa-font-path}/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
		url('#{$fa-font-path}/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
		url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
	//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
