.tile {
	display: flex;
	overflow: hidden;

	$this: &;

	&:hover:not(.disable) {
		@include media-breakpoint-up(sm) {
			@include hover() {
				#{$this}__front {
					visibility: hidden;
					order: 1;
				}

				#{$this}__back {
					visibility: visible;
					order: 0;
				}
			}
		}
	}

	&__front,
	&__back {
		width: 100%;
		height: inherit;
	}

	&__front {
		visibility: visible;
		order: 0;
	}

	&__back {
		display: none;
		visibility: hidden;
		order: 1;
		margin-right: -100%;
	}

	&__front + &__back {
		display: block;
	}
}
