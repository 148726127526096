// SVG

.currency,
.iconTask {
	path {
		fill: #004b96;
	}
	&--black {
		path {
			fill: #000;
		}
	}
}
