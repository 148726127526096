// roundNumber

.roundNumber {
	border-radius: 50%;
	position: absolute;
	transition: all 0.3s ease-in;
	background-color: color-mod(var(--color--primary--background) shade(0.9));
	.number {
		text-align: center;
		font-weight: 600;
		font-size: 12px;
		color: #fff;
		width: 100%;
		height: 100%;
		background-color: var(--color--primary--background);
		border-radius: 50%;
		padding: 5px 5px 3px 5px;
		position: relative;
		top: -2px;
		min-width: 21px;
	}
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		top: 2px;
		background-color: #000;
	}
	&.roundNumber--topRight {
		right: 0;
		top: 0;
	}
	&--noShadow {
		background-color: var(--color--primary--background);
		.number {
			top: 0;
			font: {
				size: 14px;
				weight: 400;
			}
		}
	}
	&.roundNumber--bordered {
		border: 3px solid #fff;
	}
	&__big {
		font-size: 36px;
		padding: 11px 12px;
		border-radius: 29px;
		text-align: center;
		font-weight: 400;
		min-width: 58px;
		position: static;
		margin-bottom: 30px;
		display: inline-block;
	}
}
