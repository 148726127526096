// <Avatar />

.profile-avatar {
	width: 103px;
	min-width: 103px;
	height: 103px;
	background: {
		color: var(--color--primary--background);
		size: cover;
		position: center;
		repeat: no-repeat;
	}

	input[type='file'] {
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		opacity: 0;
		cursor: pointer;
		z-index: 600;

		&:hover + button {
			top: 0;
		}
	}

	button {
		top: 65%;
		left: 0;
		bottom: 0;
		z-index: 500;
		opacity: 0.8;
		width: 100%;
		font-size: 1rem;
		transition: all 0.3s ease 0s;
		color: var(--color--primary--foreground);
		background: linear-gradient(
			to bottom,
			var(--color--primary--background) 0%,
			var(--color--primary--background) 100%
		);

		&.inProgress {
			top: 0;
		}
	}
}
