// prizeDetails

.prizeDetails {
	.standardPreview {
		&__column--right {
			text-align: center;
			.btn__basic {
				margin-bottom: 30px;
				padding: 10px 30px;
			}
		}
		&__column--middle {
			img:not(.imageViewer__miniature) {
				width: 90%;
			}
		}
	}
	.scoreItemVisualization {
		.currencyContainer {
			width: 50%;
			flex-direction: column;
			align-content: center;
			text-align: center;
			margin-bottom: 30px;
			img {
				width: 70%;
				height: auto;
			}
			span {
				font-size: 3em;
			}
		}
	}
	.markdown {
		color: var(--color--content--foreground-60);
	}
	.availabilityInfo {
		font-size: 47px;
		color: var(--color--content--foreground);
		font-weight: 100;
		margin-bottom: 40px;
		.slash {
			width: 10px;
			position: relative;
			margin: 0 10px;
			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				height: 40px;
				width: 1px;
				background-color: var(--color--content--foreground-30);
				transform: rotate(17deg);
			}
		}
	}
	.itemPrice {
		margin-bottom: 20px;
	}
	.missingCurrencies {
		margin-bottom: 20px;
		> div {
			margin-top: 10px;
		}
		img {
			margin-left: 5px;
			position: relative;
			top: -2px;
		}
		.progressBar {
			margin-top: 3px;
		}
	}
	@include M {
		.standardPreview {
			&__column--middle {
				flex-shrink: 0;
			}
			&__column--right {
				padding-top: 50px;
				.buyItem {
					display: none;
				}
			}
		}
	}

	.buyPopup {
		&__acceptation {
			.content h1 {
				padding-bottom: 30px;
			}
		}
		.background {
			position: fixed;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			z-index: 200;
			background-color: var(--color--content--background-60);
		}

		.content {
			background-color: var(--color--primary--background);
			position: fixed;
			left: 50%;
			top: 50%;
			z-index: 250;
			transform: translate(-50%, -50%);
			padding: 30px 40px;
			border-radius: 10px;

			h1,
			p {
				color: var(--color--primary--foreground);
			}

			h1 {
				font-size: 28px;
			}

			p {
				font-size: 18px;
				margin-top: 20px;
			}

			.buttonsContainer {
				display: flex;
				justify-content: space-around;
				button {
					margin: 10px 0 0 0;
				}
			}
		}
	}
}
