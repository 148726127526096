// .answer
// Selectable position in native tasks.

.answer {
	position: relative;
	.technicalInput {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		&.disablePosition {
			top: auto;
			left: auto;
			width: auto;
			height: auto;
		}
		&:checked {
			~ .imageWrapper .image {
				filter: grayscale(0);
				height: 103%;
				width: 103%;
			}
			~ .answer {
				&__textType::after {
					content: none;
				}
				&__selectArea {
					background-color: var(--color--content--foreground-10);
					.customInput {
						display: inline-block;
						.checked {
							display: block;
							&::after {
								width: 14px;
							}
							&::before {
								width: 7px;
							}
						}
						&--radio .checked::after {
							width: 10px;
							height: 10px;
						}
					}
					.fa {
						display: inline-block;
					}
					.iterator {
						display: none;
					}
					p {
						display: none;
					}
				}
				&__contentArea {
					background-color: var(--color--content--foreground-5);
					&::after,
					&::before {
						content: '';
						position: absolute;
						width: 100%;
						height: 1px;
						left: 0;
						background-color: var(--color--content--background);
						z-index: 200;
					}
					&::after {
						bottom: 0;
					}
					&::before {
						top: -1px;
						bottom: initial;
					}
					.standardText {
						color: var(--color--content--foreground);
					}
				}
			}
			+ .image {
				filter: grayscale(0);
			}
		}
	}
	* {
		transition: all 0.15s ease-in;
	}
	@include minLaptop {
		&:not(&--static):hover {
			.answer {
				&__textType::after {
					content: none;
				}
				&__selectArea {
					background-color: var(--color--content--foreground-10);
					.fa {
						display: inline-block;
					}
					.customInput {
						display: inline-block;
					}
					.iterator {
						display: none;
					}
					p {
						display: none;
					}
				}
				&__contentArea {
					background-color: var(--color--content--foreground-5);
					.standardText {
						color: var(--color--content--foreground);
					}
				}
			}
		}
	}
	&__textType {
		width: 100%;
		min-height: 60px;
		position: relative;
		z-index: 600;
		@include M {
			height: auto;
			min-height: 68px;
			&__contentArea {
				padding: 5px 30px;
			}
		}
		&::after,
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			left: 0;
			background-color: var(--color--content--foreground-10);
		}
		&::after {
			bottom: 0;
		}
		&::before {
			top: -1px;
			bottom: initial;
			background-color: transparent;
		}
		&:last-child::after {
			background-color: transparent;
		}
		.customInputWrapper {
			height: 28px;
		}
		.answer__selectArea {
			padding: 16px 0;
		}

		@include minLaptop {
			&:not(&.answer--static):hover {
				&::after,
				&::before {
					background-color: var(--color--content--background);
				}
			}
		}
	}
	&__selectArea {
		width: 76px;
		position: relative;
		padding: 20px 0;
		color: var(--color--content--foreground-30);
		@include core-border-radius(10px, top-left, bottom-left);
		overflow: hidden;
		.fa {
			display: none;
		}
		&::after {
			content: '';
			position: absolute;
			height: 29px;
			width: 1px;
			right: 0;
			top: 50%;
			background-color: var(--color--content--foreground-10);
			transform: translate(0, -50%);
		}
		&--imageType {
			width: 36px;
			height: 36px;
			position: absolute;
			bottom: 10px;
			right: 10px;
			background-color: var(--color--content--background);
			border-radius: 10px;
			padding: 0;
			&::after {
				content: none;
			}
		}
		.iterator {
			color: var(--color--content--foreground-30);
			font-size: 28px;
		}
	}
	&__contentArea {
		padding: 5px 30px;
		@include core-border-radius(10px, top-right, bottom-right);
		overflow: hidden;
		.standardText {
			color: var(--color--interactive--background);
			font-weight: 600;
			text-align: left;
		}
		input[type='text'] {
			padding: 5px 10px;
			border-radius: 19px;
			border: 1px solid var(--color--content--foreground-10);
			width: 100%;
		}
	}
	&__imageType {
		width: 32%;
		height: 12.6vw;
		position: relative;
		border-radius: 10px;
		margin: 0 10px 10px 0;
		overflow: hidden;
		border: 1px solid var(--color--content--foreground-30);
		.image {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 10px;
			transition: 0.2s ease-out;

			@include hooverSupported {
				filter: grayscale(1);
			}
		}
		.iterator {
			position: relative;
			top: 2px;
		}
		.technicalInput:checked ~ .answer__selectArea {
			background-color: var(--color--content--background);
		}
		@include minLaptop {
			&:hover {
				&.answer .answer__selectArea {
					background-color: var(--color--content--background);
				}
				.image {
					filter: grayscale(0);
					height: 103%;
					width: 103%;
				}
			}
		}
	}
	&.incorrect {
		.technicalInput:checked {
			& ~ .answer {
				&__imageType {
					filter: grayscale(0);
				}
			}
		}
	}
	&.correct,
	&.incorrect {
		@include minLaptop {
			&:hover .technicalInput ~ .answer__selectArea {
				color: var(--color--content--foreground-10);
			}
		}
		.technicalInput {
			& ~ .answer__selectArea {
				.customInput {
					display: none;
				}
				.iterator {
					display: block;
				}
				p {
					display: block;
				}
			}
		}
	}
}
