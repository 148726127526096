// imageViewer

.imageViewer {
	width: 100%;
	&__mainImageWrapper {
		width: 100%;
		@include minLaptop {
			padding-bottom: 10vh;
		}
		.image {
			border-radius: 10px;
		}
		+ div {
			width: 100%;
			position: relative;
		}
	}
	&__miniature {
		width: 100%;
		height: auto;
		cursor: pointer;
		transition: all 0.1s ease-out;
		border-radius: 10px;
	}
	&__miniatureWrapper {
		width: 25%;
		padding: 0 10px;
		height: 12vh;
	}
	&__miniatureContainer {
		overflow: hidden;
		height: calc(12vh + 20px);
		.miniatureContainer__technical {
			padding-bottom: 20px;
			position: relative;
			transition: all 0.2s ease-out;
			overflow-x: scroll;
		}
	}
	.returnArrow {
		opacity: 0.8;
		background-color: var(--color--interactive--background);
		&:hover {
			opacity: 1;
		}
		&--left {
			left: -25px;
		}
		&--right {
			right: -25px;
		}
	}
	@include M {
		&__miniatureWrapper {
			width: 100%;
			height: 30vh;
		}
		&__miniatureContainer {
			height: 30vh;
			.miniatureContainer__technical {
				height: calc(30vh + 20px);
				overflow-x: visible;
				position: relative;
				&.positioning {
					transition: left 0.5s ease-out;
				}
			}
		}
		&__miniature {
			width: 100%;
			height: 30vh;
		}
		.singleImage {
			width: 100%;
			height: 30vh;
		}
		.arrow {
			display: none;
		}
	}
}
