.visitLinkTaskContainer {
	.progressBarWrapper {
		@include M {
			margin: 0 30px;
		}
		.progressBar {
			.progressBar__content {
				transition: width 1s linear;
			}
		}
	}
	.standardPreview__header .returnArrow__wrapper {
		z-index: 1100;
	}
	.standardPreview__footer {
		.progressBar {
			border: 1px solid rgba(51, 51, 51, 0.1);
			&__content {
				background: linear-gradient(
					to bottom,
					$footerCorrectBgr 0%,
					color-mod($footerCorrectBgr shade(0.9)) 100%
				);
			}
			&__number {
				color: rgb(51, 51, 51);
			}
			&__status {
				strong {
					color: rgb(51, 51, 51);
				}
			}
		}
		@include minLaptop {
			.footer__interactiveSection--wide {
				width: 50%;
			}
		}
		@include S {
			.footer__interactiveSection {
				flex-grow: 1;
				.progressBarWrapper {
					justify-content: center;
					margin: 0 10px;
					.progressBar__status {
						margin-top: 5px;
					}
				}
			}
		}
	}
}
.visitLinkTask {
	position: relative;
	iframe {
		width: 100%;
		height: 100%;
	}
	.externalPageWrapper {
		position: fixed;
		top: 70px;
		left: 0;
		bottom: 76px;
		width: 100vw;
		height: calc(100vh - 146px);
		z-index: 1000;
		@include M {
			position: absolute;
			height: 100%;
			top: 0;
			bottom: 0;
		}
	}
	&__questionWrapper {
		.timeCountTask__textWrapper {
			text-align: left;
			span {
				padding-right: 40px;
				display: block;
			}
		}
	}
	&__textWrapper {
		.markdown {
			font-size: inherit;
		}
		a {
			color: var(--color--interactive--background);
			padding: 0 5px;
			display: inline-block;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				height: 2px;
				width: 0;
				bottom: -0;
				left: 50%;
				transform: translate(-50%, 0);
				transition: width 0.2s;
				background-color: var(--color--interactive--background);
			}
			@include minLaptop {
				&:hover::after {
					width: 100%;
				}
			}
		}
	}
	.progressBarWrapper {
		width: 100%;
		margin: 60px 0 0 0;
		.progressBar__marker {
			top: 0;
			border-left: 1px solid var(--color--content--foreground-10);
		}
	}
	@include M {
		&__questionWrapper {
			.timeCountTask__textWrapper {
				padding: 0 0 30px 0;
				text-align: center;
				span {
					padding-right: 0;
				}
			}
		}
	}
	.taskDetails .standardPreview {
		&.minimalPassedStatus {
			.progressBar__content {
				background: linear-gradient(to bottom, $yellowStatus 0%, $yellowStatusDark 100%);
			}
			.btn__basic::after {
				background: linear-gradient(to bottom, $yellowStatus 0%, $yellowStatusDark 100%);
			}
			.standardPreview__footer {
				background-color: $yellowStatusFooter;
			}
		}
		&.waitingStatus {
			.progressBar__content {
				background: var(--color--primary--background-10);
			}
		}
	}
}
