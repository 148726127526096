// Curency container

.currencyContainer {
	display: flex;
	align-items: center;
	&__number {
		color: var(--color--content--foreground);
		font-size: 14px;
		margin: 0 10px 0 5px;
		position: relative;
		top: 1px;
		font-weight: 600;
		@include minDesktop {
			font-size: 16px;
		}
	}
	&__icon {
		width: 18px;
		height: 18px;
	}
	img .currencyCotainer__icon {
		width: auto;
		height: 18px;
	}
	&--big {
		span.currencyConainer__number {
			font-size: 1.8em;
		}
	}
	.degressionAnimation {
		.currencyContainer {
			&__icon {
				animation: degressionIconInitial 1.5s ease-in, degressionIconChill 3s 1.5s ease-out;
			}
			&__number {
				animation: degressionNumberInitial 1.5s ease-in, degressionNumberChill 3s 1.5s ease-out;
			}
			&__icon,
			&__number {
				position: relative;
			}
		}
	}
}

@keyframes degressionIconInitial {
	0% {
		transform: scale(1) translate(0, 0);
	}
	100% {
		transform: scale(1.3) translate(-3px, 0);
	}
}

@keyframes degressionIconChill {
	0% {
		opacity: 1;
		transform: scale(1.3) translate(-3px, 0);
	}
	15% {
		opacity: 1;
		transform: scale(1.3) translate(-3px, 0);
	}
	80% {
		opacity: 0;
		transform: scale(0.8) translate(-3px, 20vh);
	}
	81% {
		opacity: 0;
		transform: scale(0) translate(-3px, 0);
	}
	90% {
		opacity: 1;
	}
	95% {
		transform: scale(1) translate(0, 0);
	}
}

@keyframes degressionNumberInitial {
	0% {
		transform: scale(1);
		text-shadow: 1px 1px 1px transparent;
	}
	100% {
		transform: scale(1.2);
		text-shadow: 1px 4px 4px red;
	}
}

@keyframes degressionNumberChill {
	0% {
		transform: scale(1.2);
		text-shadow: 1px 4px 4px red;
	}
	100% {
		transform: scale(1);
		text-shadow: 1px 4px 4px transparent;
	}
}
