// autoScrollingText

.autoScrollingText {
	@include M {
		overflow: hidden;
		height: 24px;
		position: relative;
		border-left: 1px solid var(--color--primary--foreground-10);
		&::after,
		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			height: 100%;
			width: 30px;
		}
		&::after {
			left: 0;
			background: linear-gradient(
				to right,
				var(--color--primary--background) 0%,
				var(--color--primary--background-0) 100%
			);
		}
		&::before {
			right: 0;
			background: linear-gradient(
				to left,
				var(--color--primary--background) 0%,
				var(--color--primary--background-0) 100%
			);
		}
		.autoScrollingText__wrapper {
			overflow-x: scroll;
			overflow-y: hidden;
			height: 40px;
		}
		.text {
			flex-shrink: 0;
		}
	}
}
