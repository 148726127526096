@import './avatar/avatar';
@import './setting-field/setting-field';
@import './wysiwyg/wysiwyg';

form.form {
	input[type='text'],
	input[type='password'],
	textarea {
		background-color: var(--color--content--foreground-3);
		color: var(--color--content--foreground-60);
		border: 1px solid var(--color--content--foreground-30);
		max-width: 100%;
		// GLOBAL OVERRIDES:
		width: 100%;
		height: auto;
		margin-bottom: auto;

		font-size: 1em;

		&:focus {
			border-color: var(--color--interactive--background);
			color: var(--color--interactive--background);
			background-color: var(--color--content--foreground-5);
		}

		&[disabled] {
			background-color: var(--color--content--foreground-20);
			color: var(--color--content--foreground-60);
		}

		&::placeholder {
			color: var(--color--content--foreground-30);
		}
	}

	fieldset {
		&[disabled] {
			input[type='text'],
			input[type='password'],
			textarea,
			select {
				background-color: var(--color--content--foreground-20);
				color: var(--color--content--foreground-60);
			}
		}
	}

	input {
		min-height: 1.8em;
		padding: 0.3rem 0.5rem;

		&[size] {
			width: auto;
		}

		&.rounded {
			border-radius: 1em !important;
		}
	}

	textarea {
		height: 150px;
		display: block;
		line-height: 1.2;
		border-radius: 0.5rem;
		padding: 0.5rem;

		@include media-breakpoint-down(xs) {
			height: 200px;
		}
	}

	.error {
		color: red;
		display: block;
		text-align: left;
		padding-top: 5px;
		padding-left: 10px;
	}

	label.search {
		position: relative;
		line-height: normal;
		input {
			padding-left: 0.7rem;
			padding-right: 2rem;
			height: 1rem;
			line-height: 1rem;
			border-radius: 15px;
			font-size: 1rem;
		}
		&::after {
			content: '\f002';
			font-family: FontAwesome;
			font-size: 16px;
			color: var(--color--content--foreground-30);
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translate(0, -50%);
		}
	}
}
