.view__error {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: var(--color--content--background);
	.content {
		display: flex;
		flex-direction: column;
		max-width: 500px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		color: var(--color--content--foreground);
	}
	.icon {
		color: var(--color--interactive--background);
		font-size: 120px;
		margin: 0 auto;
	}
	h2 {
		font-size: 28px;
		text-align: center;
		line-height: 1.2;
	}
	a.btn__basic {
		align-self: center;
	}
	p.info {
		color: var(--color--content--foreground-50);
		font-size: 12px;
		text-align: center;
		border-top: 1px solid var(--color--content--foreground-10);
	}
	@include S {
		overflow-y: scroll;
		.content {
			position: static;
			left: auto;
			top: auto;
			transform: none;
			padding: 20px;
		}
	}
}
