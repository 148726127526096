// arrow

.arrow {
	position: absolute;
	top: 50%;
	height: 40px;
	width: 40px;
	cursor: pointer;
	background-color: var(--color--interactive--background);
	border-radius: 50%;
	text-align: center;
	opacity: 0.7;
	&:hover {
		opacity: 1;
	}
	i {
		font-family: FontAwesome;
		color: var(--color--content--background);
		font-size: 32px;
		line-height: 0.8;
		position: relative;
		left: 1px;
	}
	&.arrow--left {
		left: -20px;
		transform: rotate(-90deg) translate(50%, 0);
	}
	&.arrow--right {
		transform: rotate(90deg) translate(-50%, 0);
	}
}
