// editableTextField

.resizableTextField {
	overflow: hidden;
	.editableTextField {
		width: 100%;
		padding: 5px 10px;
		margin-right: 10px;
		resize: none;
		background-color: transparent;
		border: 2px solid var(--color--primary--foreground-10);
		&:focus {
			outline: 0;
			box-shadow: 0 0 12px 3px var(--color--content--foreground-5) inset;
		}
		&--inputLook {
			padding: 6px 10px;
		}
		+ div {
			text-align: left;
			font-size: 0.8em;
		}
	}
	textarea {
		overflow: hidden;
	}
	.description {
		color: var(--color--content--foreground-60);
		text-align: left;
		font-size: 0.8em;
		margin-bottom: 5px;
	}
	.noBorder {
		border: none;
	}
}
