// customInput  - input[type="radio"], input[type="checkbox"];
// DEFAULT: type="checkbox"
// customInput - input for use in tasks
// checkboxWrapper - custom checkbox input for other use

.customInput {
	background-color: var(--color--content--background);
	box-shadow: 0 0 0 1px var(--color--content--foreground-30);
	border-radius: 3px;
	width: 20px;
	height: 20px;
	position: relative;
	cursor: pointer;
	display: none;
	.checked {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		&::before,
		&::after {
			position: absolute;
			background-color: var(--color--interactive--background);
			transition: all 0.2s ease-in;
		}
		&::after {
			content: '';
			width: 0;
			height: 3px;
			top: 11px;
			left: 4px;
			transform: rotate(-47deg) translate(2px, -1px);
			border-radius: 3px;
		}
		&::before {
			width: 0;
			height: 3px;
			content: '';
			top: 11px;
			left: 2px;
			transform: rotate(45deg);
			border-radius: 3px;
		}
	}
	&--radio {
		border-radius: 50%;
		.checked {
			&::before {
				content: none;
			}
			&::after {
				width: 0;
				height: 0;
				border-radius: 50%;
				top: 10px;
				left: 10px;
				transform: translate(-50%, -50%);
			}
		}
	}
}

.checkboxWrapper {
	margin-right: 10px;
	width: 21px;
	height: 21px;
	position: relative;
	border: 1px solid var(--color--content--foreground-30);
	border-radius: 2px;
	overflow: hidden;
	+ div {
		text-align: left;
	}
	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		&:checked + .checked {
			transform: scale(1);
		}
	}
	.checked {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: scale(0);
		transition: 0.2s ease-out;
		&::after,
		&::before {
			content: '';
			position: absolute;
			width: 3px;
			top: 50%;
			left: 50%;
			@include core-border-radius(3px, top-left, top-right);
			background-color: var(--color--content--foreground);
		}
		&::after {
			height: 7px;
			transform: translate(-5px, -1px) rotate(-48deg);
		}
		&::before {
			height: 13px;
			transform: translate(1px, -5px) rotate(47deg);
			@include M {
				transform: translate(1px, -5px) rotate(47deg);
			}
		}
	}
}
