// <SettingField />

.settingField {
	font-size: 0.85rem;

	input,
	textarea {
		color: var(--color--interactive--background);
		outline: 0;
		&[disabled] {
			background-color: transparent;
		}
	}
	textarea {
		height: 136px;
		resize: vertical;
		line-height: 1.4;
		background-color: transparent;
	}
	h6 {
		font-size: inherit;
		color: var(--color--content--foreground-50);
	}
	i {
		color: var(--color--content--foreground-20);
		font-size: 1.2rem;
	}

	&__container {
		border: 1px solid transparent;
		border-bottom: 1px solid var(--color--content--foreground-10);
	}

	&__name {
		color: var(--color--content--foreground-50);
		white-space: nowrap;
	}

	&__value {
		line-height: 1.4;
		word-wrap: break-word;
		word-break: break-word;
		white-space: pre-wrap;

		a {
			color: var(--color--interactive--background);

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&.editMode &__container {
		border: 1px solid var(--color--content--foreground-20);
		i {
			color: var(--color--interactive--background);
		}
	}

	&.editable:hover &__container,
	&.editable.editMode &__container {
		border-radius: 1.6rem;
	}

	&--textarea.editable {
		cursor: pointer;

		&:hover .settingField__container {
			border: 1px solid var(--color--content--foreground-20);
			p,
			i {
				color: var(--color--interactive--background);
			}
		}
	}

	&--text.editable {
		cursor: pointer;

		&:hover .settingField__container {
			border: 1px solid var(--color--content--foreground-20);
			.settingField__value,
			i {
				color: var(--color--interactive--background);
			}
		}
	}
}
