.task__join {
	.task__container {
		width: 100%;
		max-width: 600px;
		text-align: center;
		h2 {
			font-size: 36px;
			color: var(--color--primary--background);
		}
	}

	.description,
	.title {
		text-align: center;
	}
	.description {
		color: var(--color--content--foreground-60);
		padding: 20px;
		max-width: 800px;
		font-size: 18px;
	}

	.title {
		margin: 40px 0 60px 0;
		&::after {
			content: attr(data-text);
			position: relative;
			z-index: 200;
			font-size: 18px;
			color: var(--color--content--foreground);
			background-color: var(--color--content--background);
			padding: 0 10px;
		}
		&::before {
			content: '';
			height: 1px;
			background-color: var(--color--content--foreground-10);
			position: absolute;
			left: 30px;
			right: 30px;
			transform: translate(0, 9px);
			z-index: 100;
		}
	}
	.field {
		height: 100%;
		position: relative;
		margin-bottom: 30px;
	}
	.fields .field label.autocomplete {
		text-align: left;
	}
	.fields .field:nth-of-type(1) label.autocomplete {
		z-index: 10;
	}
}

.isAnonymous {
	.description,
	.title {
		text-align: center;
	}
	.description {
		color: var(--color--content--foreground-60);
		padding: 20px;
		max-width: 800px;
	}
	.title {
		margin: 40px 0 60px 0;
		&::after {
			content: attr(data-text);
			position: relative;
			z-index: 200;
			font-size: 18px;
			color: var(--color--content--foreground);
			background-color: var(--color--content--background);
			padding: 0 10px;
		}
		&::before {
			content: '';
			height: 1px;
			background-color: var(--color--content--foreground-10);
			position: absolute;
			left: 30px;
			right: 30px;
			transform: translate(0, 9px);
			z-index: 100;
		}
	}
}
