// phraseQuestion

.phraseQuestion,
.timeCountTask,
.declarationTask,
.visitLinkTask {
	height: 100%;
	.standardText {
		font-size: 14px;
		color: var(--color--content--foreground-60);
	}
	&.completeSentence {
		.phraseQuestion__textWrapper {
			flex: 5;
		}
		.phraseQuestion__imageWrapper {
			flex: 4;
		}
	}
	&__questionWrapper {
		width: 100%;
		padding: 20px 0;
		.phraseQuestion__answer .visualStatus {
			width: 25px;
			height: 25px;
			transform: translate(10px, -15px);
		}
	}
	&__imageWrapper {
		flex: 1;
		margin-left: 40px;
		border-radius: 10px;
		background-position: top left;
		width: 100%;
		min-width: 10px;
		.matchingImageType {
			border-radius: 10px;
		}
		img {
			width: 100%;
			height: auto;
			border-radius: 10px;
		}
	}
	&__textWrapper {
		text-align: center;
		flex: 1;
		width: 100%;
		&.rightAlign {
			text-align: center;
			@include M {
				text-align: center;
			}
		}
		span {
			font-size: inherit;
			line-height: 1.2;
			white-space: pre-line;
			color: var(--color--content--foreground-60);
		}
		textarea {
			max-width: 700px !important;
			margin: 0 auto;
		}
	}
	&__answer {
		display: inline-block;
		background-color: var(--color--content--foreground-3);
		position: relative;
		font-size: inherit;
		border: 1px solid var(--color--content--foreground-30);
		border-radius: 10px;
		margin: 5px 5px 5px 5px;
		input {
			width: inherit;
			padding: 5px 10px;
			font-size: inherit;
			border: none;
			background-color: transparent;
			text-align: center;
			color: var(--color--content--foreground-80);
			// Hide IE default clear 'x' sign
			&::-ms-clear {
				display: none;
			} // sass-lint:disable-line no-vendor-prefixes
			&::placeholder {
				color: inherit;
			}
			&:focus {
				outline: none;
			}
		}
		textarea,
		textarea::placeholder {
			color: var(--color--content--foreground);
		}
		.roundVisualStatus {
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(20px, -20px);
		}
		&--bigMargin {
			margin: 5px 10px;
		}
		&--medium,
		&--big {
			font-size: 18px;
			color: var(--color--content--foreground-60);
			.roundVisualStatus {
				transform: translate(20px, -20px);
			}
		}
		&--medium {
			width: 300px;
		}
		&--big {
			max-width: 600px;
			width: 100%;
			.resizableTextField .editableTextField:focus {
				box-shadow: none;
			}
		}
		&--noMargin {
			margin: 0;
		}
	}
	.correct.phraseQuestion__answer {
		background-color: $answerCorrect;
		border: none;
		input {
			color: #fff;
		}
		.roundVisualStatus {
			@include roundVisualStatus__correct;
		}
	}
	@include L {
		&__imageWrapper {
			margin: 0 0 20px 40px;
			justify-content: center;
			flex-direction: column;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	@include M {
		padding: 30px;
		&__answer {
			width: 100%;
		}
		&__questionWrapper {
			flex-direction: column-reverse;
			width: 100%;
			padding: 0;
			flex-grow: 0;
		}
		&__imageWrapper {
			margin: 0 0 20px 0;
			width: 100%;
		}
		&__textWrapper {
			text-align: center;
		}
		&--big {
			width: 100%;
		}
	}
	@include minLaptop {
		&__questionWrapper {
			padding: 0 10vw;
			width: 100%;
		}
		&.completeSentence .phraseQuestion__questionWrapper {
			@include L {
				width: 95%;
			}
		}
	}
	@include minDesktop {
		&__questionWrapper {
			padding: 0 6vw;
		}
	}
}
