// taskDetailsMobile

.taskDetails {
	@include M {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100vw;
		.imageQuestions {
			margin-top: 20px;
			flex-shrink: 0;
			&.standardPreview__column--middle {
				padding: 0 0 30px 30px;
			}
			.answer {
				&__selectArea {
					border-radius: 10px;
					bottom: -23px;
					&::after {
						content: none;
					}
				}
			}
			.answer__imageType .technicalInput:checked ~ .answer__selectArea {
				background-color: $answerCorrect;
			}
		}
		.standardPreview {
			&__header {
				.technicalHeader {
					overflow-x: auto;
					overflow-y: hidden;
					border-left: 1px solid rgba($previewHeader, 0.1);
					h1 {
						border-left: 0;
					}
				}
				.autoScrollingText {
					border-left: 0;
				}
			}
			&__mainWrapper {
				margin-bottom: 76px;
			}
			&__column {
				width: 100%;
				padding: 10px 0;
				&--middle {
					order: 2;
					border: none;
					img {
						width: 80%;
					}
				}
				&--left {
					order: 1;
					padding: 10px 30px;
					.standardText {
						margin: 15px 0;
					}
				}
			}
			&__footer {
				position: fixed;
				width: 100%;
				bottom: 0;
				left: 0;
			}
			&.incorrect,
			&.correct {
				.standardPreview {
					&__mainWrapper {
						display: none;
					}
					&__footer {
						display: none;
					}
				}
			}
			.mobileTaskStatus--incorrect,
			.mobileTaskStatus--rejected {
				display: flex;
				background-color: rgba($error, 0.4);
			}
			.mobileTaskStatus--correct {
				display: flex;
				background-color: $footerCorrectBgr;
			}
			.mobileTaskStatus--awaiting {
				display: flex;
				background-color: #f7f4d6;
			}
		}
		&__mockup .standardPreview__mainWrapper {
			margin-bottom: 0;
		}
		.answerList {
			margin-right: 0;
			flex-direction: row;
		}
		.answer {
			&__textType:not(:last-child)::after {
				width: 90%;
				left: 5%;
			}
			&__selectArea {
				border-radius: 0;
			}
			&__contentArea {
				border-radius: 0;
			}
			&__imageType {
				height: 25.4vw;
				width: calc(50% - 30px);
				margin: 0 30px 10vw 0;
				overflow: visible;
			}
		}
		.technicalInput {
			&:checked {
				~ .answer {
					&__textType::after {
						content: none;
					}
					&__selectArea {
						background-color: var(--color--content--foreground-5);
						&::after {
							content: none;
						}
					}
					&__contentArea {
						background-color: var(--color--content--foreground-5);
						.standardText {
							color: var(--color--content--foreground);
						}
					}
				}
				+ .image {
					filter: grayscale(0);
				}
			}
		}
	}
}
