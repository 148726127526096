// availabilityCalendar

.availabilityCalendar {
	width: 100%;
	background-color: var(--color--content--foreground-5);
	border-radius: 10px;
	margin-bottom: 30px;
	&__legend {
		background-color: var(--color--content--foreground-10);
		height: 36px;
		color: rgba(0, 0, 0, 0.2);
		font-size: 14px;
		font-weight: 400;
		border-radius: 10px;
		padding: 0 10px;
	}
	&__contentWrapper {
		padding: 10px;
		ul {
			li {
				width: calc(100% / 7);
				padding: 10px 0;
				text-align: center;
				position: relative;
				font-size: 14px;
				color: var(--color--content--foreground);
				border: {
					right: 1px solid var(--color--content--foreground-10);
					bottom: 1px solid var(--color--content--foreground-10);
				}
				.infoCloud {
					min-width: 210px;
				}
				&:hover .infoCloud.hide {
					display: flex;
				}
				&:nth-child(7n) {
					border-right: none;
					color: var(--color--content--foreground-60);
				}
				&:not(.presentMonth) {
					color: var(--color--content--foreground-60);
				}
				&:nth-last-child(-n + 7) {
					border-bottom: none;
				}
				&.currentDay {
					> span {
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						line-height: 1.9;
						text-align: center;
						color: #fff;
						font-size: 18px;
						font-weight: 600;
						transform: translate(-50%, -50%);
						background-color: var(--color--interactive--background);
						width: 35px;
						height: 35px;
						border-radius: 35px;
					}
				}
				&.available {
					&::after {
						content: '';
						position: absolute;
						top: 5px;
						right: 5px;
						width: 5px;
						height: 5px;
						background-color: var(--color--interactive--background);
						border-radius: 50%;
					}
					&.past::after {
						background-color: rgba(0, 0, 0, 0.3);
					}
				}
				span {
					cursor: default;
				}
			}
		}
	}
}
