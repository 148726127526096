// challengeDetails

.view__challengeDetails {
	height: inherit;
	.standardPreview.challengeDetails {
		.challengeDetails__footer {
			padding: 0 30px;
			height: 76px;
			background-color: $mobileTopGradientBgr;
			z-index: 100;
			.btn__basic {
				font-size: 20px;
				&--disabled {
					font: {
						size: 16px;
						weight: 100;
					}
				}
			}
			.countdown {
				margin-left: 5px;
				font-weight: 600;
			}
			.progressBarWrapper {
				@include minLaptop {
					max-width: 450px;
				}
			}
			.progressBarWrapper strong {
				font-size: 16px;
			}
			.progressBar {
				margin: 0 10px;
				top: -1px;
				&__content {
					height: 10px;
				}
				&__status {
					margin: 0;
				}
				&__status .standardText {
					font: {
						size: 16px;
						weight: 100;
					}
				}
			}
		}
		.standardPreview {
			&__header {
				.buttonWrapper {
					padding-left: 30px;
				}
				.technicalHeader {
					position: relative;
					&::before,
					&::after {
						position: absolute;
						top: 15%;
						height: 70%;
						width: 1px;
						background-color: var(--color--primary--foreground-10);
					}
					&::before {
						left: 0;
						content: '';
					}
					&.technicalHeader--rightBorder {
						padding-right: 30px;
						&::after {
							content: '';
							right: 0;
						}
					}
				}
				.notStarted {
					color: var(--color--primary--foreground-70);
					font-size: 14px;
					div {
						font-weight: 100;
					}
					strong {
						padding-left: 5px;
						position: relative;
						top: -1px;
					}
				}
			}
		}
		.socialBar {
			font-size: 13px;
			color: var(--color--content--foreground-60);
			i {
				opacity: 0.8;
			}
		}
		@include hS {
			.contentSection__contentWrapper {
				overflow: visible;
			}
		}
		@include M {
			.challengeDetails {
				&__footer {
					.btn__basic span {
						top: 2px;
					}
					.progressBar__status .standardText {
						font-size: 14px;
						strong {
							padding: 0;
						}
					}
				}
			}
			.sticky {
				z-index: 400;
			}
			.challengeDetails__descriptionSection {
				padding: 0 30px;
			}
			.contentSection {
				&__contentWrapper {
					overflow: visible;
					min-height: 50vh;
					&.submissionAwards {
						padding-top: 20px;
						overflow: visible;
					}
					.submissionAward {
						&__section {
							flex-direction: column;
						}
						&__tile {
							padding: 0;
							width: 100%;
							> * {
								padding: 15px;
								flex-direction: row;
							}
							.image,
							.prizeCurrencies {
								width: 45%;
								height: 100%;
								margin-right: 20px;
							}
						}
					}
				}
			}
			.progressBarWrapper {
				width: 100%;
			}
			.statusWrapper {
				width: 100%;
			}
		}
		@include XS {
			&__footer {
				.btn__basic {
					min-width: 100px;
				}
				.progressBar__status .standardText {
					font-size: 14px;
				}
			}
		}
	}
}

.view__challengeDetails--creative {
	.standardPreview.challengeDetails {
		.challengeDetails {
			padding: 30px;
			&__descriptionSection {
				margin-right: 0;
				min-width: 300px;
				font-size: 1rem;
			}
			&__mainImage {
				@include minLaptop {
					width: 420px;
					padding-right: 30px;
					margin-right: 30px;
					border-right: 1px solid var(--color--content--foreground-10);
				}
			}
			&__footer {
				@include M {
					padding-top: 20px;
					padding-bottom: 20px;
					height: auto;
				}
			}
			&__detailsRow {
				.currencyContainer {
					&__icon {
						margin-left: 10px;
					}
					&__number {
						margin-right: 0;
					}
				}
			}
		}
		.contentSection__contentWrapper {
			padding: 30px;
		}
		@include M {
			.challengeDetails {
				flex-direction: column;
				padding: 0;
				overflow: auto;
				&__mainImage {
					flex-shrink: 0;
					padding: 30px 30px 0 30px;
				}
				&__contentSection {
					overflow: auto;
				}
				&__descriptionSection {
					padding-top: 0;
				}
				&__footer {
					padding-top: 20px;
					padding-bottom: 20px;
					height: auto;
					&.canParticipate {
						flex-direction: row;
						.statusWrapper {
							width: 50%;
							flex-grow: 0;
						}
						.progressBar__status {
							margin-top: 10px;
							align-self: start;
							strong {
								font-size: 14px;
								margin-left: 10px;
							}
						}
						.progressBarWrapper {
							flex-direction: column-reverse;
							flex-grow: 0;
						}
					}
				}
			}
			.viewTitleBox {
				padding-top: 15px;
				ul {
					padding-left: 0;
				}
				a {
					padding: 12px;
				}
			}
			.standardPreview__mainWrapper {
				overflow: hidden;
			}
			.roundNumber {
				top: 50%;
				transform: translate(75%, -50%);
			}
		}
	}
}
