.challengeList {
	&__tile {
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $tile-box-shadow;

		p.challenge-name {
			color: var(--color--interactive--background);
		}

		img.challenge-participation {
			width: 2rem;
		}

		.challenge-submissions {
			border-left: 1px solid var(--color--content--foreground-30);
			img {
				width: 2rem;
			}
		}

		.tile__front,
		.tile__back {
			background-color: var(--color--content--background);
		}
		.tile__image {
			height: auto;
			padding-top: 60%;
			background-color: #efefef;
		}

		.challenge-button {
			background-color: var(--color--content--foreground-3);
		}
	}
}
