// popup

.submissionPopup {
	&__underlayer {
		position: fixed;
		width: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: var(--color--content--foreground-60);
		z-index: 400;
	}
	&__technicalWrapper {
		z-index: 500;
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		textarea {
			background-color: var(--color--content--background);
			border-radius: 10px;
			border: 1px solid var(--color--content--foreground-10);
			padding: 10px;
			margin-bottom: 10px;
			color: var(--color--content--foreground);
			resize: none;
			line-height: 1.3;
			&:focus {
				outline: none;
			}
		}
	}
	.technicalMargin {
		height: 20px;
		padding: 0;
	}
	.btn__basic {
		height: 23px;
		font-size: 12px;
		min-width: 0;
		padding: 7px 10px 9px;
	}
	&__wrapper {
		position: relative;
		font-weight: 100;
		.error {
			color: $error;
		}
	}
	&__header {
		min-height: 86px;
		position: relative;
		color: var(--color--primary--foreground);
		padding: 0 10px;
		background-color: var(--color--primary--background);
		@include core-border-radius(10px, top-left, top-right);
		.title {
			color: inherit;
			font-size: 28px;
			font-weight: 400;
		}
		.author {
			color: inherit;
			font-size: 12px;
			padding-top: 6px;
			opacity: 0.5;
		}
		.subtitle {
			font-size: 16px;
			padding-top: 4px;
		}
		.btn__basic--close {
			min-width: 0;
			position: absolute;
			top: 0;
			right: 0;
			transform: translate(50%, -50%);
			height: 27px;
			width: 27px;
			border-radius: 50%;
			&::before {
				font-size: 23px;
				font-weight: 100;
				color: #fff;
				z-index: 900;
				position: absolute;
				top: 2px;
				left: 7px;
			}
		}
	}
	@include M {
		&__header {
			border-radius: 0;
			justify-content: flex-start;
			min-height: 64px;
			padding: 0;
			.btn__basic {
				display: none;
			}
			.autoScrollingText {
				width: 100%;
				border-left: 0;
				.text {
					padding: 0 20px;
				}
			}
			.closeWrapper {
				width: 60px;
			}
			.subtitle {
				padding-left: 20px;
				padding-top: 0;
			}
		}
		&__headerText {
			align-items: flex-start;
			text-align: left;
			flex-grow: 1;
			border-left: 1px solid var(--color--primary--foreground-10);
		}
		&__technicalWrapper {
			overflow: auto;
			justify-content: flex-start;
			padding: 0;
			flex-grow: 1;
		}
	}
}
