.logo {
	.line {
		height: 1px;
		background-color: var(--color--content--foreground-10);
	}
	img {
		width: auto;
		height: 30px;
		padding: 0 10px;
		&.grayscale {
			filter: grayscale(1);
			opacity: 0.4;
		}
	}
}

.languageSwitcher {
	&.top-right {
		position: absolute;
		right: 30px;
		top: 30px;

		@include media-breakpoint-down(sm) {
			top: 15px;
		}
	}

	&.auth {
		select {
			border-color: var(--color--content--foreground-50);
			color: var(--color--content--foreground-50);
		}
	}

	select {
		background-color: transparent;
		border-radius: 20px;
		border-width: 1px;
		border-style: solid;
		border-color: inherit;
		color: inherit;
		padding: 5px 7px;
		font-size: 14px;
		outline: 0;
		text-transform: uppercase;
		cursor: pointer;

		&:hover,
		&:focus {
			border: 1px solid var(--color--interactive--background);
			color: var(--color--interactive--background);
		}
	}
}

.integrations {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 2rem 1rem 0 1rem;
	overflow: auto;

	.content {
		max-width: 360px;
		margin: 0 auto;
	}

	h2 {
		font-size: 1.2rem;
		text-align: center;
		margin-bottom: 1.4rem;
	}

	/* RESET DEFAULTS: START */
	input {
		height: auto;
		padding: 0;
		margin: 0;
		border-radius: 0;
		color: initial;
		border: none;
		width: auto;
		overflow: visible;
	}
	/* RESET DEFAULTS: END */

	input[type='text'],
	input[type='password'],
	input[type='tel'] {
		width: 100%;
		border: 1px solid #888;
		height: 30px;
		padding: 0 10px;
		border-radius: 30px;
		margin-bottom: 1.2rem;
		font-size: 0.8rem;
		color: #a7a7a7;
		text-align: center;
		opacity: 1;
		appearance: none;
		outline: 0;
	}

	.PhoneInputCountry {
		border: 1px solid #888;
		height: 30px;
		padding: 0 10px;
		border-radius: 30px;
		font-size: 0.8rem;
	}

	.field {
		&.valid {
			position: relative;
			&::before {
				content: '';
				background-color: #43c268;
				width: 32px;
				height: 32px;
				border-radius: 32px;
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(50%, -50%);
				border: 3px solid var(--color--content--background);
			}
			&::after {
				content: '\f00c';
				font-size: 1rem;
				font-family: FontAwesome;
				top: 0;
				right: 0;
				transform: translate(50%, -50%);
				position: absolute;
				color: #fff;
			}
			input {
				color: #43c268;
				-webkit-text-fill-color: #43c268; // sass-lint:disable-line no-vendor-prefixes
				border-color: #43c268;
			}
		}
		&.invalid {
			position: relative;
			&::before {
				content: '';
				background-color: #e00101;
				width: 32px;
				height: 32px;
				border-radius: 32px;
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(50%, -50%);
				border: 3px solid var(--color--content--background);
			}
			&::after {
				content: '\f00d';
				font-size: 1rem;
				font-family: FontAwesome;
				top: -1px;
				right: 0;
				transform: translate(50%, -50%);
				position: absolute;
				color: #fff;
			}
			input {
				color: #e00101;
				-webkit-text-fill-color: #e00101; // sass-lint:disable-line no-vendor-prefixes
				border-color: #e00101;
				margin-bottom: 0.4rem;
			}
			&.empty {
				input {
					margin-bottom: 1.2rem;
				}
			}
		}
	}

	.error {
		color: #e00101;
		font-size: 0.8rem;
		margin-bottom: 1.2rem;
		text-align: center;
	}

	.btn__basic {
		height: 30px;
		margin-bottom: 1.2rem;

		span {
			font-size: 0.8rem;
			font-weight: 600;
			display: block;
		}
	}

	.or {
		position: relative;
		padding: 1.8rem 0;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			background: var(--color--content--foreground-10);
			height: 1px;
		}
		&::after {
			content: attr(data-text);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background-color: var(--color--content--background);
			padding: 5px 10px;
			font-size: 0.8rem;
			color: var(--color--content--foreground-30);
		}
	}

	a {
		color: var(--color--interactive--background);
		font-size: 0.8rem;
		text-align: center;
		margin-bottom: 0.3rem;
		&:hover {
			text-decoration: underline;
		}
		&.anchor--reset {
			display: block;
		}
	}

	p {
		font-size: 0.8rem;
		color: var(--color--content--foreground-50);
		text-align: center;
		line-height: 1.4;
		margin-bottom: 1.4rem;

		span {
			color: var(--color--content--foreground);
		}

		&.agreement {
			display: inline;
			margin-bottom: 0;
			margin-left: 0.3rem;
		}
	}

	.markdown {
		p {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	&--oauth {
		.integration {
			button {
				width: 100%;
				height: 30px;
				padding: 0 10px;
				border-radius: 30px;
				margin-bottom: 0.6rem;
				color: #fff;
				border: 0;
				font-size: 0.8rem;
				font-weight: 600;
				cursor: pointer;
			}
			&__oauth {
				&--facebook button {
					background-color: #3b5998;
				}
				&--google button {
					background-color: #cf4332;
				}
				&--generic button {
					background-color: #3a3f42;
				}
				&--linkedIn button {
					background-color: #1a81b9;
				}
			}
		}
	}
}

.infoLayer {
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
	background-color: var(--color--content--background);
}
