// search input

.filterFiled {
	position: relative;
	overflow: hidden;
	height: 26px;
	font-size: 12px;
	padding-left: 10px;
	&--separator {
		border-left: 1px solid var(--color--content--foreground-10);
		//LIGHTEN: border-left: 1px solid lighten(var(--color--content--foreground-10), 50%);
		margin-left: 20px;
	}
	.description {
		margin-right: 20px;
		font-weight: 600;
	}
	input,
	select {
		height: 26px;
		width: 135px;
		transition: all 0.2s ease-in;
		color: var(--color--content--foreground-30);
		border-radius: 13px;
		border: 1px solid rgb(244, 244, 244);
		line-height: 24;
		font-weight: 400;
		padding: 0 0 0 14px;
		background-color: transparent;
		position: relative;
		&:hover,
		&:focus {
			outline: 0;
			font-weight: 400;
			color: var(--color--interactive--background);
			border: 1px solid var(--color--interactive--background);
			+ .filterFiled__icon::after {
				color: #fff;
				background: linear-gradient(to bottom, #08c74b 0%, #05a627 100%);
			}
		}
	}
	input {
		padding-right: 30px;
	}
	.filterFiled__icon {
		position: absolute;
		right: -1px;
		top: -1px;
		z-index: 100;
		cursor: pointer;
		&::after {
			font-family: FontAwesome;
			position: absolute;
			color: var(--color--content--foreground-60);
			top: 2px;
			right: 2px;
			border-radius: 50%;
			width: 24px;
			height: 24px;
			line-height: 26;
			text-align: center;
			transition: all 0.2s ease-in;
		}
	}
	&__search .filterFiled__icon::after {
		content: '\f002';
	}
	@include M {
		height: 20px;
		&:first-child {
			padding-left: 0;
		}
		&--separator {
			border-left: none;
			margin-left: 0;
		}
		.description {
			display: none;
		}
		input,
		select {
			width: 20px;
			height: 20px;
			color: var(--color--interactive--background);
			padding: 0;
			border: 1px solid rgba(244, 244, 244, 0);
			z-index: 300;
			font-size: 0;
			&:hover,
			&:focus {
				font-size: 12px;
				color: var(--color--interactive--background);
				border: 1px solid var(--color--interactive--background);
				width: 135px;
				padding: 2px 0 0 14px;
				+ .filterFiled__icon::after {
					color: var(--color--interactive--background);
					background: none;
					font-size: 12px;
				}
			}
		}
		select {
			line-height: 12;
			+ .filterField {
				top: -3px;
			}
		}
		.filterFiled__icon {
			top: -5px;
			&::after {
				color: var(--color--interactive--background);
				font-size: 19px;
			}
		}
	}
}
