// submissionList

.view__challengeDetails {
	.challengeDetails {
		.challengeDetails__contentSection .submissionAwards {
			@include M {
				padding: 30px;
			}
		}
	}
}
