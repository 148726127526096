// profilePreview

.profile {
	color: var(--color--content--foreground-60);
	.standardPreview__mainWrapper {
		height: 100%;
		@include hM {
			overflow: auto;
		}
	}
	&__teamSection {
		@include minLaptop {
			max-width: 122px;
		}
	}
	&__avatarSection {
		padding-bottom: 30px;
		text-align: right;
		color: var(--color--content--foreground);
		font-weight: 400;
		@include minLaptop {
			.avatar + div {
				@include ms {
					width: 100%;
				}
			}
		}
		.teamName {
			color: var(--color--interactive--background);
			margin-top: 12px;
			width: 100%;
			overflow-wrap: break-word;
		}
		.image {
			width: 52px;
			height: 52px;
			background-color: var(--color--interactive--background);
			&.teamIcon {
				background-size: 60%;
			}
		}
		p {
			margin-bottom: 5px;
		}
	}
	.listWrapper {
		width: 100%;
	}
	&__descriptionSection {
		padding-top: 30px;
		.borderedWrapper {
			padding-top: 30px;
			border-top: 1px solid var(--color--content--foreground-10);
		}
	}
	.levelBox {
		background-color: var(--color--content--foreground-5);
		border-radius: 10px;
		padding: 20px;
		text-align: center;
		margin-bottom: 30px;
		.progressBar {
			margin: 6px 0;
			border: 1px solid var(--color--content--foreground-10);
		}
		p {
			font-size: 12px;
			&:first-of-type {
				color: var(--color--content--foreground-80);
				font-weight: 400;
				font-size: 18px;
			}
		}
	}
	.standardList {
		padding: 0 30px 80px 10px;
		.standardListRow {
			cursor: default;
		}
	}
	.currenciesWrapper {
		margin: 0 0 30px 0;
		.currencyContainer {
			margin-bottom: 5px;
		}
		span {
			color: var(--color--content--foreground);
			font-size: 18px;
			font-weight: 600;
			padding-left: 2px;
			position: relative;
			top: 1px;
		}
	}
	.statsTile__wrapper {
		padding-left: 20px;
		@include M {
			overflow: visible;
			justify-content: center;
		}
	}
	.technicalHeader {
		padding: 5px 0;
		input {
			text-align: center;
		}
		h1 {
			margin-right: 0;
			@include minLaptop {
				padding: 0 25px;
			}
		}
		.btn__basic {
			margin-left: 30px;
			transition: all 0.05s ease-out;
			&.loading {
				padding-right: 30px;
			}
		}
	}
	@include M {
		.returnArrow__wrapper {
			width: 55px;
		}
		.technicalHeader {
			padding: 0;
			border-left: 1px solid var(--color--primary--foreground-10);
			h1 {
				margin: 0;
				border-left: 0;
				padding-right: 0;
				padding-left: 20px;
			}
			.autoScrollingText {
				border-left: 0;
			}
			.btn__basic {
				position: absolute;
				top: 49px;
				right: 27px;
				z-index: 900;
			}
			input {
				max-width: calc(100vw - 105px);
				text-align: left;
			}
		}
		&__contentSection {
			flex-shrink: 0;
			.contentSection__header {
				overflow: hidden;
			}
			.emptyList {
				padding: 30px;
			}
		}
		&__descriptionSection {
			background-color: var(--color--content--foreground-03);
		}
		&__details > div:first-child {
			border-top: 1px solid var(--color--content--foreground-10);
		}
		.markdown {
			margin-bottom: 0;
			padding-bottom: 0;
		}
		.standardPreview__mainWrapper {
			overflow: auto;
		}
	}
	@include S {
		.technicalHeader {
			h1 {
				input {
					font-size: 20px;
				}
			}
		}
	}
}
