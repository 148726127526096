.ranking-entry {
	position: relative;
	background-color: transparent;
	border: {
		top: 1px solid transparent;
		left: 1px solid transparent;
		right: 1px solid transparent;
		bottom: 1px solid var(--color--content--foreground-5);
	}

	.entry-position {
	}
	.entry-avatar {
		$avatar-size: 60px;

		.avatar {
			width: $avatar-size;
			height: $avatar-size;
			background: {
				color: var(--color--interactive--background);
				repeat: no-repeat;
				position: center;
				size: cover;
			}
		}

		.user-level {
			$size: 30px;

			width: $size;
			height: $size;
			right: -($size / 2);
			line-height: $size;
			text-align: center;
			bottom: 0;
			font-weight: $font-weight-bold;
			background: {
				color: var(--color--primary--background);
			}
			color: var(--color--primary--foreground);
		}

		.team-avatar {
			$size: 30px;

			width: $size;
			height: $size;
			left: -($size / 2);
			bottom: 0;
			background: {
				color: var(--color--interactive--background);
				repeat: no-repeat;
				position: center;
				size: cover;
			}

			@include media-breakpoint-down(md) {
				$size: 30px;

				width: $size;
				height: $size;
				left: -($size / 2);
			}
		}

		.container-avatar {
			width: $avatar-size;
			height: $avatar-size;
			line-height: $avatar-size;
			background-color: var(--color--interactive--background);
			color: var(--color--interactive--foreground);
			font-size: 1.4em;
			font-weight: $font-weight-bold;
		}
	}
	.entry-fullName {
		color: var(--color--interactive--background);
	}
	.entry-shortName {
		color: var(--color--content--foreground-50);
	}
	.entry-scores {
		background-color: transparent;

		.icon {
			max-width: 30px;
		}
	}

	@include hover() {
		background-color: var(--color--content--background);
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $row-box-shadow;
		border-radius: $border-radius;

		.entry-fullName {
			color: var(--color--content--foreground);
		}

		.entry-scores {
			background-color: var(--color--content--foreground-5);
		}
	}
}
