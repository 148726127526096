// Task details

.taskDetails {
	.standardPreview {
		.currencyWrapper {
			.currencyContainer {
				margin-left: 0.3rem;
				span {
					color: var(--color--primary--foreground);
					font-size: 1.2rem;
				}
			}
		}
		&__completedStatus {
			h1 {
				font-size: 26px;
				margin: 35px 20px 55px 20px;
				text-align: center;
			}
			.roundVisualStatus {
				width: 115px;
				height: 115px;
			}
		}
		&__footer {
			height: 76px;
			background-color: $taskPreviewFooter;
			padding: 20px 30px;
			.btn__basic {
				min-width: 0;
				&.loading {
					padding-right: 30px;
				}
			}
			.answerStatusWrapper {
				display: flex;
			}
			.statusDescription {
				color: rgb(51, 51, 51);
				padding-left: 10px;
				h2 {
					font-size: 20px;
				}
				p {
					font-size: 18px;
					font-weight: 100;
					margin-top: 2px;
					span {
						color: rgba(51, 51, 51, 0.7);
						margin-right: 7px;
					}
				}
			}
			.footer__interactiveSection {
				white-space: nowrap;
			}
			.exitButton {
				cursor: pointer;
			}

			&.correct {
				background-color: $footerCorrectBgr;
				.answerStatusWrapper__correct {
					display: flex;
				}
			}

			&.incorrect {
				background-color: $footerIncorrectBgr;
				.answerStatusWrapper__incorrect {
					display: flex;
				}
			}

			&.awaiting {
				background-color: #f7f4d6;
			}

			&.rejected {
				background-color: $footerIncorrectBgr;
			}
		}

		@include M {
			&__mainWrapper,
			&__footer {
				&.correct,
				&.incorrect,
				&.awaiting,
				&.rejected {
					display: none;
				}
			}
		}

		.answer {
			&.correct,
			&.incorrect {
				label {
					pointer-events: none;
				}
				&:hover .technicalInput ~ .answer__selectArea {
					color: #fff;
				}
				.technicalInput {
					& ~ .answer__selectArea {
						.customInput {
							display: none;
						}
						.iterator {
							display: block;
						}
						&--textType .iterator {
							color: #fff;
						}
						p {
							display: block;
						}
					}
					&:checked {
						& ~ .answer__selectArea {
							.iterator {
								color: #fff;
							}
							&--imageType {
								background-color: var(--color--content--foreground-30);
							}
						}
					}
				}

				&.pencil .technicalInput ~ .answer__selectArea .iterator {
					display: none;
				}
			}
			&.correct {
				.technicalInput {
					&:checked {
						& ~ .answer__selectArea {
							.iterator {
								color: #fff;
							}
							&--imageType {
								background-color: $answerCorrectDark;
							}
						}
					}
				}
			}
			&.incorrect {
				.technicalInput .customInput .checked {
					&::before,
					&::after {
						background-color: $answerIncorrectDark;
					}
				}
			}
		}

		.taskInstructions {
			font-size: 18px;
			text-align: center;
			margin-bottom: 20px;
			width: 100%;
			@include M {
				font-size: 16px;
			}
		}
	}
	.watchingProgressStatus {
		font-size: 20px;
		span {
			font-size: 18px;
			color: var(--color--content--foreground-60);
		}
	}
	.progressBarWrapper {
		margin-right: 30px;
		.progressBar {
			height: 14px;
			&__content {
				height: 10px;
			}
			&__markersWrapper {
				position: relative;
			}
			&__marker {
				position: absolute;
				border-left: 1px solid var(--color--content--foreground-60);
				padding-left: 8px;
				top: -2px;
				padding-top: 8px;
			}
		}
		.currencyContainer span {
			font-size: 18px;
		}
	}
	.realTimeNotification--task {
		display: none;
	}
	.standardPreview__column {
		&--left {
			width: 27vw;
			@include minLaptop {
				max-width: 600px;
			}
			img {
				width: 100%;
				border-radius: 10px;
			}
			.matchingImageType {
				flex-shrink: 0;
			}
		}
		&--middle {
			padding: 20px 60px;
			width: 100%;
			border-right: none;
			display: block;
		}
		.roundVisualStatus {
			z-index: 300;
		}
		&.documentTask--rightColumn {
			padding-bottom: 0;
			padding-right: 0;
			@include M {
				flex-grow: 1;
				min-height: 50vh;
			}
			> div {
				border-radius: 10px;
			}
			.btn__basic {
				margin-top: 20px;
				@include M {
					margin: 10px 30px;
				}
			}
		}
	}
	.noInstructionQuestion {
		@include minLaptop {
			padding-top: 0;
			margin-top: 20px;
		}
	}
	.reverseLayout {
		flex-direction: row-reverse;
		align-self: center;
		.editableTextField {
			padding: 10px;
		}
		> .standardPreview__column {
			width: 100%;
			flex: 1;
			padding: 20px 0 0 0;
		}
		.standardPreview__column--left {
			margin-left: 40px;
		}
		.standardPreview__column--middle {
			border: none;
		}
		@include M {
			padding: 30px;
			flex-direction: column;
			> .standardPreview__column {
				flex: none;
			}
			.standardPreview__column--left {
				margin-left: 0;
			}
		}
	}
	.imageQuestions {
		&.standardPreview__column--middle {
			padding: 20px 0 20px 60px;
			@include XL {
				padding-left: 40px;
			}
		}
		.answerList {
			justify-content: flex-start;
			flex-direction: row;
			align-content: flex-start;
			width: 100%;
		}
		.imageWrapper {
			width: 100%;
			height: 100%;
			border-radius: 10px;
			overflow: hidden;
			position: relative;
		}
		.answer {
			margin-bottom: 25px;
			overflow: visible;
			&__selectArea {
				border-radius: 10px;
				border: 1px solid var(--color--content--foreground-30);
				bottom: -20px;
				&::after {
					content: none;
				}
			}
		}
	}
	&--repetition {
		.repetitionStatus {
			padding: 20px 30px;
			font-size: 16px;
		}
		.progressBarWrapper {
			margin: 5px 0;
		}
		.progressBar__status {
			font-weight: 400;
			margin-top: 5px;
		}
	}
	.task {
		&__order {
			.answerList {
				user-select: none;
			}
		}
	}
	@include XXL {
		.answer__imageType {
			width: 31%;
			height: 11.6vw;
		}
	}
	@include XL {
		.answer__imageType {
			width: 47%;
			height: 16.6vw;
		}
		.standardPreview {
			&__column {
				padding-right: 40px;
			}
			&__column--middle {
				padding: 0 20px 0 40px;
			}
		}
	}
}
