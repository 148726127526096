// unsupportedBrowser

.unsupportedBrowser {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	z-index: 1000;
	background-color: #fff;
	font-family: current-font;
	.unsupportedBrowser__container {
		margin: 0 auto;
		font-size: 18px;
		color: rgb(50, 50, 50);
		text-align: center;
		padding-top: 50px;
		@media all and (max-width: 760px) {
			width: 100%;
			padding: 30px 30px;
		}
		h2 {
			margin: 40px 0;
			font-size: 1.6em;
		}
		h4 {
			font-size: 1em;
			font-weight: 100;
			padding-top: 5px;
		}
		p {
			font-size: 0.8em;
			opacity: 0.6;
			padding-top: 10px;
		}
		hr {
			margin: 40px 0;
			border: none;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
		hr.lackOfTopMargin {
			margin-top: 0;
		}
	}
	.logos {
		font-size: inherit;
		.logoWrapper {
			display: inline-block;
			vertical-align: middle;
			margin: 0 20px 40px 20px;
			color: rgb(7, 191, 67);
			a {
				text-decoration: none;
			}
			img {
				width: 64px;
				outline: 0;
				border: 0;
				margin-bottom: 10px;
			}
			.text {
				color: #07bf43;
			}
			.download {
				font-size: 0.7em;
				color: rgba(50, 50, 50, 0.8);
			}
		}
	}
}
