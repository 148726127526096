.view__home {
	height: 100%;
	padding: 0;
	background-color: rgba(0, 0, 0, 0.1);
	> div {
		font-size: 0;
	}
	.graph {
		overflow: hidden;
	}
	.resizers__wrapper {
		position: absolute;
		right: 30px;
		bottom: 36px;
		padding: 12px;
		border-radius: 36px;
		background-color: var(--color--interactive--background);
		box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.15) inset;
		@include minDesktop {
			bottom: 40px;
			right: 50px;
		}
		.plus,
		.minus {
			position: relative;
			width: 9px;
			height: 9px;
			transition: all 0.1s ease-in;
			cursor: pointer;
			&::after,
			&::before {
				content: '';
				position: absolute;
				background-color: var(--color--interactive--foreground);
				width: 100%;
				height: 2px;
			}
			&::after {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			&:hover {
				transform: scale(1.4);
			}
		}
		.plus {
			margin-right: 10px;
			&::before {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(90deg);
			}
		}
		.minus::before {
			width: 0;
		}
		.image {
			width: 40px;
			height: 40px;
			cursor: pointer;
			transition: transform 0.1s ease-in;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}
