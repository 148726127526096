// popup

.popup {
	&__layer {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: var(--color--content--foreground-60);
		z-index: 1080;
	}

	&__background {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		z-index: 1100;
		width: 100%;
		max-width: 500px;
		transform: translate(-50%);
		border-radius: 10px;
		margin: 2rem 0;
		background-color: var(--color--content--background);
		overflow: auto;

		@include media-breakpoint-down(xxs) {
			width: auto;
			transform: none;
			left: 1rem;
			right: 1rem;
		}
	}

	&__content {
		text-transform: none;
		letter-spacing: normal;
		padding: 1rem;
		.integrations {
			position: relative;
		}
	}
}
