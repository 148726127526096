// responsive font sizes:

$base-font-size: 1rem !default;

$font-sizes: (
	1: (
		$spacer * 0.25,
	),
	2: (
		$spacer * 0.5,
	),
	3: $spacer * 0.75,
	4: (
		$spacer,
	),
	5: (
		$spacer * 1.25,
	),
	6: (
		$spacer * 1.5,
	),
	7: (
		$spacer * 2,
	),
	8: (
		$spacer * 3,
	),
) !default;

$font-size-mobile: $base-font-size * 0.875;

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$breakpoint-infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@each $size in map-keys($font-sizes) {
			$infix: map-get($font-sizes, $size);

			.fs#{$breakpoint-infix}-#{$size} {
				font-size: #{$infix} !important;
			}
		}

		.fs#{$breakpoint-infix}-mobile {
			font-size: $font-size-mobile !important;
		}
	}
}

// font colors:

.color-content {
	color: var(--color--content--foreground-60) !important;
}
