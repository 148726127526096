.view__trueFalse {
	.answer {
		border-radius: 7px;
		margin-bottom: 5px;
		.body {
			align-content: space-between;
			min-height: 60px;
			padding: 5px;
		}
		&:not(:last-of-type) {
			.body {
				border-bottom: 1px solid var(--color--content--foreground-5);
			}
		}
		p {
			flex-grow: 1;
			font-weight: 600;
			padding: 10px 20px;
			font-size: 16px;
			line-height: 1.2;
			color: var(--color-content-foreground);
		}
		div.buttons {
			display: flex;
			align-self: stretch;
			button {
				min-width: 80px;
				border: 0;
				outline: 0;
				background-color: transparent;
				color: var(--color-content-foreground);
				cursor: pointer;
				font-size: 16px;
				transition: 0.3s;
				&:nth-of-type(1) {
					@include core-border-radius(7px, top-left, bottom-left);
				}
				&:nth-of-type(2) {
					@include core-border-radius(7px, top-right, bottom-right);
				}
			}
			&--happySad {
				button {
					font-size: 20px;
				}
			}
			&--plusMinus {
				button {
					font-size: 20px;
				}
			}
		}
		&:hover,
		&.true--checked,
		&.false--checked {
			background-color: var(--color--content--foreground-10);
		}
		&:not(.checked):hover div.buttons button {
			background-color: var(--color--content--background);
			&:hover {
				background-color: var(--color--interactive--background-40);
				color: var(--color--content--foreground);
			}
		}
		$options: true false;
		@for $index from 1 through length($options) {
			$option: nth($options, $index);
			&.#{$option}--checked div.buttons {
				& > button:nth-of-type(#{$index}) {
					background-color: var(--color--interactive--background);
					color: var(--color--interactive--foreground);
				}
				& > button:nth-of-type(#{$index + if($option, 1, -1)}) {
					background-color: var(--color--content--background);
					&:hover {
						background-color: var(--color--interactive--background-40);
						color: var(--color--content--foreground);
					}
				}
			}
		}
	}
}
