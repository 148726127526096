.view__submissionDetails {
	.info {
		font-size: 18px;
		color: var(--color--content--foreground-30);

		.author {
			&__name a {
				color: var(--color--interactive--background);
			}
		}
		.date {
			&__value {
				color: var(--color--content--foreground-60);
			}
		}
	}

	.content {
		font-size: 16px;
		color: var(--color--content--foreground-60);
	}
}
