// declarationTask

.declarationTask {
	position: relative;
	padding: 0 70px 0 70px;
	height: auto;
	&__contentWrapper {
		width: auto;
		margin-top: 30px;
		&.hasImage {
			justify-content: flex-start;
		}
		.matchingImageType {
			margin-right: 40px;
			@include minLaptop {
				@include XL {
					min-width: 30vw;
				}
			}
			@include M {
				margin: 0 0 30px 0;
			}
		}
	}
	&__textWrapper {
		padding-top: 0;
		width: 100%;
		span {
			display: inline-block;
		}
	}
	&__checkboxListWrapper {
		min-width: 535px;
	}
	&__imageWrapper {
		margin: 0 150px 0 0;
		min-width: 200px;
		max-width: 700px;
		flex: 10;
	}
	.answer {
		&__selectArea .customInput {
			cursor: default;
			display: block;
		}
		&__textType {
			height: auto;
			min-height: 60px;
		}
		&__contentArea {
			padding: 7px 30px;
			.standardText.loading {
				transition: all 0.2s ease-in;
				position: relative;
				&::before {
					font-family: FontAwesome;
					position: absolute;
					content: '\f110';
					top: calc(50% - 0.5em);
					right: 0;
					animation: rotate 1s infinite ease-in;
					z-index: 200;
					font-size: 1em;
				}
			}
		}
		.standardText {
			font-size: 18px;
			font-weight: 400;
			color: var(--color--interactive--background);
			@include ms--widthAuto;
		}
		.technicalInput {
			cursor: pointer;
		}
		.technicalInput:checked {
			& ~ .answer {
				&__selectArea {
					background-color: $answerCorrectDark;
					&::after {
						content: none;
					}
				}
				&__contentArea {
					background-color: $answerCorrect;
					.standardText {
						color: #fff;
					}
				}
			}
		}
	}
	@include minLaptop {
		@include XL {
			padding: 0 20px 25px 20px;
			&__contentWrapper {
				display: block;
			}
		}
		@include XXL {
			&__imageWrapper {
				margin-right: 70px;
			}
		}
	}
	@include L {
		&__contentWrapper {
			flex-direction: column;
		}
		&__checkboxListWrapper {
			width: 100%;
			min-width: 0;
		}
		.matchingImageType {
			margin: 0 0 30px 0;
		}
	}
	@include M {
		padding: 20px 30px 40px 30px;
		.matchingImageType {
			margin-bottom: 20px;
			img {
				height: 100%;
			}
		}
		&__contentWrapper {
			margin-top: 0;
		}
		.standardText {
			&.visible--mobile {
				display: block;
				margin-bottom: 40px;
				text-align: center;
			}
		}
		.answer {
			&__selectArea {
				width: 56px;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
			&__contentArea {
				padding: 7px 22px;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
}
