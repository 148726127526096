.achievementList {
	&__information {
		min-width: auto;
		box-shadow: 0 0 15px 0 var(--color--content--foreground-10);
		background-color: var(--color--content--background);
		border-right: none;
		line-height: 1.2;
		margin-left: -$spacer;
		margin-right: -$spacer;

		p {
			color: var(--color--content--foreground);
		}

		@include media-breakpoint-up(sm) {
			border-right: 1px solid var(--color--content--foreground-10);
			background-color: transparent;
			box-shadow: none;
			min-width: 300px;
			max-width: 300px;
			margin-left: 0;
			margin-right: $spacer * 0.5;
		}
	}

	&__list {
		.status {
			display: block;
			background-color: #63bf00;
			color: #fff;
			width: 1.5rem;
			height: 1.5rem;
			position: absolute;
			top: -0.75rem;
			right: -0.75rem;
			border-radius: 1.5rem;
			font-size: 1rem;

			@include media-breakpoint-up(sm) {
				width: 2rem;
				height: 2rem;
				font-size: 1.3rem;
			}
		}
	}

	&__tile {
		border: 1px solid var(--color--content--foreground-5);
		box-shadow: $tile-box-shadow;

		.tile__front {
			.tile__image {
				height: auto;
				padding-top: 100%;
			}

			p {
				color: var(--color--interactive--background);
				//background-color: var(--color--content--foreground-5);
				border-top: 1px solid var(--color--content--foreground-5);
				box-shadow: inset 0 17px 29px -27px rgba(0, 0, 0, 0.1);
			}
		}

		.tile__back {
			& > div {
				p {
					color: var(--color--content--foreground-60);
				}
			}
		}

		&.notAchieved {
			.tile__front {
				p {
					color: var(--color--content--foreground-30);
				}

				.tile__image {
					opacity: 0.4;
					filter: grayscale(1);
				}
			}
			.tile__back {
				opacity: 0.6;
			}
		}
	}
}
