.submissionList {
	&__item {
		font-size: 1rem;
		position: relative;

		.status__tooltip {
			$tooltip-border-color: #ededed;
			opacity: 1 !important;
			background-color: var(--color--content--background);
			color: var(--color--content--foreground-60);
			padding: 0.5rem 0.7rem;
			box-shadow: 9px 9px 7px -10px rgba(0, 0, 0, 0.59);
			max-width: 200px;
			font-size: 0.9rem;

			&.border {
				border: 1px solid $tooltip-border-color !important;
			}

			//TODO: place-top, place-bottom - create mixin for generate it.
			&.place-right {
				&::before {
					// arrow border
					border-right: 8px solid $tooltip-border-color !important;
				}
				&::after {
					// arrow background
					border-right: 6px solid var(--color--content--background) !important;
				}
			}

			&.place-left {
				&::before {
					// arrow border
					border-left: 8px solid $tooltip-border-color !important;
				}
				&::after {
					// arrow background
					border-left: 6px solid var(--color--content--background) !important;
				}
			}
		}

		.item__status {
			$status-size: 1.5em;

			position: absolute;
			top: -$status-size/2;
			right: -$status-size/2;
			width: $status-size;
			height: $status-size;
			color: #fff;
			font-size: 1.1em;
			display: flex;
			align-items: center;
			justify-content: center;

			&.accepted {
				background-color: #34c05d;

				.fa::before {
					content: '\f00c'; // fa-tick
				}
			}
			&.new {
				background-color: #fecb00;

				.fa::before {
					content: '\f017'; // fa-clock-o
				}
			}
			&.rejected {
				background-color: #e30000;

				.fa::before {
					content: '\f00d'; // fa-times
				}
			}
		}

		.item__front {
			border: 1px solid var(--color--content--foreground-10);
			font-size: 0.9em;
		}

		.item__text {
			background-color: var(--color--interactive--background);
			color: var(--color--interactive--foreground);
			padding: 0.7em;
		}

		.item__image {
			width: 100%;
			max-width: 100%;
		}

		.item__title {
			background-color: var(--color--content--background);
			padding: 0.7em;

			.judgement {
				color: var(--color--content--foreground-30);
				margin-bottom: 0.5em;

				i {
					margin-right: 0.2em;
				}
			}
			p.title {
				color: var(--color--interactive--background);
			}
			p.author {
				color: var(--color--content--foreground-60);
			}

			p.title + p.author {
				margin-top: 0.5em;
			}
		}
	}
}
