// close

.close {
	position: relative;
	width: 40px;
	height: 40px;
	z-index: 200;
	&::before,
	&::after {
		position: absolute;
		content: '';
		width: 20px;
		height: 3px;
		border-radius: 3px;
		transition: all 0.15s ease-in;
		left: 50%;
		top: 50%;
		background-color: var(--color--primary--foreground);
	}
	&::before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
	&::after {
		transform: translate(-50%, -50%) rotate(45deg);
	}
}
