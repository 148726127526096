// visual status

.taskVisualStatus {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	position: absolute;
	&--topRight {
		top: 0;
		right: 0;
	}
	&::after {
		font-family: FontAwesome;
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -45%);
		font-size: 16px;
	}
}
