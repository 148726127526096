.modal {
	&__backdrop {
		background-color: var(--color--content--foreground-60);
		z-index: $zindex-modal-backdrop;
	}

	&__content {
		font-size: 14px;
		max-width: 600px;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);

		@include media-breakpoint-down(sm) {
			left: 1rem;
			right: 1rem;
			max-width: initial;
			width: auto;
			transform: none;
		}

		section {
			background-color: var(--color--content--background);
			color: var(--color--content--foreground-80);

			& > div {
				background-color: var(--color--content--foreground-5);
			}
		}
	}
}
