// forms

form {
	width: auto;
	label {
		position: relative;
		display: block;
		width: 100%;
		color: var(--color--content--foreground);
		* {
			transition: all 0.15s ease-in;
		}
		&.ok {
			input,
			textarea,
			select {
				border: 1px solid var(--color--interactive--background);
				color: var(--color--interactive--background);
			}
			.roundVisualStatus {
				@include roundVisualStatus__correct;
			}
		}
		&.error {
			input,
			textarea,
			select {
				color: $error;
				border: 1px solid $error;
			}
			.errorMessage {
				display: block;
			}
			.roundVisualStatus {
				@include roundVisualStatus__incorrect--mini;
				.roundVisualStatus__signWrapper {
					&::after {
						font-size: 20px;
						left: 0;
						top: -4px;
					}
				}
			}
		}
		.roundVisualStatus {
			width: 25px;
			height: 25px;
			position: absolute;
			right: -16px;
			z-index: 300;
			top: -10px;
			border: 3px solid #fff;
			box-sizing: content-box;
		}
	}
	.successMessage {
		color: var(--color--interactive--background);
		padding: 10px 0;
	}
	.errorMessage {
		display: none;
		color: $error;
		padding: 10px 0;
		text-align: center;
		width: 100%;
	}
	input,
	textarea,
	select {
		color: #a9a9a9;
		border: 1px solid $formsBorders;
		padding: 12px 15px;
		width: 100%;
		margin-bottom: 10px;
		&:focus {
			color: #333;
			border: 1px solid #888;
			outline: 0;
			&::placeholder {
				color: transparent;
			}
		}
	}
	input,
	select {
		height: 31px;
		padding-right: 20px;
		border-radius: 16px;
	}
	textarea {
		border-radius: 10px;
		resize: none;
	}
	select {
		padding: 0 30px 0 15px;
		background-color: transparent;
		color: var(--color--content--foreground);
		appearance: none;
		position: relative;
		z-index: 200;
		margin-bottom: 25px;
		&:hover {
			+ .innerIcon {
				background-color: rgba(0, 0, 0, 0.65);
				&::after,
				&::before {
					background-color: #fff;
				}
			}
		}
		&:active + .innerIcon {
			&--dropdown {
				background-color: rgba(0, 0, 0, 0.65);
				&::after,
				&::before {
					background-color: #fff;
					transition: all 0.2s ease-in;
				}
				&::after {
					transform: translate(120%, -50%) rotate(-45deg);
				}
				&::before {
					transform: translate(-168%, -46%) rotate(45deg);
				}
			}
		}
		+ .innerIcon {
			z-index: 100;
		}
	}
	.innerIcon {
		position: absolute;
		width: 23px;
		height: 23px;
		border-radius: 50%;
		top: 4px;
		right: 5px;
		background-color: $standardBgr;
		.info {
			display: none;
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translate(20px, -50%);
			width: 260px;
			padding: 15px;
			border: 1px solid $standardBgr;
			border-radius: 10px;
			color: var(--color--content--foreground-60);
			text-align: left;
			&::after,
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: -10px;
				width: 0;
				height: 0;
				transform: translate(0, -50%);
			}
			&::after {
				border: {
					right: 10px solid $standardBgr;
					top: 10px solid transparent;
					bottom: 10px solid transparent;
				}
			}
			&::before {
				z-index: 300;
				left: -9px;
				border: {
					right: 9px solid #fff;
					top: 9px solid transparent;
					bottom: 9px solid transparent;
				}
			}
		}
		&:hover {
			background-color: rgba(0, 0, 0, 0.65);
			&::after {
				color: #fff;
			}
			.info {
				display: block;
			}
		}
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-3px, -7px);
			font-size: 14px;
			font-weight: 600;
		}
		&--info {
			&::after {
				content: '?';
			}
		}
		&--search {
			cursor: pointer;
			&::after {
				font-family: FontAwesome;
				content: '\f002';
				transform: translate(-7px, -8px);
			}
		}
		&--dropdown {
			&::after,
			&::before {
				position: absolute;
				content: '';
				width: 2px;
				height: 9px;
				top: 50%;
				left: 48%;
				background-color: rgba(0, 0, 0, 0.65);
			}
			&::after {
				transform: translate(150%, -50%) rotate(45deg);
			}
			&::before {
				transform: translate(-168%, -46%) rotate(-45deg);
			}
			&:hover {
				&::before,
				&::after {
					background-color: #fff;
				}
			}
		}
	}
}
