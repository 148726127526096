$missionListWidth: 123px;
$headerHeight: 60px;
$footerHeight: 46px;

$headerHeight--desktop: 70px;
$headerHeight--laptop: 58px;
$headerHeight--mobile: 58px;

// Breakpoints - width:
$XS: 380px;
$S: 480px;
$M: 575px;
$L: 768px;
$XL: 992px;
$XXL: 1280px;
$XXXL: 1600px;
$XXXXL: 1920px;

// Breakpoints - height:
$hXS: 480px;
$hS: 640px;
$hM: 768px;
$hL: 900px;
$hXL: 1024px;
$hXXL: 1280px;

// Main colors

$standardBgr: rgb(244, 244, 244);
$standardBgrDark: rgb(232, 232, 232);
$mobileTopGradientBgr: #f4f4f4;

// Visual statuses
$waitingStatus: rgb(254, 203, 0);

// Fonts
$menuFontColor: #fff;
$error: rgb(227, 0, 0);
$errorDark: rgb(229, 4, 4);
$previewHeader: #fff;
$defaultButton: rgb(7, 191, 67);

// Customs

//taskDetails
$taskPreviewFooter: rgb(245, 245, 245);
$answerIncorrect: rgb(239, 53, 53);
$answerIncorrectDark: rgb(223, 0, 0);
$answerCorrect: rgb(8, 199, 75);
$footerCorrectBgr: rgb(219, 246, 199);
$answerCorrectDark: rgb(52, 192, 93);
$phraseInputBgr: rgb(250, 250, 250);
$footerIncorrectBgr: rgb(255, 221, 221);
$yellowStatus: rgb(255, 213, 49);
$yellowStatusDark: rgb(255, 183, 26);
$yellowStatusFooter: rgb(248, 238, 202);

// Forms
$formsBorders: rgb(136, 136, 136);
