// scss/widgets/_languageSwitcher.scss

.languageSwitcher__auth {
	display: flex;
	align-items: center;
	position: absolute;
	top: 20px;
	right: 30px;
	color: var(--color--content--foreground-50);
	z-index: 100;
	@include M {
		position: static;
		top: auto;
		right: auto;
		justify-content: center;
		margin-bottom: 30px;
	}

	p {
		font-size: 16px;
		margin-right: 10px;
		letter-spacing: -0.4px;
	}

	select {
		padding: 5px 10px;
		height: 30px;
		border-radius: 30px;
		text-transform: uppercase;
		background-color: transparent;
		color: inherit;
		outline: 0;
		cursor: pointer;
	}
}

.languageSwitcher__app {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 23px;
	margin-left: 14px;
	position: relative;
	z-index: 100;
	&::after {
		content: '';
		height: 34%;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
		width: 1px;
		border-left: 1px solid rgba(255, 255, 255, 0.1);
	}
	.locale {
		position: relative;
		text-transform: uppercase;
		cursor: pointer;
		color: var(--color--primary--foreground);
		z-index: 600;
		&:hover {
			& > div.current {
				@include minLaptop {
					background-color: var(--color--interactive--background);
				}
				&::after {
					content: '\f106';
				}
			}
			& > div.list {
				display: flex;
			}
		}
		div.current {
			width: 50px;
			padding: 6px 0 4px 10px;
			border-radius: 20px;
			background-color: transparent;
			&::after {
				content: '\f107';
				font-family: FontAwesome;
				position: absolute;
				font-size: 16px;
				right: 10px;
				top: 50%;
				transform: translate(0, -50%);
			}
		}
		div.list {
			display: none;
			flex-direction: column;
			position: absolute;
			width: 100%;
			top: 100%;
			left: 0;
			padding-top: 10px;
			.returnArrow {
				display: none;
			}
			&:hover {
				display: flex;
			}
			ul {
				padding: 5px;
				background-color: var(--color--interactive--background);
				border-radius: 10px;
				&::before {
					content: '';
					border: {
						width: 6px;
						color: transparent;
						style: solid;
					}
					border-top: 0;
					border-bottom-color: var(--color--interactive--background);
					position: absolute;
					top: 1px;
					left: 50%;
					transform: translate(-50%, 50%);
				}
				li {
					padding: 5px;
					&:not(:last-of-type) {
						border-bottom: 1px solid var(--color--primary--foreground-30);
					}
					&:hover {
						color: var(--color--primary--background-70);
					}
				}
			}
		}
	}
	.background {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 500;
	}

	@include M {
		padding-left: 0;
		margin-left: 5px;
		margin-right: 15px;
		&::after {
			content: normal;
		}
		.locale {
			&:hover {
				& > div.current::after {
					content: normal;
				}
				& > div.list {
					display: none;
				}
			}
			div.current {
				width: auto;
				padding: 0 0 4px 10px;
				border-radius: 0;
				background-color: transparent;
				font-size: 14px;
				&::after {
					content: normal;
				}
				&::before {
					content: '\f107';
					font-family: FontAwesome;
					position: absolute;
					font-size: 16px;
					top: 100%;
					transform: translate(20%, -65%);
					color: var(--color--primary--foreground-40);
				}
			}
		}

		&.opened {
			z-index: 300;
			.locale {
				div.list {
					display: flex;
					position: fixed;
					left: auto;
					right: 0;
					top: 0;
					bottom: 0;
					width: 120px;
					background-color: var(--color--primary--background);
					box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.28) inset;
					.returnArrow {
						display: block;
						margin: 10px auto 0 auto;
					}
					ul {
						margin: 20px 0;
						padding: 0;
						background-color: transparent;
						color: var(--color--interactive--foreground);
						&::before {
							content: normal;
						}
						li {
							padding: 25px 5px;
							font-size: 18px;
							text-align: center;
							position: relative;
							&:hover,
							&:active,
							&.active {
								background-color: var(--color--interactive--background);
							}
							&:not(:last-of-type) {
								border-bottom: 0;
								&::after {
									content: '';
									height: 1px;
									background-color: var(--color--primary--foreground-30);
									position: absolute;
									bottom: 0;
									left: 20px;
									right: 20px;
								}
							}
							&:hover {
								color: inherit;
							}
						}
					}
				}
			}
			.background {
				display: block;
			}
		}
	}
}
