// wrapper

.app {
	background-color: var(--color--content--background);
	color: var(--color--content--foreground);
	.missionListTechnical {
		width: 133px;
		@include L {
			display: none;
		}
		+ div {
			width: 100%;
		}
	}
	.middle {
		height: 100%;
		.mainContainer {
			background-color: var(--color--content--background);
		}
	}
	main {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		.main-container {
			position: relative;
			background-color: var(--color--primary--background);
			touch-action: none;
		}
		.mainContentWrapper.mainContentContainer {
			background-color: color-mod(var(--color--content--background) shade(0.1));
			overflow-y: auto;
		}
	}

	.globalBar {
		height: 50px;
		background-color: #8f1c00;
		font-size: 25px;
		padding: 12px;
		color: white;
	}

	.globalBar + main {
		top: 50px;
	}
}
