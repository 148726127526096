.notificationList {
	&__list {
		.notification {
			position: relative;
			background-color: transparent;
			border: {
				top: 1px solid transparent;
				left: 1px solid transparent;
				right: 1px solid transparent;
				bottom: 1px solid var(--color--content--foreground-5);
			}

			&__icon {
				svg,
				img {
					max-width: 30px;
				}
			}
			&__content {
			}
			&__title {
				color: var(--color--interactive--background);
			}
			&__date {
				color: var(--color--content--foreground-50);

				strong {
					color: var(--color--content--foreground-80);
				}
			}
			&__points {
				background-color: transparent;
			}

			@include hover() {
				background-color: var(--color--content--background);
				border: 1px solid var(--color--content--foreground-5);
				box-shadow: $row-box-shadow;
				border-radius: $border-radius;

				.notification__points {
					background-color: var(--color--content--foreground-5);
				}
			}
		}
	}
}
