@mixin hover {
	// https://medium.com/@ferie/detect-a-touch-device-with-only-css-9f8e30fa1134
	@media (hover: hover) and (pointer: fine) {
		@include media-breakpoint-up(sm) {
			&:hover {
				@content;
			}
		}
	}
}
