.general-tooltip {
	display: inline-block;
	position: relative;
	text-align: left;
}

.general-tooltip .tooltip-text--top {
	min-width: 120px;
	top: -10px;
	left: 50%;
	transform: translate(-50%, -100%);
	padding: 10px 20px;
	background-color: var(--color--interactive--background);
	color: var(--color--interactive--foreground);
	font-weight: normal;
	font-size: 13px;
	border-radius: 8px;
	position: absolute;
	z-index: 99999999;
	box-sizing: border-box;
	box-shadow: $tile-box-shadow;
	text-align: center;
	display: none;
}

.general-tooltip:hover .tooltip-text--top {
	display: block;
}

.general-tooltip .tooltip-text--top i {
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -12px;
	width: 24px;
	height: 12px;
	overflow: hidden;
}

.general-tooltip .tooltip-text--top i::after {
	content: '';
	position: absolute;
	width: 12px;
	height: 12px;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	background-color: var(--color--interactive--background);
	box-shadow: $tile-box-shadow;
}
