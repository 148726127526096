// challengeList

.teamProfile .standardList {
	.row__roundNumber {
		background-color: #fff;
		font-size: 2em;
		border-radius: 15px;
		padding: 5px 9px 1px 8px;
		border: 1px solid $standardBgrDark;
		min-width: 30px;
		height: 30px;
		font-weight: 100;
	}
	.standardListRow {
		&:hover {
			.iconWrapper::after {
				border: 3px solid $standardBgr;
			}
			.standardListRow__additionalContent {
				&.challengeVersion {
					background-color: $standardBgrDark;
				}
			}
		}
		.reverse {
			display: none;
		}
		&__mainContent {
			padding: 20px 30px;
			.markedAvatar,
			.avatar {
				width: 30px;
				height: 30px;
				.roundNumber {
					min-width: 17px;
					height: 17px;
					background-color: var(--color--interactive--background);
					left: 100%;
					transform: translate(-10px, 0);
					.number {
						border-radius: 17px;
						min-width: 17px;
						background-color: var(--color--interactive--background);
						color: #fff;
						top: 0;
						font-size: 12px;
					}
				}
			}
			.iconWrapper {
				position: relative;
				margin-right: 60px;
				padding-left: 30px;
				border-left: 1px solid var(--color--content--foreground-10);
				margin-left: 30px;
				.row__icon {
					margin-right: 0;
				}
				&::after {
					position: absolute;
					content: '';
					border-radius: 50%;
					height: 12px;
					width: 12px;
					right: -3px;
					top: -2px;
					border: 3px solid #fff;
					box-sizing: content-box;
				}
				&.finished::after {
					background: linear-gradient(to bottom, rgba(195, 25, 25, 1) 0%, rgba(183, 26, 26, 1) 100%);
				}
				&.beforeAnnouncement::after {
					background: linear-gradient(to bottom, rgba(226, 184, 20, 1) 0%, rgba(221, 180, 21, 1) 100%);
				}
				&.notStarted::after {
					background: linear-gradient(to bottom, rgba(195, 25, 25, 1) 0%, rgba(183, 26, 26, 1) 100%);
				}
				&.inProgress::after {
					background: linear-gradient(to bottom, rgba(196, 220, 24, 1) 0%, rgba(128, 180, 16, 1) 100%);
				}
			}
		}
		&__additionalContent {
			padding: 0 30px;
			&:not(.challengeVersion) {
				padding-left: 0;
			}
			&.challengeVersion {
				padding-right: 60px;
				.row__icon {
					margin-right: 0;
				}
			}
			> div:not(:last-child) {
				margin-right: 60px;
			}
			.progressBar {
				width: 165px;
				margin-bottom: 5px;
				.progressBar__number {
					font-size: 1.4em;
				}
			}
			.row__text--constWidth {
				width: 217px;
				text-align: center;
				&.finished {
					font-weight: 600;
				}
			}
			.row__icon {
				margin-right: 60px;
			}
			.btn__basic {
				padding: 7px 12px 6px 12px;
				min-width: initial;
			}
		}
	}
	@include M {
		.standardListRow__mainContent {
			.row {
				&__title {
					margin-bottom: 3px;
				}
				&__icon {
					margin-right: 0;
				}
			}
			.iconWrapper {
				margin-right: 5vw;
			}
			.progressBar {
				margin-right: 10px;
			}
		}
	}
	@include minLaptop {
		.standardListRow__additionalContent {
			&.challengeVersion {
				padding: 0 2vw;
			}
			&:not(.challengeVersion) {
				padding: 0 2vw;
			}
			> div:not(:last-child) {
				margin-right: 4vw;
			}
		}
		@include XXXL {
			.standardListRow {
				&__mainContent {
					padding: 20px 2vw;
					.iconWrapper {
						margin-right: 4vw;
					}
				}
				&__additionalContent {
					.progressBar {
						width: 17vw;
					}
				}
			}
		}
		@include XL {
			.mainContentWrapper {
				padding: 40px 0 0 0;
			}
			.standardListRow {
				&__additionalContent {
					.progressBar {
						width: 125px;
					}
					.row__roundNumber {
						display: none;
					}
				}
			}
		}
	}
	@include minDesktop {
		.standardListRow {
			&:hover {
				background-color: $standardBgr;
			}
			&__additionalContent {
				padding: 0 50px;
				> div:not(:last-child) {
					margin-right: 60px;
				}
			}
		}
		@include XXXXL {
			.standardListRow__additionalContent {
				padding: 0 2vw;
				&.challengeVersion {
					padding: 0 2vw;
				}
				&:not(.challengeVersion) {
					padding-left: 2vw;
				}
				> div:not(:last-child) {
					margin-right: 4vw;
				}
			}
		}
	}
}
