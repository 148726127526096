.battleDetails {
	$user-avatar-size: 46px;

	width: 100%;
	height: 100%;
	background-color: var(--color--content--background);
	z-index: 2000;
	position: fixed;
	top: 100%;
	left: 0;
	right: 0;
	padding: 95px 32px 45px;
	transition: top 0.5s;

	@include media-breakpoint-down(sm) {
		padding: 50px 16px 16px;
	}

	&--open {
		top: 0;
	}

	.progress-bar-container {
		background-color: color-mod(var(--color--content--background) shade(0.2));
	}

	&__close-button {
		position: absolute;
		top: 16px;
		right: 16px;
	}

	&__container {
		max-width: 700px;
		height: 100%;
		overflow-y: auto;
		position: relative;
		margin: auto;
	}

	&--color--gray {
		color: var(--color--content--foreground-50);
	}

	&__duel {
		height: 50px;
		border-radius: 24px;
		position: relative;
		overflow: hidden;

		.player__image {
			width: $user-avatar-size;
			height: $user-avatar-size;
			background-color: var(--color--interactive--background);
			margin: 2px;
		}
	}

	&__duel__current {
		height: 50px;
		background-color: #ff591d;
		min-width: 130px;
	}

	&__duel__opponent {
		height: 50px;
		background-color: #ff591d;
		min-width: 130px;
	}

	&__duel__score {
		color: white;
		border-radius: 12px;
		padding: 4px 7px 3px 7px;
		background-color: rgba(255, 255, 255, 0.25);
	}

	&__duel--winning {
		background-color: #5bacf6;
	}
}
