@mixin container($rule) {
	&.\:container\(#{cq-espace($rule)}\) {
		@content;
	}
}

@mixin ms--heightAuto {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		height: 100%;
	} // sass-lint:disable-line no-vendor-prefixes
}

@mixin ms--widthAuto {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		width: 100%;
	} // sass-lint:disable-line no-vendor-prefixes
}

@mixin ms {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	} // sass-lint:disable-line no-vendor-prefixes
}

@mixin grayscale {
	img {
		opacity: 0.4;
		filter: grayscale(1);
	}
}

@mixin core-border-radius($radius, $corners...) {
	@each $corner in $corners {
		border-#{$corner}-radius: $radius;
	}
}

@mixin buttonEffect($color) {
	background-color: color-mod($color brighten(0.5));
	&:hover {
		background-color: color-mod($color brighten(0.5));
		&::after {
			top: 3px;
		}
	}
	&:focus,
	&.loading {
		background-color: color-mod($color shade(0.9));
		&::after {
			background: linear-gradient(to bottom, color-mod($color shade(0.9)) 0%, $color 100%);
		}
	}
	&::after {
		background: $color;
	}
}

// Scroll on whole view section
@mixin wholePageScroll {
	overflow: auto;
	.overflow--auto {
		overflow: visible;
	}
	.overflow--hidden {
		overflow: visible;
	}
}

@mixin doubleSideTile__taskDimensions {
	width: 12vw;
	max-width: 220px;
	min-width: 150px;
}

@mixin doubleSideTile__resetDimensions {
	width: 100%;
	max-width: none;
	min-width: 0;
}

@mixin roundVisualStatus__incorrect {
	display: block;
	background-color: $answerIncorrectDark;
	.roundVisualStatus__signWrapper {
		transform: translate(-50%, -50%);
		&::before,
		&::after {
			width: 20%;
			height: 100%;
			top: 50%;
			left: 50%;
		}
		&::before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
		&::after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
}

@mixin roundVisualStatus__awaiting {
	display: block;
	background-color: #ffcb00;
	.roundVisualStatus__signWrapper {
		width: auto;
		height: auto;
		top: 15px;
		&::before {
			content: none;
		}
		&::after {
			content: '\f017';
			font-family: FontAwesome;
			background-color: transparent;
			position: absolute;
			font-size: 120px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			color: #fff;
		}
	}
}

@mixin roundVisualStatus__incorrect--mini {
	display: block;
	background-color: $answerIncorrectDark;
	.roundVisualStatus__signWrapper {
		transform: translate(-50%, -50%);
		&::after {
			content: '\f00d';
			font-family: FontAwesome;
			background-color: transparent;
			position: absolute;
			font-size: 24px;
			text-align: center;
			color: #fff;
			left: 1px;
		}
	}
}

@mixin roundVisualStatus__awaiting--mini {
	display: block;
	background-color: #ffcb00;
	.roundVisualStatus__signWrapper {
		transform: translate(-50%, -50%);
		&::after {
			content: '\f017';
			font-family: FontAwesome;
			background-color: transparent;
			position: absolute;
			font-size: 24px;
			text-align: center;
			color: #fff;
		}
	}
}

@mixin roundVisualStatus__correct {
	display: block;
	background-color: $answerCorrectDark;
	.roundVisualStatus__signWrapper {
		transform: translate(-70%, -73%) rotate(-50deg);
		&::before {
			width: 20%;
			height: 50%;
		}
		&::after {
			width: 100%;
			height: 20%;
		}
	}
}

@mixin wrapper--row {
	flex-direction: row;
}

@mixin XS {
	@media all and (max-width: #{$XS}) {
		@content;
	}
}
@mixin S {
	@media all and (max-width: #{$S}) {
		@content;
	}
}
@mixin M {
	@media all and (max-width: #{$M}) {
		@content;
	}
}
@mixin L {
	@media all and (max-width: #{$L}) {
		@content;
	}
}
@mixin XL {
	@media all and (max-width: #{$XL}) {
		@content;
	}
}
@mixin XXL {
	@media all and (max-width: #{$XXL}) {
		@content;
	}
}
@mixin XXXL {
	@media all and (max-width: #{$XXXL}) {
		@content;
	}
}
@mixin XXXXL {
	@media all and (max-width: #{$XXXXL}) {
		@content;
	}
}

@mixin minDesktop {
	@media all and (min-width: #{$XXXL}) {
		@content;
	}
}
@mixin minLaptop {
	@media all and (min-width: #{$M}) {
		@content;
	}
}

@mixin touchDevices {
	@media all and (any-hover: none), all and (max-width: #{$M}) {
		@content;
	}
}

@mixin hooverSupported {
	@media (hover: hover) {
		@content;
	}
}

// ---

@mixin hXS {
	@media all and (max-height: #{$hXS}) {
		@content;
	}
}
@mixin hS {
	@media all and (max-height: #{$hS}) {
		@content;
	}
}
@mixin hM {
	@media all and (max-height: #{$hM}) {
		@content;
	}
}
@mixin hL {
	@media all and (max-height: #{$hL}) {
		@content;
	}
}
@mixin hXL {
	@media all and (max-height: #{$hXL}) {
		@content;
	}
}
@mixin hXXL {
	@media all and (max-height: #{$hXXL}) {
		@content;
	}
}
